import * as yup from 'yup';

export const NEW_PRESCRIPTION_SCHEMA = (isControlled: boolean = false) =>
    yup.object().shape({
        drugName: yup
            .string()
            .test('drug-name', (value) => {
                return !isControlled;
            })
            .required(),
        drugForm: yup.string().required(),
        strength: yup.string().required(),
        ndc: yup.string().required(),
        qty: yup
            .string()
            .required()
            .test('drug-qty', (value) => /^[1-9][0-9]*$/.test(value))
            .max(6, 'drug-qty-max')
    });

export const TRANSFER_PRESCRIPTION_SCHEMA = (isControlled: boolean = false) =>
    yup.object().shape({
        drugName: yup
            .string()
            .test('drug-name', (value) => {
                return !isControlled;
            })
            .required(),
        drugForm: yup.string().required(),
        strength: yup.string().required(),
        ndc: yup.string().required(),
        qty: yup
            .string()
            .required()
            .test('drug-qty', (value) => /^[1-9][0-9]*$/.test(value))
            .max(6, 'drug-qty-max'),
        rxNumber: yup.string().required()
    });
export const BIRDI_PRICE_SCHEMA = () =>
    yup.object().shape({
        drugForm: yup.string().required(),
        strength: yup.string().required(),
        ndc: yup.string().required(),
        qty: yup
            .string()
            .required()
            .test('drug-qty', (value) => /^[1-9][0-9]*$/.test(value))
            .max(6, 'drug-qty-max')
    });
