import React from 'react';
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js';

import Text from 'ui-kit/text/text';

import { PhoneNumberTextProps } from 'ui-kit/phone-number-text/phone-number-text.props';

const formatMap = {
    US: {
        countryCode: 'US',
        maxLength: 10
    },
    CA: {
        countryCode: 'CA',
        maxLength: 10
    }
};

function cleanPhone(phone: any): string {
    if (phone) {
        if (typeof phone === 'object') {
            return String(phone[0]);
        } else {
            return String(phone);
        }
    } else {
        return '';
    }
}

const PhoneNumberText = ({ countryCode, onChange, value, ...rest }: PhoneNumberTextProps) => {
    const format = formatMap[countryCode];
    const formatType = format?.countryCode === 'US' ? 'NATIONAL' : 'INTERNATIONAL';
    const phoneNumberValue = parsePhoneNumberFromString(cleanPhone(value), countryCode);
    const formattedValue = phoneNumberValue?.format(formatType);

    const handleChange = (e: any) => {
        let formattedValue = new AsYouType(countryCode).input(e.currentTarget.value);
        let parsedNumber = parsePhoneNumberFromString(e.currentTarget.value, countryCode);
        const formattingRe = new RegExp(/\)|\(/g);
        if (value && e.currentTarget.value.length > value.length) {
            e.currentTarget.value = formattedValue;
        }

        if (parsedNumber && parsedNumber?.nationalNumber.length > format.maxLength) {
            e.currentTarget.value = value;
        } else {
            if (
                e.currentTarget.value?.length > 0 &&
                e.currentTarget.value?.length < 7 &&
                value?.match(formattingRe) &&
                e.currentTarget.value[0] !== '('
            ) {
                e.currentTarget.value = value.replace(formattingRe, '');
            } else if (
                e.currentTarget.value?.length > 0 &&
                e.currentTarget.value?.length < 5 &&
                value?.match(formattingRe)
            ) {
                e.currentTarget.value = value.replace(formattingRe, '');
            } else {
                e.currentTarget.value = formattedValue;
            }
        }
        if (onChange) onChange(e);
    };
    return (
        <div className={`phone-number-text`}>
            <Text
                onChange={handleChange}
                type="text"
                value={formattedValue}
                defaultValue={formattedValue}
                name={rest.name}
                label={rest.label}
                errors={rest.errors}
                touched={rest.touched}
                className={rest.className}
                autocomplete={rest.autocomplete}
                inputRef={rest.inputRef}
            />
        </div>
    );
};

export default PhoneNumberText;
