import { put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import {
    easyRefillAuthenticateUserRoutine,
    easyRefillVerifyUserRoutine,
    easyRefillGetSecretKeyHashRoutine,
    easyRefillGetPatientDataRoutine,
    easyRefillGetPatientAddressesRoutine,
    easyRefillGetPatientPaymentCardsRoutine,
    easyRefillStartOrderRoutine,
    easyRefillSubmitOrderRoutine,
    easyRefillCancelOrderRoutine,
    easyRefillRemoveRxToRefillRoutine,
    easyRefillAddRxToRefillRoutine,
    easyRefillRemoveAllRxToRefillRoutine
} from './easy-refill.reducer';
import {
    Address,
    AuthenticateUserRequest,
    AuthenticateUserResponse,
    CreateOrUpdateRequest,
    CreateOrUpdateResponse,
    EasyRefillResponse,
    EasyRefillSubmitOrderRequest,
    EasyRefillSubmitOrderResponse,
    GetEasyRefillAddressDataResponse,
    GetEasyRefillPatientDataResponse,
    GetEasyRefillPaymentCardDataResponse,
    PaymentCard,
    PlanNumUpdate,
    VerifyUserRequest,
    VerifyUserResponse
} from 'types/easy-refill';
import { EasyRefillService } from './easy-refill.services';
import { baseEffectHandler } from 'util/sagas/sagas';
import { TrackError } from 'util/google_optimize/optimize_helper';
import {
    easyRefillExpeditedShippingSelector,
    easyRefillPatientAddressSelector,
    easyRefillPatientBillAddressSelector,
    easyRefillPatientPaymentCardSelector,
    easyRefillPlanNumberSelector,
    easyRefillRxsToRefillSelector,
    easyRefillSecretKeyHashSelector,
    easyRefillSecurityTokenSelector,
    easyRefillUserBearerTokenSelector
} from './easy-refill.selectors';

function* generateSecretKeySaga(
    action: PayloadAction<{
        onFailure?: (response: string) => void;
    }>
) {
    try {
        const securityToken: string = yield select(easyRefillSecurityTokenSelector);

        yield baseEffectHandler({
            service: EasyRefillService.getSecretKeyHash().get,
            isAuthenticatedService: false,
            data: securityToken,
            *onResponse(response: AuthenticateUserResponse) {
                yield put(easyRefillGetSecretKeyHashRoutine.success(response));
            },
            *onError() {
                yield put(easyRefillGetSecretKeyHashRoutine.failure('The security token is invalid'));
                const { onFailure } = action.payload;
                if (onFailure) {
                    onFailure('The security token is invalid');
                }
            }
        });
    } catch (error) {
        yield put(easyRefillGetSecretKeyHashRoutine.failure(error));
    }
}

function* authenticateUserSaga() {
    try {
        const securityToken: string = yield select(easyRefillSecurityTokenSelector);
        const secretKeyHash: string = yield select(easyRefillSecretKeyHashSelector);
        // if successful verification - authenticate user
        const authenticateData: AuthenticateUserRequest = {
            securityToken,
            secretKeyHash,
            authenticationType: 'EasyRefill'
        };

        yield baseEffectHandler({
            service: EasyRefillService.authenticateUser().post,
            isAuthenticatedService: false,
            data: authenticateData,
            *onResponse(response: AuthenticateUserResponse) {
                if (response && response.messageText !== 'Authenticated') {
                    yield put(easyRefillAuthenticateUserRoutine.failure(response));
                    TrackError('easy-refill.sagas.ts', 'authenticateUserSaga', response.messageText);
                } else {
                    yield put(easyRefillAuthenticateUserRoutine.success(response));
                }
            },
            *onError(error) {
                yield put(easyRefillAuthenticateUserRoutine.failure({ messageText: 'An error has occured' }));
            }
        });
    } catch (error) {}
}

function* verifyUserSaga(
    action: PayloadAction<{
        dateOfBirth: string;
        zipcode: string;
        onSuccess?: () => void;
        onFailure?: (response: VerifyUserResponse) => void;
        onMaxNumAttemptsReached?: (response: VerifyUserResponse) => void;
    }>
) {
    try {
        const securityToken: string = yield select(easyRefillSecurityTokenSelector);
        const { dateOfBirth, zipcode, onSuccess, onFailure, onMaxNumAttemptsReached } = action.payload;
        const data: VerifyUserRequest = {
            dateOfBirth,
            zipcode,
            securityToken,
            authenticationType: 'EasyRefill'
        };

        yield baseEffectHandler({
            service: EasyRefillService.verifyUser().post,
            isAuthenticatedService: false,
            data: data,
            *onResponse(response: VerifyUserResponse) {
                if (response && response.verificationStatus === 'MaxAttemptsReached') {
                    yield put(easyRefillVerifyUserRoutine.failure(response));
                    if (onMaxNumAttemptsReached) onMaxNumAttemptsReached(response);
                } else if (response && response.verificationStatus !== 'UserVerified') {
                    yield put(easyRefillVerifyUserRoutine.failure(response));
                    TrackError('easy-refill.sagas.ts', 'verifyUserSaga', response.messageText);
                    if (onFailure) onFailure(response);
                } else {
                    yield put(easyRefillVerifyUserRoutine.success(response));
                    yield put(easyRefillAuthenticateUserRoutine.trigger());
                    if (onSuccess) onSuccess();
                }
            },
            *onError(error) {
                yield put(easyRefillVerifyUserRoutine.failure({ messageText: 'An error has occured' }));
            }
        });
    } catch (error) {
        console.log(error);
    }
}

function* getPatientDataSaga(action: PayloadAction<{ onFailure?: () => void }>) {
    try {
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
        yield baseEffectHandler({
            service: EasyRefillService.getPatientData().get,
            isAuthenticatedService: false,
            data: bearerToken,
            *onResponse(response: GetEasyRefillPatientDataResponse) {
                yield put(easyRefillGetPatientDataRoutine.success(response));
            },
            *onError() {
                yield put(easyRefillGetPatientDataRoutine.failure({ messageText: 'An error has occured' }));
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    } catch (error) {
        yield put(easyRefillGetPatientDataRoutine.failure({ messageText: 'An error has occurred' }));
    }
}
function* getPatientAddressesSaga() {
    try {
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
        yield baseEffectHandler({
            service: EasyRefillService.getPatientAddresses().get,
            isAuthenticatedService: false,
            data: bearerToken,
            *onResponse(response: GetEasyRefillAddressDataResponse) {
                yield put(easyRefillGetPatientAddressesRoutine.success(response));
            },
            *onError(error) {
                yield put(easyRefillGetPatientAddressesRoutine.failure({ messageText: error.messageText }));
            }
        });
    } catch (error) {}
}
function* getPatientPaymentCardsSaga() {
    try {
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
        yield baseEffectHandler({
            service: EasyRefillService.getPatientPaymentCard().get,
            isAuthenticatedService: false,
            data: bearerToken,
            *onResponse(response: GetEasyRefillPaymentCardDataResponse) {
                yield put(easyRefillGetPatientPaymentCardsRoutine.success(response));
            },
            *onError(error) {
                yield put(easyRefillGetPatientPaymentCardsRoutine.failure({ messageText: error.messageText }));
            }
        });
    } catch (error) {}
}

function* startEasyRefillOrderSaga(
    action: PayloadAction<{
        onSuccess?: (response: CreateOrUpdateResponse) => void;
        onFailure?: () => void;
    }>
) {
    try {
        const rxNumbersToRefill: string[] = yield select(easyRefillRxsToRefillSelector);
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);

        const data: CreateOrUpdateRequest = {
            rxNumbers: rxNumbersToRefill
        };

        const { onFailure, onSuccess } = action.payload;

        yield baseEffectHandler({
            service: EasyRefillService.createOrder().post,
            isAuthenticatedService: false,
            data: { ...data, bearerToken: bearerToken },
            *onResponse(response: CreateOrUpdateResponse) {
                yield put(easyRefillStartOrderRoutine.success(response));
                if (onSuccess) onSuccess(response);
            },
            *onError() {
                yield put(easyRefillStartOrderRoutine.failure({ messageText: 'An error has occured' }));
                if (onFailure) onFailure();
            }
        });
    } catch (error) {}
}

function* submitEasyRefillOrderSaga(
    action: PayloadAction<{
        onSuccess?: (response: EasyRefillSubmitOrderResponse) => void;
        onFailure?: () => void;
    }>
) {
    try {
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
        const expeditedShipping: boolean = yield select(easyRefillExpeditedShippingSelector);
        const planNum: string = yield select(easyRefillPlanNumberSelector);
        const defaultShipAddress: Address = yield select(easyRefillPatientAddressSelector);
        const defaultBillAddress: Address = yield select(easyRefillPatientBillAddressSelector);
        const defaultCard: PaymentCard = yield select(easyRefillPatientPaymentCardSelector);
        const rxsToRefill: string[] = yield select(easyRefillRxsToRefillSelector);

        const planUpdates: PlanNumUpdate[] = rxsToRefill.map((rxNumber) => {
            return { rxNumber, planNum };
        });

        const data: EasyRefillSubmitOrderRequest = {
            planNumUpdates: planUpdates,
            patientBillAddressSeq: defaultBillAddress.addressSeqNum,
            patientShipAddressSeq: defaultShipAddress.addressSeqNum,
            paymentCardSeqNumber: defaultCard.cardSeqNum,
            orderExpedited: expeditedShipping
        };

        const { onSuccess, onFailure } = action.payload;

        yield baseEffectHandler({
            service: EasyRefillService.submitOrder().post,
            isAuthenticatedService: false,
            data: { ...data, bearerToken: bearerToken },
            *onResponse(response: EasyRefillSubmitOrderResponse) {
                yield put(easyRefillSubmitOrderRoutine.success(response));
                if (onSuccess) onSuccess(response);
            },
            *onError() {
                yield put(easyRefillSubmitOrderRoutine.failure({ messageText: 'An eror has occured' }));
                if (onFailure) onFailure();
            }
        });
    } catch (error) {}
}

function* cancelEasyRefillOrderSaga(
    action: PayloadAction<{
        onSuccess?: (response: EasyRefillResponse) => void;
        onFailure?: (response: EasyRefillResponse) => void;
    }>
) {
    try {
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
        yield baseEffectHandler({
            service: EasyRefillService.cancelOrder().post,
            isAuthenticatedService: false,
            data: { bearerToken: bearerToken },
            *onResponse(response: EasyRefillResponse) {
                yield put(easyRefillCancelOrderRoutine.success(response));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess(response);
            },
            *onError(error) {
                yield put(easyRefillCancelOrderRoutine.failure({ messageText: error.messageText }));
                const { onFailure } = action.payload;
                if (onFailure) onFailure(error);
            }
        });
    } catch (error) {}
}

function* removeRxsToRefillSaga(action: PayloadAction<{ rxNumber: string; onSuccess?: () => void }>) {
    try {
        const { rxNumber, onSuccess } = action.payload;
        const rxsToRefill: string[] = yield select(easyRefillRxsToRefillSelector);
        const newRxList = rxsToRefill.filter((rx) => rx !== rxNumber);
        yield put(easyRefillRemoveRxToRefillRoutine.success(newRxList));
        if (onSuccess) onSuccess();
    } catch (error) {}
}

function* removeAllRxsToRefillSaga(action: PayloadAction<{ onSuccess?: () => void }>) {
    const { onSuccess } = action.payload;
    yield put(easyRefillRemoveRxToRefillRoutine.success([]));
    if (onSuccess) onSuccess();
}

function* addRxsToRefillSaga(action: PayloadAction<{ rxNumber: string; onSuccess?: () => void }>) {
    try {
        const { rxNumber, onSuccess } = action.payload;

        const rxsToRefill: string[] = yield select(easyRefillRxsToRefillSelector);
        let newListOfRxs: string[] = [];

        if (rxsToRefill.indexOf(rxNumber) === -1) {
            newListOfRxs = [...rxsToRefill, rxNumber];
        } else {
            newListOfRxs = [...rxsToRefill];
        }

        yield put(easyRefillAddRxToRefillRoutine.success(newListOfRxs));
        if (onSuccess) onSuccess();
    } catch (error) {}
}

function* easyRefillSaga() {
    yield takeLatest(easyRefillVerifyUserRoutine.TRIGGER, verifyUserSaga);
    yield takeLatest(easyRefillAuthenticateUserRoutine.TRIGGER, authenticateUserSaga);
    yield takeLatest(easyRefillGetSecretKeyHashRoutine.TRIGGER, generateSecretKeySaga);
    yield takeLatest(easyRefillGetPatientDataRoutine.TRIGGER, getPatientDataSaga);
    yield takeLatest(easyRefillGetPatientAddressesRoutine.TRIGGER, getPatientAddressesSaga);
    yield takeLatest(easyRefillGetPatientPaymentCardsRoutine.TRIGGER, getPatientPaymentCardsSaga);
    yield takeLatest(easyRefillStartOrderRoutine.TRIGGER, startEasyRefillOrderSaga);
    yield takeLatest(easyRefillSubmitOrderRoutine.TRIGGER, submitEasyRefillOrderSaga);
    yield takeLatest(easyRefillCancelOrderRoutine.TRIGGER, cancelEasyRefillOrderSaga);
    yield takeLatest(easyRefillRemoveRxToRefillRoutine.TRIGGER, removeRxsToRefillSaga);
    yield takeLatest(easyRefillAddRxToRefillRoutine.TRIGGER, addRxsToRefillSaga);
    yield takeLatest(easyRefillRemoveAllRxToRefillRoutine.TRIGGER, removeAllRxsToRefillSaga);
}

export default easyRefillSaga;
