import { useCallback, useEffect, useState, forwardRef, useImperativeHandle, Ref } from 'react';
import classNames from 'classnames';
import { noop } from 'util/function';
import Button from 'ui-kit/button/button';
import ColumnSection, { ColumnSectionProps } from 'components/sidebar-column/column-section/column-section.component';
import './column-section-toggle.style.scss';

export interface ColumnSectionEditModeToggleProps extends Omit<ColumnSectionProps, 'headerActionContent'> {
    editModeContent: React.ReactNode;
    onEditModeChange?: (isEditMode: boolean) => void;
    isToggleDisabled?: boolean;
    chevronButton?: boolean;
}

export interface ColumnSectionEditModeToggleRef {
    handleSaveChangesButtonClick: () => void;
}

const ColumnSectionEditModeToggle = forwardRef(
    (props: ColumnSectionEditModeToggleProps, ref: Ref<ColumnSectionEditModeToggleRef>) => {
        const {
            children,
            editModeContent = null,
            onEditModeChange = noop,
            isToggleDisabled = false,
            chevronButton = false,
            className,
            ...remainingColumnSectionProps
        } = props;
        const [isEditMode, setIsEditMode] = useState<boolean>(false);
        const hasChevronClass = chevronButton ? 'has-chevron' : '';

        const updateEditMode = useCallback(
            (isEditing: boolean) => {
                setIsEditMode(isEditing);
                onEditModeChange(isEditing);
            },
            [onEditModeChange]
        );

        const handleChangeButtonClick = useCallback(() => {
            updateEditMode(true);
        }, [updateEditMode]);

        const handleSaveChangesButtonClick = useCallback(() => {
            updateEditMode(false);
        }, [updateEditMode]);

        useEffect(() => {
            if (isToggleDisabled) {
                updateEditMode(false);
            }
        }, [updateEditMode, isToggleDisabled]);

        useImperativeHandle(ref, () => ({ handleSaveChangesButtonClick }));

        return (
            <ColumnSection
                className={classNames('column-section-toggle', className, hasChevronClass)}
                headerAction={
                    isEditMode ? (
                        <Button
                            className="column-section__button"
                            variant="text-blue-light"
                            label={chevronButton ? '' : 'Save Changes'}
                            type="button"
                            chevron={chevronButton ? 'down' : undefined}
                            onClick={handleSaveChangesButtonClick}
                        />
                    ) : (
                        !isToggleDisabled && (
                            <Button
                                className="column-section__button"
                                variant="text-blue-light"
                                label={chevronButton ? '' : 'Change'}
                                type="button"
                                chevron={chevronButton ? 'up' : undefined}
                                onClick={handleChangeButtonClick}
                            />
                        )
                    )
                }
                {...remainingColumnSectionProps}
            >
                {isEditMode ? editModeContent : children}
            </ColumnSection>
        );
    }
);

export default ColumnSectionEditModeToggle;
