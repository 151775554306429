import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { verifyAddressRoutine } from 'state/usps/usps.routines';
import { AddressValidateResponse } from 'util/usps';

import { USPSVerifiedAddress, USPSAddressValidateResponse } from 'util/usps';

export interface VerifyAddressRequest {
    address: AddressParts;
    onSuccess?: (AddressValidateResponse: AddressValidateResponse | unknown) => void;
    onFailure?: (AddressValidateResponse: AddressValidateResponse | unknown) => void;
}
export interface AddressParts {
    street1: string;
    street2?: string;
    city: string;
    state: string;
    zip: string;
}
export interface USPSState {
    addressParts?: AddressParts;
    verifiedAddress?: USPSVerifiedAddress;
    isBusy: boolean;
    error?: string;
}

const initialState: USPSState = {
    addressParts: undefined,
    verifiedAddress: undefined,
    isBusy: false,
    error: undefined
};
const uspsSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {},
    extraReducers: {
        [verifyAddressRoutine.TRIGGER]: (state: USPSState) => {
            state.isBusy = true;
            state.verifiedAddress = undefined;
        },
        [verifyAddressRoutine.SUCCESS]: (state: USPSState, action: PayloadAction<USPSVerifiedAddress>) => {
            state.isBusy = false;
            state.verifiedAddress = action.payload;
        },
        [verifyAddressRoutine.FAILURE]: (state: USPSState, action: PayloadAction<USPSAddressValidateResponse>) => {
            state.isBusy = false;
            state.verifiedAddress = undefined;
            state.error = action.payload.error;
        }
    }
});

export default uspsSlice.reducer;
