import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { produce } from 'immer';
import {
    getFilePrescriptionRoutine,
    getFilePharmacyRoutine,
    getFilePrescriberRoutine
} from 'state/add-transfer-prescription/add-transfer-prescription.routines';
import { PharmacyPayload } from './add-transfer-prescription.services';

export interface DrugStrengths {
    ddid: string;
    formCode: string;
    formDescription: string;
    fullStrength: string;
    gpi: string;
    ndc: string;
    routeAdmin: string;
    strength: string;
    strengthUnit: string;
}

export interface DrugOption {
    key: string;
    label: string;
    value: string;
    strengths: StrengthOption[];
    dosageForm: string;
}

export interface StrengthOption {
    key: string;
    label: string;
    value: string;
    fullStrength: string;
    drugCode: string;
    quantity90days: string;
    gpi: string;
    packageOptions: PackageOption[]; // DRX-1056 enhancement
}

// DRX-1056 enhancement
export interface PackageOption {
    key: string;
    label: string;
    value: string;
    drugCode: string;
    isUoU: number;
    packageDesc: string;
    packageDisplay: string;
    quantity90days: string;
    packageWithStrength: string;
    quantityLabelDesc?: string;
}

// DRX-1056 enhancement
export interface StrengthOptionAlt {
    key: string;
    label: string;
    value: string;
    packTotal: string;
    packUoM: string;
    packageDesc: string;
    packageDisplay: string;
    packageQuantity: number;
    packageSize: number;
    quantity90days: number;
}

export interface DrugDetailsPayload {
    readonly drugFormOptions?: DrugOption[];
    readonly drugStrengthOptions?: StrengthOption[];
    readonly drugPackageOptions?: PackageOption[]; // DRX-1056 enhancement
}

export interface CurrentStepPayload {
    readonly currentStep: number;
}

export interface TotalStepPayload {
    readonly totalSteps: number;
}

export interface FlowTypePayload {
    readonly flowType: string;
}

export interface ShowPaymentStepPayload {
    readonly showPaymentStep: boolean;
}

export interface ShowAddressStepPayload {
    readonly showAddressStep: boolean;
}

export interface isPhotoUploadedPayload {
    readonly isPhotoUploaded: boolean;
}

export interface PrescriptionDetailsPayload {
    readonly firstName: string;
    readonly lastName: string;
    readonly phoneNumber: string;
    readonly faxNumber: string;
    readonly address: string;
    readonly city: string;
    readonly state: string;
    readonly zip: string;
}

export interface PharmacyDataPayload {
    readonly pharmacyName: string;
    readonly pharmacyPhone: string;
    readonly pharmacyAddress: string;
    readonly pharmacyCity: string;
    readonly pharmacyState: string;
    readonly pharmacyZip: string;
}

export interface PrescriberDataPayload {
    readonly prescriberFirstName: string;
    readonly prescriberLastName: string;
    readonly prescriberPhone: string;
}

export interface PrescriptionPharmacyPayload {
    readonly pharmacyName: string;
    readonly pharmacyPhone: string;
    readonly pharmacyAddress: string;
}

export interface PrescriptionInfoPayload {
    drugPrice: string;
    readonly drugName: string;
    readonly drugDisplayName: string;
    readonly drugForm: string;
    readonly strength: string;
    readonly qty: string;
    readonly rxNumber: string;
    readonly imageUrl?: string;
    readonly genericProductCode: string;
    readonly gpi: string;
    readonly ndc: string;
    strengthWithPackage: string;
    packageDisplay: string;
}

export interface PrescriptionPayload {
    readonly RequestType: string;
    readonly DrugPrice: string;
    readonly DrugName: string;
    readonly DrugDisplayName?: string;
    readonly GenericProductCode?: string;
    readonly DrugForm: string;
    readonly Strength: string;
    readonly StrengthWithPackage?: string;
    readonly Qty: string;
    readonly PrescriberName: string;
    readonly PrescriberAddress: string;
    readonly PrescriberCity: string;
    readonly PrescriberState: string;
    readonly PrescriberZip: string;
    readonly PrescriberPhone: string;
    readonly PrescriberFax: string;
    readonly PharmacyName: string;
    readonly PharmacyAddress: string;
    readonly PharmacyCity: string;
    readonly PharmacyState: string;
    readonly PharmacyZip: string;
    readonly PharmacyPhone: string;
    readonly RxNumber: string;
    readonly ImageExtractedData?: string;
    readonly GPI: string;
    readonly NDC: string;
    readonly PackageDisplay?: string;
}

export interface PrescriberPayload {
    PrescriberName: string;
    PrescriberAddress: string;
    PrescriberCity: string;
    PrescriberState: string;
    PrescriberZip?: string;
    PrescriberPhone: string;
    PrescriberFax?: string;
}

export interface addTransferPrescriptionState {
    error?: string;
    isBusy?: boolean;
    isPhotoUploaded?: boolean;
    addTransferPrescription: PrescriptionPayload;
    currentStep: number;
    totalSteps: number;
    flowType: string;
    showPaymentStep: boolean;
    showAddressStep: boolean;
    drugFormOptions: DrugOption[];
    drugStrengthOptions: StrengthOption[];
    drugPackageOptions: []; // DRX-1056 enhancement
}

export const initialState: addTransferPrescriptionState = {
    addTransferPrescription: {
        RequestType: '',
        DrugPrice: '',
        DrugName: '',
        DrugDisplayName: '',
        DrugForm: '',
        Strength: '',
        StrengthWithPackage: '',
        Qty: '',
        PrescriberName: '',
        PrescriberAddress: '',
        PrescriberCity: '',
        PrescriberState: '',
        PrescriberZip: '',
        PrescriberPhone: '',
        PrescriberFax: '',
        PharmacyName: '',
        PharmacyAddress: '',
        PharmacyCity: '',
        PharmacyState: '',
        PharmacyZip: '',
        PharmacyPhone: '',
        RxNumber: '',
        ImageExtractedData: '',
        GPI: '',
        NDC: ''
    },
    drugFormOptions: [],
    drugStrengthOptions: [],
    drugPackageOptions: [], // DRX-1056 enhancement
    currentStep: 0,
    totalSteps: 0,
    flowType: '',
    showPaymentStep: false,
    showAddressStep: false
};

const addTransferPrescriptionSlice = createSlice({
    name: 'add-transfer-prescription',
    initialState,
    reducers: {
        setDrugDetailValues: (state, { payload }: PayloadAction<DrugDetailsPayload>) =>
            produce(state, (draftState) => {
                draftState.drugFormOptions = payload.drugFormOptions;
                draftState.drugStrengthOptions = payload.drugStrengthOptions;
                draftState.drugPackageOptions = payload.drugPackageOptions; // DRX-1056 enhancement
            }),
        setStep: (state, { payload }: PayloadAction<CurrentStepPayload>) =>
            produce(state, (draftState) => {
                draftState.currentStep = payload.currentStep;
            }),
        setTotalStep: (state, { payload }: PayloadAction<TotalStepPayload>) =>
            produce(state, (draftState) => {
                draftState.totalSteps = payload.totalSteps;
            }),
        setShowAddressStep: (state, { payload }: PayloadAction<ShowAddressStepPayload>) =>
            produce(state, (draftState) => {
                draftState.showAddressStep = payload.showAddressStep;
            }),
        setIsPhotoUploaded: (state, { payload }: PayloadAction<isPhotoUploadedPayload>) =>
            produce(state, (draftState) => {
                draftState.isPhotoUploaded = payload.isPhotoUploaded;
            }),
        setShowPaymentStep: (state, { payload }: PayloadAction<ShowPaymentStepPayload>) =>
            produce(state, (draftState) => {
                draftState.showPaymentStep = payload.showPaymentStep;
            }),
        setFlowType: (state, { payload }: PayloadAction<FlowTypePayload>) =>
            produce(state, (draftState) => {
                draftState.flowType = payload.flowType;
                draftState.addTransferPrescription.RequestType = payload.flowType;
            }),
        savePrescriptionInfo: (state, { payload }: PayloadAction<PrescriptionInfoPayload>) =>
            produce(state, (draftState) => {
                draftState.addTransferPrescription.DrugPrice = payload.drugPrice;
                draftState.addTransferPrescription.DrugName = payload.drugName;
                draftState.addTransferPrescription.DrugDisplayName = payload.drugDisplayName;
                draftState.addTransferPrescription.DrugForm = payload.drugForm;
                draftState.addTransferPrescription.Strength = payload.strength;
                draftState.addTransferPrescription.StrengthWithPackage = payload.strengthWithPackage;
                draftState.addTransferPrescription.Qty = payload.qty;
                draftState.addTransferPrescription.RxNumber = payload.rxNumber;
                draftState.addTransferPrescription.ImageExtractedData = payload.imageUrl;
                draftState.addTransferPrescription.GenericProductCode = payload.genericProductCode;
                draftState.addTransferPrescription.PackageDisplay = payload.packageDisplay;
                draftState.addTransferPrescription.GPI = payload.gpi;
                draftState.addTransferPrescription.NDC = payload.ndc;
            }),
        savePrescriptionPharmacy: (state, { payload }: PayloadAction<PrescriptionPharmacyPayload>) =>
            produce(state, (draftState) => {
                draftState.addTransferPrescription.PharmacyName = payload.pharmacyName;
                draftState.addTransferPrescription.PharmacyPhone = payload.pharmacyPhone;
                draftState.addTransferPrescription.PharmacyAddress = payload.pharmacyAddress;
            }),
        clearPrescriptionPharmacy: (state) =>
            produce(state, (draftState) => {
                draftState.addTransferPrescription.PharmacyName = '';
                draftState.addTransferPrescription.PharmacyPhone = '';
                draftState.addTransferPrescription.PharmacyAddress = '';
            }),
        savePastPharmacyDetails: (state, { payload }: PayloadAction<PharmacyPayload>) =>
            produce(state, (draftState) => {
                draftState.addTransferPrescription.PharmacyName = payload.PharmacyName;
                draftState.addTransferPrescription.PharmacyPhone = payload.PharmacyPhone;
                draftState.addTransferPrescription.PharmacyAddress = payload.PharmacyAddress;
                draftState.addTransferPrescription.PharmacyCity = payload.PharmacyCity;
                draftState.addTransferPrescription.PharmacyState = payload.PharmacyState;
                draftState.addTransferPrescription.PharmacyZip = payload.PharmacyZip;
            }),
        clearAddTransferObject: (state) =>
            produce(state, (draftState) => {
                draftState.addTransferPrescription.DrugName = '';
                draftState.addTransferPrescription.DrugForm = '';
                draftState.addTransferPrescription.DrugPrice = '';
                draftState.addTransferPrescription.DrugDisplayName = '';
                draftState.addTransferPrescription.Strength = '';
                draftState.addTransferPrescription.Qty = '';
                draftState.addTransferPrescription.RxNumber = '';
                draftState.addTransferPrescription.ImageExtractedData = '';
                draftState.addTransferPrescription.GenericProductCode = '';

                draftState.addTransferPrescription.PharmacyName = '';
                draftState.addTransferPrescription.PharmacyPhone = '';
                draftState.addTransferPrescription.PharmacyAddress = '';

                draftState.addTransferPrescription.PrescriberName = '';
                draftState.addTransferPrescription.PrescriberAddress = '';
                draftState.addTransferPrescription.PrescriberCity = '';
                draftState.addTransferPrescription.PrescriberState = '';
                draftState.addTransferPrescription.PrescriberZip = '';
                draftState.addTransferPrescription.PrescriberPhone = '';
                draftState.addTransferPrescription.PrescriberFax = '';
                draftState.addTransferPrescription.RequestType = '';

                draftState.totalSteps = 0;
                draftState.currentStep = 0;
                draftState.drugFormOptions = [];
                draftState.drugStrengthOptions = [];
                draftState.drugPackageOptions = [];
            }),
        savePrescriberDetails: (state, { payload }: PayloadAction<PrescriptionDetailsPayload>) =>
            produce(state, (draftState) => {
                draftState.addTransferPrescription.PrescriberName = `${payload.firstName} ${payload.lastName}`;
                draftState.addTransferPrescription.PrescriberPhone = payload.phoneNumber;
                draftState.addTransferPrescription.PrescriberFax = payload.faxNumber;
                draftState.addTransferPrescription.PrescriberAddress = payload.address;
                draftState.addTransferPrescription.PrescriberCity = payload.city;
                draftState.addTransferPrescription.PrescriberState = payload.state;
                draftState.addTransferPrescription.PrescriberZip = payload.zip;
            }),
        savePastPrescriberDetails: (state, { payload }: PayloadAction<PrescriberPayload>) =>
            produce(state, (draftState) => {
                draftState.addTransferPrescription.PrescriberName = payload.PrescriberName;
                draftState.addTransferPrescription.PrescriberPhone = payload.PrescriberPhone;
                draftState.addTransferPrescription.PrescriberFax = payload.PrescriberFax ? payload.PrescriberFax : '';
                draftState.addTransferPrescription.PrescriberAddress = payload.PrescriberAddress;
                draftState.addTransferPrescription.PrescriberCity = payload.PrescriberCity;
                draftState.addTransferPrescription.PrescriberState = payload.PrescriberState;
                draftState.addTransferPrescription.PrescriberZip = payload.PrescriberZip ? payload.PrescriberZip : '';
            })
    },
    extraReducers: ({ addCase }) => {
        /**
         * Web Profile Fetching Reducers
         */
        addCase(getFilePrescriptionRoutine.SUCCESS, (state, { payload }: PayloadAction<PrescriptionInfoPayload>) =>
            produce(state, (draftState) => {
                draftState.addTransferPrescription.DrugName = payload.drugName === null ? '' : payload.drugName[0];
                draftState.addTransferPrescription.DrugForm = payload.drugForm === null ? '' : payload.drugForm;
                draftState.addTransferPrescription.Strength = payload.strength === null ? '' : payload.strength;
                draftState.addTransferPrescription.Qty = payload.quantity === null ? '' : payload.quantity;
                draftState.addTransferPrescription.RxNumber =
                    payload.prescriptionNo === null ? '' : payload.prescriptionNo;
                draftState.addTransferPrescription.ImageExtractedData =
                    payload.imageUrl === null ? '' : payload.imageUrl;
            })
        );
        addCase(getFilePrescriptionRoutine.FAILURE, (state, { payload }: PayloadAction<PrescriptionInfoPayload>) =>
            produce(state, (draftState) => {
                draftState.addTransferPrescription.DrugName = payload.drugName === null ? '' : payload.drugName[0];
                draftState.addTransferPrescription.DrugForm = payload.drugForm === null ? '' : payload.drugForm;
                draftState.addTransferPrescription.Strength = payload.strength === null ? '' : payload.strength;
                draftState.addTransferPrescription.Qty = payload.quantity === null ? '' : payload.quantity;
                draftState.addTransferPrescription.RxNumber =
                    payload.prescriptionNo === null ? '' : payload.prescriptionNo;
                draftState.addTransferPrescription.ImageExtractedData =
                    payload.imageUrl === null ? '' : payload.imageUrl;
            })
        );
        /**
         * Web Profile Fetching Reducers
         */
        addCase(getFilePharmacyRoutine.SUCCESS, (state, { payload }: PayloadAction<PharmacyDataPayload>) =>
            produce(state, (draftState) => {
                draftState.addTransferPrescription.PharmacyName =
                    payload.pharmacyName === null ? '' : payload.pharmacyName;
                draftState.addTransferPrescription.PharmacyPhone =
                    payload.pharmacyPhone === null ? '' : payload.pharmacyPhone[0];
                draftState.addTransferPrescription.PharmacyAddress =
                    payload.pharmacyAddress === null
                        ? ''
                        : `${payload.pharmacyAddress} ${payload.pharmacyCity} ${payload.pharmacyState} ${payload.pharmacyZip}`;
            })
        );
        addCase(getFilePharmacyRoutine.FAILURE, (state, { payload }: PayloadAction<PharmacyDataPayload>) =>
            produce(state, (draftState) => {
                draftState.addTransferPrescription.PharmacyName =
                    payload.pharmacyName === null ? '' : payload.pharmacyName;
                draftState.addTransferPrescription.PharmacyPhone =
                    payload.pharmacyPhone === null ? '' : payload.pharmacyPhone[0];
                draftState.addTransferPrescription.PharmacyAddress =
                    payload.pharmacyAddress === null
                        ? ''
                        : `${payload.pharmacyAddress} ${payload.pharmacyCity} ${payload.pharmacyState} ${payload.pharmacyZip}`;
            })
        );
        /**
         * Web Profile Fetching Reducers
         */
        addCase(getFilePrescriberRoutine.SUCCESS, (state, { payload }: PayloadAction<PrescriberDataPayload>) =>
            produce(state, (draftState) => {
                draftState.addTransferPrescription.PrescriberName =
                    payload.prescriberFirstName === null
                        ? ''
                        : `${payload.prescriberFirstName} ${payload.prescriberLastName}`;
                draftState.addTransferPrescription.PrescriberPhone =
                    payload.prescriberPhone === null ? '' : payload.prescriberPhone;
            })
        );
        addCase(getFilePrescriberRoutine.FAILURE, (state, { payload }: PayloadAction<PrescriberDataPayload>) =>
            produce(state, (draftState) => {
                draftState.addTransferPrescription.PrescriberName =
                    payload.prescriberFirstName === null
                        ? ''
                        : `${payload.prescriberFirstName} ${payload.prescriberLastName}`;
                draftState.addTransferPrescription.PrescriberPhone =
                    payload.prescriberPhone === null ? '' : payload.prescriberPhone;
            })
        );
    }
});

export const {
    setDrugDetailValues,
    setStep,
    savePrescriptionInfo,
    clearAddTransferObject,
    savePrescriberDetails,
    savePastPrescriberDetails,
    savePrescriptionPharmacy,
    clearPrescriptionPharmacy,
    savePastPharmacyDetails,
    setTotalStep,
    setFlowType,
    setShowAddressStep,
    setIsPhotoUploaded,
    setShowPaymentStep
} = addTransferPrescriptionSlice.actions;
export default addTransferPrescriptionSlice.reducer;
