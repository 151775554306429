import { useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'ui-kit/button/button';
import Text from 'ui-kit/text/text';
import { accountMessagesSelector, accountProfileSelector } from 'state/account/account.selectors';

import { accountFetchComposeMessageRoutine, accountPostComposeMessageRoutine } from 'state/account/account.routines';

import TextArea from 'ui-kit/text/textarea';
import FormSelect from 'ui-kit/form-select/form-select';
import { Messages } from 'types/messages';
import { MESSAGE_SCHEMA } from 'schema/messages';
import { messageOptions } from 'const/options';
import AttachFiles from 'ui-kit/attach-files/attach-files';

export const MessageForm = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const messages = useSelector(accountMessagesSelector);
    const profileObject = useSelector(accountProfileSelector);
    const formName = 'MessageForm';

    useEffect(() => {
        dispatch(accountFetchComposeMessageRoutine.trigger());
    }, [profileObject]);

    const submissionCallbacks = (setSubmitting: Function) => ({
        onSuccess: () => {
            const { onSuccess } = props.callbacks;
            if (onSuccess) onSuccess();
            setSubmitting(false);
        },
        onFailure: () => {
            const { onFailure } = props.callbacks;
            if (onFailure) onFailure();
            setSubmitting(false);
        }
    });

    const handleFormSubmit = (values: Partial<Messages>, { setSubmitting }: { setSubmitting: Function }) => {
        let messageTemplate = {};
        if (values.messageType === 'Contact Us') {
            messageTemplate = {
                ...messages?.composeMessage.contactUs,
                messageType: 'Contact Us',
                HeaderID: 1,
                MessageID: 1,
                Subject: values.subject,
                CreateDate: new Date().toISOString(),
                Body: values.message,
                Action: 'NEW',
                AttachedFiles: values.attachedFiles,
                WebUserID: profileObject?.epostPatientNum,
                ...submissionCallbacks(setSubmitting)
            };
        } else {
            messageTemplate = {
                ...messages?.composeMessage.askThePharm,
                messageType: 'Ask The Pharmacist',
                Subject: values.subject,
                CreateDate: new Date().toISOString(),
                Body: values.message,
                Action: 'NEW',
                AttachedFiles: values.attachedFiles,
                WebUserID: profileObject?.epostPatientNum,
                PersonFirstName: profileObject?.patientFirstName,
                PersonLastName: profileObject?.patientLastName,
                Email: profileObject?.patientEmailAddress,
                ...submissionCallbacks(setSubmitting)
            };
        }
        dispatch(accountPostComposeMessageRoutine.trigger(messageTemplate));
    };

    return (
        <Formik<Partial<Messages>>
            onSubmit={handleFormSubmit}
            validationSchema={MESSAGE_SCHEMA}
            initialValues={{
                messageType: '',
                subject: '',
                message: '',
                attachedFiles: []
            }}
        >
            {(formik: any) => (
                <Form
                    id="messages-form"
                    onSubmit={formik.handleSubmit}
                    autoComplete="off"
                    className="text-left"
                    data-ga-form-name={formName}
                >
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Field
                                name="messageType"
                                component={FormSelect}
                                options={messageOptions}
                                placeholder={t('pages.profile.messages.labels.messageType')}
                                errors={
                                    formik.errors?.messageType
                                        ? t('forms.errorMessages.requiredField', {
                                              label: t('pages.profile.messages.labels.messageType')
                                          })
                                        : undefined
                                }
                                touched={formik.touched.messageType}
                            />
                        </Col>
                    </Row>
                    {formik.values.messageType && (
                        <>
                            <Row>
                                <Col>
                                    <p>
                                        {formik.values.messageType === 'Contact Us'
                                            ? t('pages.profile.messages.labels.toGeneral')
                                            : t('pages.profile.messages.labels.toPharmacist')}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Text
                                        label={t('pages.profile.messages.labels.subject')}
                                        name="subject"
                                        onChange={formik.handleChange}
                                        errors={
                                            formik.errors?.subject
                                                ? t('forms.errorMessages.requiredField', {
                                                      label: t('pages.profile.messages.labels.subject')
                                                  })
                                                : undefined
                                        }
                                        touched={formik.touched.subject}
                                        value={formik.values?.subject}
                                        autocomplete="off"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextArea
                                        label={t('pages.profile.messages.labels.message')}
                                        name="message"
                                        rows={5}
                                        onChange={formik.handleChange}
                                        errors={
                                            formik.errors?.message
                                                ? t('forms.errorMessages.requiredField', {
                                                      label: t('pages.profile.messages.labels.message')
                                                  })
                                                : undefined
                                        }
                                        touched={formik.touched.message}
                                        value={formik.values?.message}
                                        maxLength={2000}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Field
                                        fieldName="attachedFiles"
                                        setFieldValue={formik.setFieldValue}
                                        component={AttachFiles}
                                        value={formik.values?.attachedFiles}
                                        disabled={formik.isSubmitting}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}

                    <Row className={`flex-column mt-3` + (props.centeredButtons ? ` text-center` : ``)}>
                        <Col>
                            <Button
                                async
                                className="sm-full md-full"
                                label={t('pages.profile.messages.labels.submit')}
                                variant="primary"
                                type="submit"
                                disabled={
                                    !formik.values.subject.length ||
                                    !formik.values.message.length ||
                                    !formik.dirty ||
                                    formik.isSubmitting
                                }
                                onClick={formik.handleSubmit}
                                dataGAFormName={formName}
                                isBusy={formik.isSubmitting}
                            />
                        </Col>
                        {typeof props.handleMessagesFormCancel !== 'undefined' &&
                            typeof props.handleMessagesFormCancel !== 'object' && (
                                <Col className={`mt-3`}>
                                    <Button
                                        label={t('pages.profile.messages.labels.cancel')}
                                        className="md-full md-pad-y-2"
                                        type="button"
                                        variant="text"
                                        onClick={props.handleMessagesFormCancel}
                                        dataGAFormName={formName}
                                    />
                                </Col>
                            )}
                    </Row>
                </Form>
            )}
        </Formik>
    );
};
