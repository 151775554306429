import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { TFunction } from 'gatsby-plugin-react-i18next';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { accountFetchHealthConditionsRoutine } from 'state/account/account.routines';

import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { HealthProfileBubbleUpdateEvent } from 'components/health-profile/health-profile.props';
import HealthProfileDisplay from 'components/health-profile/health-profile.component';
import ProfileLayout from 'components/layouts/profile/profile.layout';
import { useHealthConditions } from 'hooks/useHealthConditions';

export const HealthConditionsWarningModalContent = ({ translation }: { translation: TFunction<'translation'> }) => {
    return (
        <BirdiModalContentAlt
            subTitle={translation('modals.healthConditions.contentParagraph', {
                phoneNumber: translation('modals.healthConditions.phoneNumber')
            })}
        />
    );
};

const HealthProfile = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const healthConditions = useHealthConditions();

    const handleUpdateConditionsClick = ({ action, update }: HealthProfileBubbleUpdateEvent) => {
        dispatch(
            openModal({
                showClose: true,
                type: 'danger',
                size: 'lg',
                headerContent: <BirdiModalHeaderDanger headerText={t('modals.healthConditions.title')} icon="alert" />,
                bodyContent: <HealthConditionsWarningModalContent translation={t} />,
                ctas: [
                    {
                        label: t('modals.healthConditions.submit'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                            dispatch(action(update));
                        },
                        dataGALocation: 'HealthProfileWarning'
                    }
                ]
            })
        );
    };

    useEffect(() => {
        dispatch(accountFetchHealthConditionsRoutine.trigger());
    }, []);

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.healthProfile.eyebrowText`)}
            title={t(`pages.profile.healthProfile.title`)}
            heading={t(`pages.profile.healthProfile.heading`)}
            subhead={
                <p className="small">
                    {t(`pages.profile.healthProfile.subHeading`)} <strong>1-877-668-4987</strong>
                </p>
            }
        >
            <HealthProfileDisplay
                onUpdateHealthConditions={handleUpdateConditionsClick}
                healthConditions={healthConditions}
            />
        </ProfileLayout>
    );
};

export default HealthProfile;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
