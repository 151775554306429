import React from 'react';

import withOnKeyDown from 'hoc/withOnKeyDown';

import { VisibilityIconProps } from 'ui-kit/icons/visibility-icon/visibility-icon.props';

const VisibilityIcon = ({ onClick, onKeyDown, style, toggled, imageAltText }: VisibilityIconProps) => {
    return (
        <div
            className="visibility-icon"
            style={style}
            role="button"
            onClick={onClick}
            onKeyDown={onKeyDown}
            tabIndex={0}
        >
            {toggled ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
                    <title>{imageAltText}</title>
                    <path
                        d="M10 0.75C5.83337 0.75 2.27504 3.34167 0.833374 7C2.27504 10.6583 5.83337 13.25 10 13.25C14.1667 13.25 17.725 10.6583 19.1667 7C17.725 3.34167 14.1667 0.75 10 0.75ZM10 11.1667C7.70004 11.1667 5.83337 9.3 5.83337 7C5.83337 4.7 7.70004 2.83333 10 2.83333C12.3 2.83333 14.1667 4.7 14.1667 7C14.1667 9.3 12.3 11.1667 10 11.1667ZM10 4.5C8.61671 4.5 7.50004 5.61667 7.50004 7C7.50004 8.38333 8.61671 9.5 10 9.5C11.3834 9.5 12.5 8.38333 12.5 7C12.5 5.61667 11.3834 4.5 10 4.5Z"
                        fill="#686D7B"
                    />
                </svg>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <title>{imageAltText}</title>
                    <g clip-path="url(#clip0_264_6789)">
                        <path
                            d="M10 5.83333C12.3 5.83333 14.1667 7.7 14.1667 10C14.1667 10.5417 14.0584 11.05 13.8667 11.525L16.3 13.9583C17.5584 12.9083 18.55 11.55 19.1584 10C17.7167 6.34167 14.1584 3.75 9.99171 3.75C8.82504 3.75 7.70837 3.95833 6.67504 4.33333L8.47504 6.13333C8.95004 5.94167 9.45837 5.83333 10 5.83333ZM1.66671 3.55833L3.56671 5.45833L3.95004 5.84167C2.56671 6.91667 1.48337 8.35 0.833374 10C2.27504 13.6583 5.83337 16.25 10 16.25C11.2917 16.25 12.525 16 13.65 15.55L14 15.9L16.4417 18.3333L17.5 17.275L2.72504 2.5L1.66671 3.55833ZM6.27504 8.16667L7.56671 9.45833C7.52504 9.63333 7.50004 9.81667 7.50004 10C7.50004 11.3833 8.61671 12.5 10 12.5C10.1834 12.5 10.3667 12.475 10.5417 12.4333L11.8334 13.725C11.275 14 10.6584 14.1667 10 14.1667C7.70004 14.1667 5.83337 12.3 5.83337 10C5.83337 9.34167 6.00004 8.725 6.27504 8.16667ZM9.86671 7.51667L12.4917 10.1417L12.5084 10.0083C12.5084 8.625 11.3917 7.50833 10.0084 7.50833L9.86671 7.51667Z"
                            fill="#686D7B"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_264_6789">
                            <rect width="20" height="20" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            )}
        </div>
    );
};

export default withOnKeyDown(VisibilityIcon);
