import React, { FC, ReactElement } from 'react';

import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { Container, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import sanitizeHtml from 'sanitize-html';

import withBackgroundImage from 'hoc/withBackgroundImage';

import Link from 'ui-kit/link/link';
import { replaceStringWith } from 'util/string';

import { HeroProps } from 'components/hero/hero.props';

import './hero.style.scss';

const Hero: FC<HeroProps> = ({
    backgroundColor,
    backgroundColorFullWidth,
    backgroundImage,
    eyebrowText,
    eyebrowColor = 'light',
    image,
    imagePosition,
    imageAltText,
    linkLabel,
    linkTo,
    size = 'large',
    text,
    title,
    subTitle,
    sectionIndex
}: HeroProps): ReactElement => {
    const heroContainerClasses = classNames(
        'hero',
        { [`${backgroundColor}`]: backgroundColor },
        { [`${size}`]: size },
        { [`${imagePosition}`]: imagePosition },
        { 'has-background-image': backgroundImage },
        { 'has-background-color-full-width': backgroundColorFullWidth && !backgroundImage },
        { 'has-no-secondary-image': !image }
    );

    const heroSubTitleClasses = classNames('hero-subtitle', { h3: sectionIndex === 0 }, { h4: sectionIndex > 0 });
    return (
        <Container
            fluid
            className={heroContainerClasses}
            data-ga-index={`${sectionIndex > -1 ? 1 + sectionIndex : 0}`}
            data-ga-location={backgroundImage ? 'Multiple Image Hero' : 'Rounded Hero'}
            data-ga-title={title}
        >
            <Row>
                <Col xs="12" md="12" lg={image ? 6 : 12} className="d-flex align-items-center">
                    <div className="hero-content">
                        {eyebrowText && (
                            <div className={`h5 hero-eyebrow-text ${eyebrowColor ? eyebrowColor : ''}`}>
                                {eyebrowText}
                            </div>
                        )}
                        {sectionIndex === 0 && <h1 className="hero-title">{title}</h1>}
                        {sectionIndex > 0 && <h2 className="hero-title">{title}</h2>}
                        {subTitle && <div className={heroSubTitleClasses}>{subTitle}</div>}
                        {text && (
                            <div
                                className="hero-text"
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(replaceStringWith(text, /\n/g, '<br />'), {
                                        allowedAttributes: { '*': ['br'] }
                                    })
                                }}
                            />
                        )}
                        {
                            <Link
                                to={linkTo}
                                label={linkLabel}
                                variant="cta-button"
                                dataGALocation={backgroundImage ? 'Multiple Image Hero' : 'Rounded Hero'}
                            />
                        }
                    </div>
                </Col>

                {image && (
                    <Col xs="12" md="12" lg="6" className={`d-flex hero-image ${imagePosition ?? ''}`}>
                        <div className="hero-image-container">
                            <GatsbyImage image={getImage(image)} alt={imageAltText} />
                        </div>
                    </Col>
                )}
            </Row>
        </Container>
    );
};

export const HeroWithBackgroundImage = withBackgroundImage(Hero, 'heroBackground');
export default Hero;
