import React, { useContext } from 'react';
import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';
import { AccordionCustomCtaProps } from 'ui-kit/accordion/accordion.props';
import { Row, Col, Accordion, Container } from 'react-bootstrap';
import withOnKeyDown from 'hoc/withOnKeyDown';
import { paragraphToComponent } from 'providers/paragraphs/paragraphs';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import './acknowledgement-info.style.scss';
export interface AcknowledgementInfoProps {
    data: any;
    eventKey: string;
    handleNextPanel: Function;
    nextRef: string;
    activeKey: string;
}

export interface AcknowledgementInfoCtaProps extends AccordionCustomCtaProps {
    title: any;
}
const AcknowledgementInfoCta = withOnKeyDown(
    ({ title, isToggled, onClick, onKeyDown }: AcknowledgementInfoCtaProps) => {
        return (
            <div className="acknowledgement-info" onClick={onClick} role="button" tabIndex={0} onKeyDown={onKeyDown}>
                <Row>
                    <Col className="d-flex birdi-accordion-toggle-title-wrapper ml-3">
                        <div className="mb-0 h3">{title}</div>
                    </Col>
                    <Col sm={2} className="d-flex justify-content-end align-items-center ml-0 pr-4">
                        <ChevronIcon direction={isToggled ? 'up' : 'down'} />
                    </Col>
                </Row>
            </div>
        );
    }
);

function ContextAwareToggle({
    label,
    eventKey,
    activeKey,
    callback,
    handleNextPanel,
    nextRef
}: {
    children?: object;
    label: string;
    eventKey: string;
    activeKey: string;
    callback?: any;
    handleNextPanel?: Function;
    nextRef?: string;
}) {
    const currentEventKey = useContext(AccordionContext);
    const toggleAccordion = useAccordionToggle(eventKey, () => callback && callback(eventKey));
    const decoratedOnClick = (e: any) => {
        if (eventKey !== currentEventKey) {
            toggleAccordion(e);
        }
        if (handleNextPanel && nextRef) handleNextPanel(nextRef);
    };

    return <AcknowledgementInfoCta title={label} isToggled={activeKey === eventKey} onClick={decoratedOnClick} />;
}
const AcknowledgementInfo = ({ data, eventKey, handleNextPanel, nextRef, activeKey }: AcknowledgementInfoProps) => {
    return (
        <div className="accordionItem  birdi-accordion-toggle birdi-accordion-toggle-content">
            <div className="accordionItem--header ">
                <ContextAwareToggle
                    eventKey={eventKey}
                    activeKey={activeKey}
                    label={data.nodes[0]?.title}
                    handleNextPanel={handleNextPanel}
                    nextRef={activeKey !== eventKey ? eventKey : nextRef}
                />
            </div>
            <Accordion.Collapse eventKey={eventKey}>
                <Container fluid>
                    <Row>
                        <Col>
                            {data.nodes[0]?.relationships?.field_landing_page_content.map(
                                (paragraph: any, index: number) => (
                                    <React.Fragment key={`acknowledgement_${index}`}>
                                        {paragraphToComponent(paragraph?.internal.type, paragraph, index + 10)}
                                    </React.Fragment>
                                )
                            )}
                        </Col>
                    </Row>
                </Container>
            </Accordion.Collapse>
        </div>
    );
};
export default AcknowledgementInfo;
