import React, { ReactElement, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { Row, Col, Container } from 'react-bootstrap';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import storageHelper from 'util/storageHelper';
import 'components/alert-banner/alert-banner.style.scss';
import './cookie-banner.style.scss';
import CircleInfo from 'ui-kit/icons/info/circle-info-icon';

const hasBeenDismissed = () => storageHelper.cookies.getCookieAccepted();
const CookieBanner = (): ReactElement => {
    const [show, setShow] = useState(true);
    const { t } = useTranslation();
    if (show && !hasBeenDismissed()) {
        return (
            <Alert
                className="alert-banner cookie-banner mb-0"
                onClose={() => {
                    setShow(false);
                    storageHelper.cookies.setCookieAccepted();
                }}
                variant={'dark'}
                dismissible
                data-ga-location="Cookie Banner"
                data-ga-type={'dark'}
            >
                <Container>
                    <Row>
                        <Col
                            xs={{ span: 8, offset: 2 }}
                            sm={{ span: 8, offset: 2 }}
                            md={{ span: 12, offset: 0 }}
                            lg="12"
                        >
                            <p className="alert-message">
                                <CircleInfo />
                                {t('components.cookie.byUsingBirdi')}{' '}
                                <Alert.Link href="/website-terms-of-use">
                                    {t('components.cookie.termsOfUse')}
                                </Alert.Link>{' '}
                                {t('components.cookie.and')}{' '}
                                <Alert.Link href="/website-privacy-policy">
                                    {t('components.cookie.webPrivacy')}
                                </Alert.Link>{' '}
                                {t('components.cookie.includingCollection')}
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Alert>
        );
    }
    return <></>;
};

export default CookieBanner;
