import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';
import { createRoutine } from 'redux-saga-routines';
import {
    Address,
    AdjudicatedRxes,
    AuthenticateUserResponse,
    CreateOrUpdateResponse,
    EasyRefillResponse,
    EasyRefillRxResult,
    EasyRefillSubmitOrderResponse,
    GetEasyRefillAddressDataResponse,
    GetEasyRefillPatientDataResponse,
    GetEasyRefillPaymentCardDataResponse,
    PaymentCard,
    VerifyUserResponse
} from 'types/easy-refill';

export interface EasyRefillState {
    userVerified: 'UserNotVerified' | 'UserVerified' | 'MaxAttemptsReached' | '';
    securityToken: string;
    secretKeyHash: string;
    bearerToken: string;
    error: {
        messageText?: string;
    };
    loadingPrescriptions: boolean;
    prescriptionsLoaded: boolean;
    firstName: string;
    rxResults: EasyRefillRxResult[];
    epostPlanNum: string;
    planAlias: string;
    loadingAddress: boolean;
    addresses: Address[];
    loadingPaymentCard: boolean;
    paymentCards: PaymentCard[];
    refillOrder: {
        rxsToRefill: string[];
        expeditedShipping: boolean;
    };
    adjudicatedRxes: AdjudicatedRxes;
    orderInvoiceNumber: string;
    emailSent: boolean;
}

const initialState: EasyRefillState = {
    userVerified: '',
    securityToken: '',
    secretKeyHash: '',
    bearerToken: '',
    error: {
        messageText: undefined
    },
    loadingPrescriptions: false,
    prescriptionsLoaded: false,
    firstName: '',
    rxResults: [],
    epostPlanNum: '',
    planAlias: '',
    loadingAddress: false,
    addresses: [],
    loadingPaymentCard: false,
    paymentCards: [],
    refillOrder: {
        rxsToRefill: [],
        expeditedShipping: false
    },
    adjudicatedRxes: {},
    orderInvoiceNumber: '',
    emailSent: false
};

// Routines
export const easyRefillVerifyUserRoutine = createRoutine('easy-refill/VERIFY_USER');
export const easyRefillGetSecretKeyHashRoutine = createRoutine('easy-refill/GET_SECRET_KEY_HASH');
export const easyRefillAuthenticateUserRoutine = createRoutine('easy-refill/AUTHENTICATE_USER');
export const easyRefillGetPatientDataRoutine = createRoutine('easy-refill/GET_PATIENT_DATA');
export const easyRefillGetPatientAddressesRoutine = createRoutine('easy-refill/GET_PATIENT_ADDRESSES');
export const easyRefillGetPatientPaymentCardsRoutine = createRoutine('easy-refill/GET_PATIENT_PAYMENT_CARDS');
export const easyRefillStartOrderRoutine = createRoutine('easy-refill/START_ORDER');
export const easyRefillSubmitOrderRoutine = createRoutine('easy-refill/SUBMIT_ORDER');
export const easyRefillCancelOrderRoutine = createRoutine('easy-refill/CANCEL_ORDER');
export const easyRefillRemoveRxToRefillRoutine = createRoutine('easy-refill/REMOVE_RX_TO_REFILL');
export const easyRefillRemoveAllRxToRefillRoutine = createRoutine('easy-refill/REMOVE_ALL_RX_TO_REFILL');
export const easyRefillAddRxToRefillRoutine = createRoutine('easy-refill/ADD_RX_TO_REFILL');

const easyRefillSlice = createSlice({
    name: 'easy-refill',
    initialState,
    reducers: {
        setSecurityToken: (state, action: PayloadAction<{ securityToken: string }>) => {
            state.securityToken = action.payload.securityToken;
        },
        setShippingChoice: (state, action: PayloadAction<boolean>) => {
            state.refillOrder.expeditedShipping = action.payload;
        },
        resetBearerToken: (state) => {
            state.bearerToken = initialState.bearerToken;
        }
    },
    extraReducers: ({ addCase }) => {
        /**
         * Easy Refill reducers
         */
        // Verify User
        addCase(easyRefillVerifyUserRoutine.SUCCESS, (state, { payload }: PayloadAction<VerifyUserResponse>) =>
            produce(state, (draftState) => {
                draftState.userVerified = payload.verificationStatus;
            })
        );
        addCase(easyRefillVerifyUserRoutine.FAILURE, (state, { payload }: PayloadAction<VerifyUserResponse>) =>
            produce(state, (draftState) => {
                draftState.error.messageText = payload.messageText;
            })
        );
        // Getting user secret key hash for authentication
        addCase(easyRefillGetSecretKeyHashRoutine.SUCCESS, (state, { payload }: PayloadAction<string>) =>
            produce(state, (draftState) => {
                draftState.secretKeyHash = payload;
            })
        );
        addCase(easyRefillGetSecretKeyHashRoutine.FAILURE, (state, { payload }: PayloadAction<string>) =>
            produce(state, (draftState) => {
                draftState.error.messageText = payload;
            })
        );
        // authenticate user
        addCase(
            easyRefillAuthenticateUserRoutine.SUCCESS,
            (state, { payload }: PayloadAction<AuthenticateUserResponse>) =>
                produce(state, (draftState) => {
                    draftState.bearerToken = payload.bearerToken;
                })
        );
        addCase(
            easyRefillAuthenticateUserRoutine.FAILURE,
            (state, { payload }: PayloadAction<AuthenticateUserResponse>) =>
                produce(state, (draftState) => {
                    draftState.bearerToken = initialState.bearerToken;
                    draftState.error.messageText = payload.messageText;
                })
        );
        // Fetching Patient data
        addCase(easyRefillGetPatientDataRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.loadingPrescriptions = true;
            })
        );
        addCase(
            easyRefillGetPatientDataRoutine.SUCCESS,
            (state, { payload }: PayloadAction<GetEasyRefillPatientDataResponse>) =>
                produce(state, (draftState) => {
                    draftState.loadingPrescriptions = false;
                    draftState.rxResults = payload.rxResults;
                    draftState.firstName = payload.firstName;
                    draftState.epostPlanNum = payload.epostPlanNum;
                    draftState.planAlias = payload.planAlias;
                    draftState.prescriptionsLoaded = true;
                })
        );
        addCase(easyRefillGetPatientDataRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.loadingPrescriptions = false;
                draftState.rxResults = [];
            })
        );
        addCase(easyRefillGetPatientAddressesRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAddress = true;
            })
        );
        addCase(
            easyRefillGetPatientAddressesRoutine.SUCCESS,
            (state, { payload }: PayloadAction<GetEasyRefillAddressDataResponse>) =>
                produce(state, (draftState) => {
                    draftState.loadingAddress = false;
                    draftState.addresses = payload.addresses;
                })
        );
        addCase(easyRefillGetPatientAddressesRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAddress = false;
                draftState.addresses = [];
            })
        );
        addCase(easyRefillGetPatientPaymentCardsRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.loadingPaymentCard = true;
            })
        );
        addCase(
            easyRefillGetPatientPaymentCardsRoutine.SUCCESS,
            (state, { payload }: PayloadAction<GetEasyRefillPaymentCardDataResponse>) =>
                produce(state, (draftState) => {
                    draftState.loadingPaymentCard = false;
                    draftState.paymentCards = payload.paymentCards;
                })
        );
        addCase(easyRefillGetPatientPaymentCardsRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.loadingPaymentCard = false;
                draftState.paymentCards = [];
            })
        );
        addCase(easyRefillStartOrderRoutine.SUCCESS, (state, { payload }: PayloadAction<CreateOrUpdateResponse>) =>
            produce(state, (draftState) => {
                draftState.adjudicatedRxes = payload.adjudicatedRxes;
            })
        );
        addCase(easyRefillStartOrderRoutine.FAILURE, (state, { payload }: PayloadAction<CreateOrUpdateResponse>) =>
            produce(state, (draftState) => {
                draftState.adjudicatedRxes = {};
                draftState.error.messageText = payload.messageText;
            })
        );
        addCase(
            easyRefillSubmitOrderRoutine.SUCCESS,
            (state, { payload }: PayloadAction<EasyRefillSubmitOrderResponse>) =>
                produce(state, (draftState) => {
                    draftState.orderInvoiceNumber = payload.orderInvoiceNumber;
                    draftState.emailSent = payload.emailSent;
                })
        );
        addCase(easyRefillSubmitOrderRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.adjudicatedRxes = {};
            })
        );
        addCase(easyRefillCancelOrderRoutine.SUCCESS, (state, { payload }: PayloadAction<EasyRefillResponse>) =>
            produce(state, (draftState) => {
                draftState.adjudicatedRxes = {};
                draftState.rxResults = [];
                draftState.refillOrder.rxsToRefill = [];
            })
        );
        addCase(easyRefillCancelOrderRoutine.FAILURE, (state) => produce(state, (draftState) => {}));
        addCase(easyRefillRemoveRxToRefillRoutine.SUCCESS, (state, { payload }: PayloadAction<string[]>) =>
            produce(state, (draftState) => {
                draftState.refillOrder.rxsToRefill = payload;
            })
        );
        addCase(easyRefillAddRxToRefillRoutine.SUCCESS, (state, { payload }: PayloadAction<string[]>) =>
            produce(state, (draftState) => {
                draftState.refillOrder.rxsToRefill = payload;
            })
        );
    }
});

export const easyRefillActions = easyRefillSlice.actions;

export default easyRefillSlice.reducer;
