import { call } from 'redux-saga/effects';

import { BaseEffectHandler } from 'types/saga-handler';

export function* baseEffectHandler<Payload, Response = any>({
    service,
    data,
    isAuthenticatedService,
    isLoggedIn,
    onResponse,
    onError
}: BaseEffectHandler<Payload, Response>): any {
    try {
        if (!isAuthenticatedService || (isAuthenticatedService && isLoggedIn)) {
            const response = yield call(service, data);
            if (!response.error && response.status !== 500) {
                if (onResponse) yield onResponse(response);
            } else {
                console.error('Error Response', response);
                if (onError) yield onError({ response: response });
                if (response.error) {
                    throw response.error;
                }
            }
        }
    } catch (err) {
        console.error('err => ', err);
        if (onError) yield onError(err);
    }
}

const defaultExport = { baseEffectHandler };
export default defaultExport;
