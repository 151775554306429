import { ReactElement, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { DrugPriceInfoCardProps } from './drug-price-info-card.props';

import './drug-price-info-card.style.scss';

const DrugPriceInfoCard = ({ prescription, price }: DrugPriceInfoCardProps): ReactElement => {
    const [dismissed, setDismissed] = useState<boolean>(false);
    const { t } = useTranslation();

    return (
        <>
            <div className="drug-price-info-card">
                {dismissed ? (
                    <div className="view-price-information-link">
                        <button type="button" className="btn btn-text" onClick={() => setDismissed(false)}>
                            <div className="btn-label">{t('components.drugPriceInfoCard.viewPriceInfoLink')}</div>
                        </button>
                    </div>
                ) : (
                    <div className="info-card-container">
                        <button type="button" className="close" onClick={() => setDismissed(true)}>
                            <span aria-hidden="true">×</span>
                            <span className="sr-only">{t('components.drugPriceInfoCard.labels.close')}</span>
                        </button>
                        <div className="title">{t('components.drugPriceInfoCard.title')}</div>
                        <div className="drug-details-container">
                            <div className="drug-name-and-prescription">
                                <div className="drug-name">{prescription?.drugName}</div>
                                <div className="prescription-details">
                                    <div className="quantity">
                                        {t('components.drugPriceInfoCard.labels.qty')} {prescription?.qty}
                                    </div>
                                    <div className="strength">{prescription?.strength}</div>
                                    {prescription?.strengthWithPackage && (
                                        <div className="package">{prescription?.strengthWithPackage}</div>
                                    )}
                                    <div className="form">{prescription?.drugForm}</div>
                                </div>
                            </div>
                            <div className="price">
                                {price}
                                <sup>*</sup>
                            </div>
                        </div>
                        <div className="foot-notes">
                            <sup>*</sup> {t('components.drugPriceInfoCard.footNotes')}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default DrugPriceInfoCard;
