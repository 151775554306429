import { OVERRIDE_ADJUDICATION, ALLOW_INSURED_BIRDI_PRICE } from 'gatsby-env-variables';

import { safeParseFloat } from 'util/number';
import { PlansObjectPayload } from 'state/account/account.services';
import { CartObjectPayload, ExtendedCartObjectPayload } from 'state/cart/cart.services';
import { DrugWithDiscountPrice } from 'state/drug/drug.reducers';
import { PrescriptionObjectPayload } from 'state/medicine-cabinet/medicine-cabinet.services';
import { ExtendedRefillRxs, RefillRxs } from 'types/order-prescription';
import { EasyRefillRxResult } from 'types/easy-refill';

//
// --- Types ---

type RxLineErrorCodeKey = keyof typeof RX_LINE_ERROR;
export type RxLineErrorCode = typeof RX_LINE_ERROR[RxLineErrorCodeKey];

//
// --- Constants ---

export const EXPEDITED_SHIPPING_COST = 25;
export const EXPEDITED_SHIPPING_ID = '638';
export const DEFAULT_SHIPPING_ID = '505';

/** error codes that can be returned as rx_line_error value when adding prescriptions to cart */
export const RX_LINE_ERROR = {
    NONE: 0,
    ADD_ORDER_LINE_ERROR: 1,
    ADJUDICATE_RX_ERROR: 2,
    NO_CONTRACT_WITH_PHARMACY: 40,
    NO_LONGER_COVERED: 65,
    NOT_COVERED: 70
} as const;

/** rx_line_error codes for various insurance related issues that result in birdi price being displayed */
const birdiPriceRxLineErrorCodes: ReadonlyArray<RxLineErrorCode> = [
    RX_LINE_ERROR.NO_CONTRACT_WITH_PHARMACY,
    RX_LINE_ERROR.NO_LONGER_COVERED,
    RX_LINE_ERROR.NOT_COVERED
];

//
// --- Cart Util Functions ---

export function hasAdjudicatedPrice(
    item: any,
    currentPrescription: PrescriptionObjectPayload | EasyRefillRxResult | undefined
): boolean {
    if (item) {
        // TO TEST THE CART PAGES AS IF ADJUDICATION IS ON, UPDATE THE ENVIRONMENT VARIABLE
        if (OVERRIDE_ADJUDICATION) {
            return true;
        }
        //
        // rxLineError field can contain values
        //   {0 = None, 1 = AddOrderLineError, 2 = AdjudicateRxError, 40 = NoContractWithPharmacy, 65 = PatientNoLongerCovered, 70 = Not Covered};
        //   -- For now, we are treating all non-zero results as the same, this may change
        if (item.rxLineError === RX_LINE_ERROR.NONE) {
            return true;
        } else {
            if (currentPrescription) {
                if (
                    item.messageStatus &&
                    currentPrescription.webEligibilityStatus !== 'NOT_ELIGIBLE' &&
                    currentPrescription.webEligibilityStatus !== 'AUTH_REQ'
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return item.messageStatus;
            }
        }
    }
    return false;
}

export function isInBirdiFormulary(rxNumber: string, drugDiscountPrices: DrugWithDiscountPrice[] | undefined) {
    // Use the drugDiscountPrices data to determine if we have the drug in the Birdi formulary
    const inFormulary = drugDiscountPrices?.find((rx) => {
        if (rx.rxNumber === rxNumber) {
            return true;
        }
        return false;
    });
    return inFormulary;
}

export function itemIsUsingBirdiPrice(item: ExtendedRefillRxs, accountHasInsurance: boolean) {
    if (
        accountHasInsurance &&
        ((item.birdiPlanNum && item.planNum === item.birdiPlanNum) ||
            item.showStrikeThruPricing ||
            item.showBirdiPricing)
    ) {
        return true;
    } else {
        return false;
    }
}

export function getBirdiPricePlanNum(plans: PlansObjectPayload[] | undefined) {
    if (plans) {
        const matchingPlan = plans.find((plan) => {
            if (plan.planAlias.toUpperCase() === 'BRD01') {
                return true;
            } else {
                return false;
            }
        });
        if (matchingPlan) {
            return matchingPlan.epostPlanNum;
        }
    }
    return undefined;
}

export function isItemPriceKnown(
    item: any,
    currentPrescription: PrescriptionObjectPayload | undefined,
    accountHasInsurance: boolean
): boolean {
    if (
        hasAdjudicatedPrice(item, currentPrescription) ||
        (!hasAdjudicatedPrice(item, currentPrescription) &&
            accountHasInsurance &&
            ALLOW_INSURED_BIRDI_PRICE &&
            (showStrikeThruPricing(item, accountHasInsurance) || showBirdiPricing(item, accountHasInsurance)))
    ) {
        return true;
    } else {
        return false;
    }
}

export function showStrikeThruPricing(item: any, accountHasInsurance: boolean): boolean {
    if (
        accountHasInsurance &&
        ALLOW_INSURED_BIRDI_PRICE &&
        birdiPriceRxLineErrorCodes.includes(item.rxLineError) &&
        item?.awpPrice !== 'NA' &&
        parseFloat(item?.awpPrice) !== 0 &&
        item.birdiPrice !== 'NA' &&
        parseFloat(item?.awpPrice) > item.birdiPrice
    ) {
        return true;
    } else {
        return false;
    }
}

export function showBirdiPricing(item: any, accountHasInsurance: boolean): boolean {
    if (
        accountHasInsurance &&
        ALLOW_INSURED_BIRDI_PRICE &&
        birdiPriceRxLineErrorCodes.includes(item.rxLineError) &&
        (item?.awpPrice === 'NA' || safeParseFloat(item?.awpPrice) <= item.birdiPrice) &&
        item.birdiPrice !== 'NA'
    ) {
        return true;
    } else {
        return false;
    }
}

export function processCart(
    cart: CartObjectPayload,
    accountHasInsurance: boolean,
    prescriptionsObject: PrescriptionObjectPayload[] | undefined,
    drugDiscountPrices: DrugWithDiscountPrice[] | undefined,
    accountPlans: PlansObjectPayload[] | undefined
): ExtendedCartObjectPayload {
    let extendedCart: ExtendedCartObjectPayload = { ...cart };
    let itemHasUnknownPrice = false;
    let cartItemsObject: RefillRxs[] = cart.refillRxs;
    const birdiPricePlanNum: string | undefined = getBirdiPricePlanNum(accountPlans);

    if (cartItemsObject?.length > 0) {
        let updatedRefillRxs: ExtendedRefillRxs[] = [];
        let calculatedOrderTotal: number = 0;
        cartItemsObject.map((item: ExtendedRefillRxs) => {
            let updatedLineItem = { ...item };
            updatedLineItem.birdiPlanNum = birdiPricePlanNum;
            updatedLineItem.hasKnownPrice = false;

            const currentPrescription = prescriptionsObject?.find((obj: any) => {
                return obj.rxNumber === item.rxNumber;
            });

            const prescriptionInBirdiFormulary = isInBirdiFormulary(item.rxNumber, drugDiscountPrices);
            if (prescriptionInBirdiFormulary) {
                updatedLineItem.awpPrice = prescriptionInBirdiFormulary.awpPrice;
                updatedLineItem.birdiPrice = prescriptionInBirdiFormulary.price;
                updatedLineItem.showStrikeThruPricing = showStrikeThruPricing(updatedLineItem, accountHasInsurance);
                updatedLineItem.showBirdiPricing = showBirdiPricing(updatedLineItem, accountHasInsurance);
            }
            updatedLineItem.isUsingBirdiPrice = itemIsUsingBirdiPrice(updatedLineItem, accountHasInsurance);
            updatedLineItem.hasKnownPrice = currentPrescription
                ? isItemPriceKnown(updatedLineItem, currentPrescription, accountHasInsurance)
                : false;
            if (!updatedLineItem.hasKnownPrice) {
                itemHasUnknownPrice = true;
            } else {
                if (updatedLineItem.showStrikeThruPricing || updatedLineItem.showBirdiPricing) {
                    updatedLineItem.planNum = birdiPricePlanNum;
                    if (updatedLineItem.birdiPrice) {
                        calculatedOrderTotal += Number(updatedLineItem.birdiPrice);
                    }
                } else {
                    if (updatedLineItem.patientCopay) calculatedOrderTotal += updatedLineItem.patientCopay;
                }
            }

            let birdiPriceNumeric = Number(updatedLineItem.birdiPrice);
            if (isNaN(birdiPriceNumeric)) {
                birdiPriceNumeric = 0;
            }
            // final price to be displayed in most areas, birdi price or patient copay depending on isUsingBirdiPrice
            updatedLineItem.finalPrice = updatedLineItem.isUsingBirdiPrice
                ? birdiPriceNumeric
                : updatedLineItem.patientCopay;

            // disclaimer message properties
            if (
                !hasAdjudicatedPrice(updatedLineItem, currentPrescription) &&
                updatedLineItem.hasKnownPrice &&
                (updatedLineItem.showStrikeThruPricing || updatedLineItem.showBirdiPricing)
            ) {
                updatedLineItem.disclaimerTranslationKey =
                    updatedLineItem.rxLineError === RX_LINE_ERROR.NO_CONTRACT_WITH_PHARMACY
                        ? 'pages.cart.rxItemNoPharmacyContractErrorMessage'
                        : updatedLineItem.rxLineError === RX_LINE_ERROR.NO_LONGER_COVERED
                        ? 'pages.cart.rxItemNoLongerCoveredErrorMessage'
                        : 'pages.cart.rxItemNotCoveredErrorMessage';
                updatedLineItem.showDisclaimer = true;
            } else {
                updatedLineItem.disclaimerTranslationKey = undefined;
                updatedLineItem.showDisclaimer = false;
            }

            updatedRefillRxs.push(updatedLineItem);
        });

        extendedCart.itemHasUnknownPrice = itemHasUnknownPrice;
        extendedCart.extendedRefillRxs = updatedRefillRxs;
        extendedCart.orderTotal = calculatedOrderTotal.toString();
    }
    return extendedCart;
}

// DRX-1658: These two functions were necessary to treat data in the frontend.
// We need to define whether the returned data will change or continue to be
// treated in the frontend.

export function lowercaseAndCapitalize(item: string): string {
    const lowercaseStr = item.toLowerCase();
    const words = lowercaseStr.split(' ');

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].slice(1);
    }
    return words.join(' ');
}

export function ellipsify(str: string): string {
    if (str.length > 11) {
        return str.substring(0, 11) + '...';
    } else {
        return str;
    }
}
