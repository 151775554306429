import React, { useState } from 'react';

import Text from 'ui-kit/text/text';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import VisibilityIcon from 'ui-kit/icons/visibility-icon/visibility-icon';

import { PasswordTextProps } from 'ui-kit/password-text/password-text.props';

const PasswordText = ({ showErrorsText = false, type, ...rest }: PasswordTextProps) => {
    const [showText, setShowText] = useState(false);
    const { t } = useTranslation();

    return (
        <div className={`password-text`}>
            <Text
                type={showText ? 'text' : type}
                showErrorsText={showErrorsText}
                autocomplete="current-password"
                {...rest}
                icon={
                    <VisibilityIcon
                        toggled={showText}
                        style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            top: '16px',
                            right: '20px'
                        }}
                        imageAltText={t('components.passwordText.reveal')}
                        onClick={() => setShowText(!showText)}
                    />
                }
            />
        </div>
    );
};

export default PasswordText;
