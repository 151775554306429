import { createRoutine } from 'redux-saga-routines';

export const getCartRoutine = createRoutine('CART/GET_CART');
export const startCartRoutine =
    createRoutine<{ rxNumbers: string[]; onSuccess?: (data: any) => void; onFailure?: (data: any) => void }>(
        'CART/START_CART'
    );
export const cartUpdateCartRoutine = createRoutine('CART/UPDATE_CART');
export const updateRefillLinesRoutine =
    createRoutine<{ rxNumbers: string[]; onSuccess?: (data: any) => void; onFailure?: (data: any) => void }>(
        'CART/UPDATE_REFILL_LINES'
    );
export const cancelOrderLine = createRoutine('CART/CANCEL_ORDER_LINE');
export const cartCancelOrderRoutine = createRoutine('CART/CANCEL_ORDER');
export const cartCompleteOrderRoutine = createRoutine('CART/COMPLETE_ORDER');
export const cartUpdateExpeditedShippingRoutine = createRoutine('CART/UPDATE_EXPEDITED_SHIPPING');
export const cartUpdateLineItemsRoutine = createRoutine('CART/UPDATE_LINE_ITEM');
export const cartUpdateShippingRoutine = createRoutine('CART/UPDATE_SHIPPING');
export const cartUpdatePaymentRoutine = createRoutine('CART/UPDATE_PAYMENT');
