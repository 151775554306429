import { ClientId, CLIENT_ID } from 'const/client';

//
// --- Types ---

export interface Client {
    clientId: ClientId;
    isBirdi: boolean;
    isEvio: boolean;
    isPrivateLabel: boolean;
}

//
// --- Util Functions ---

export function getSiteClient(): Client {
    const clientId = (process.env.GATSBY_CLIVAR as ClientId) || 'birdi';

    return {
        clientId,
        isBirdi: clientId === CLIENT_ID.BIRDI,
        isEvio: clientId === CLIENT_ID.EVIO,
        isPrivateLabel: clientId !== CLIENT_ID.BIRDI
    };
}
