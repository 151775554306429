import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Components & UI
import Button from 'ui-kit/button/button';
import { AddressCardProps } from 'components/shipping-addresses-v2/address-card/address-card.props';
import './address-card.style.scss';

const AddressCard: React.FC<AddressCardProps> = ({
    defaultAddress,
    address1,
    address2,
    city,
    state,
    zipcode,
    zipcodeFour,
    openEdit,
    isChecked,
    index,
    handleSetAsShipping,
    onSetAsDefault,
    addressType,
    showLabel = true
}) => {
    const { t } = useTranslation();

    return (
        <div className="address-card">
            {handleSetAsShipping && (
                <section className="address-card__selection">
                    <label className="default-address text-slate">
                        <input
                            type="radio"
                            name="option"
                            id={`address-${index}`}
                            onChange={handleSetAsShipping}
                            checked={isChecked}
                        />
                    </label>
                </section>
            )}
            <div className="address-card__content">
                <div className="address-card__eyebrow">
                    {addressType && showLabel && (
                        <p className="address-card__type text-left">
                            <small>
                                {t('pages.profile.shippingAddress.addressType', {
                                    type: addressType.toLowerCase()
                                })}
                            </small>
                        </p>
                    )}
                </div>
                <div className="address-card__address">
                    <address className={'align-self-start'}>
                        <p>
                            {address1} {address2 ? address2 : ''}
                        </p>
                        <p>
                            {city}, {state} {zipcodeFour ? `${zipcode}-${zipcodeFour}` : zipcode}
                        </p>
                    </address>
                </div>
                <div className="address-card__actions">
                    <Button
                        className="address-card__action no-min-width"
                        variant="text"
                        type="button"
                        onClick={openEdit}
                        dataGAFormName="address-card"
                        label={t('shipping.editAddress')}
                    />
                    <span className="divider">|</span>
                    {defaultAddress ? (
                        <span className="strong">{t('shipping.defaultAddressLabel')}</span>
                    ) : (
                        onSetAsDefault && (
                            <Button
                                className="address-card__action no-min-width p-1"
                                variant="text"
                                type="button"
                                onClick={onSetAsDefault}
                                dataGAFormName="address-card"
                                label={t('shipping.setAsDefault')}
                            />
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddressCard;
