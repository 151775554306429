import { configureStore, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import accountSaga from 'state/account/account.sagas';
import medicineCabinetSaga from 'state/medicine-cabinet/medicine-cabinet.sagas';
import uspsSaga from 'state/usps/usps.sagas';
import addTransferPrescriptionSaga from 'state/add-transfer-prescription/add-transfer-prescription.sagas';
import cartSaga from 'state/cart/cart.sagas';
import drugSaga from 'state/drug/drug.sagas';
import drupalSaga from './drupal/drupal.sagas';
import physicianSaga from './physician/physician.sagas';
import pharmacyReducer from './pharmacy/pharmacy.reducer';
import PharmacySaga from './pharmacy/pharmacy.sagas';
import easyRefillSaga from './easy-refill/easy-refill.sagas';
import autoRefillSaga from './auto-refill/auto-refill.sagas';

// Reducers
import accountReducer from 'state/account/account.reducers';
import birdiModalReducer from 'state/birdi-modal/birdi-modal.reducers';
import medicineCabinetReducer from 'state/medicine-cabinet/medicine-cabinet.reducers';
import cartReducer from 'state/cart/cart.reducers';
import uspsReducer from 'state/usps/usps.reducers';
import drugReducer from 'state/drug/drug.reducers';
import drupalReducer from 'state/drupal/drupal.reducers';
import physicianReducer from './physician/physician.reducer';
import addTransferPrescriptionReducer from 'state/add-transfer-prescription/add-transfer-prescription.reducers';
import easyRefillReducer from './easy-refill/easy-refill.reducer';
import autoRefillReducer from './auto-refill/auto-refill.reducer';
import uploadPhotoReducer from './upload-photo/upload-photo.reducers';

const sagaMiddleware = createSagaMiddleware();

const sagas = [
    accountSaga,
    medicineCabinetSaga,
    uspsSaga,
    addTransferPrescriptionSaga,
    cartSaga,
    drugSaga,
    drupalSaga,
    physicianSaga,
    PharmacySaga,
    easyRefillSaga,
    autoRefillSaga
];
const reducers = combineReducers({
    accountReducer,
    birdiModalReducer,
    medicineCabinetReducer,
    uspsReducer,
    addTransferPrescriptionReducer,
    cartReducer,
    drugReducer,
    drupalReducer,
    physicianReducer,
    pharmacyReducer,
    easyRefillReducer,
    autoRefillReducer,
    uploadPhotoReducer
});

const createStore = () => {
    const store = configureStore({
        reducer: reducers,
        middleware: [sagaMiddleware]
    });
    sagas.map(sagaMiddleware.run);
    return store;
};

export type RootState = ReturnType<typeof reducers>;
export default createStore;
