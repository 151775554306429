import { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

// UI Kit
import { Container, Button as ReactButton } from 'react-bootstrap';
import Button from 'ui-kit/button/button';
import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';

// Components
import Chatbot from 'components/chatbot/chatbot.component';
import ShippingAddress from 'components/new-medicine-cabinet-cart/shipping-address/shipping-address.component';
import ShippingMethod from 'components/new-medicine-cabinet-cart/shipping-method/shipping-method.component';
import PaymentMethod from 'components/new-medicine-cabinet-cart/payment-method/payment-method.component';
import PrescriptionInformation from 'components/new-medicine-cabinet-cart/prescription-information/prescription-information.component';
import EmptyCart from 'components/new-medicine-cabinet-cart/empty-cart/empty-cart.component';
import HealthProfile from 'components/new-medicine-cabinet-cart/health-profile/health-profile.component';

// Cart
import {
    cartItemsTotalSelector,
    cartOrderBillShipMethodSelector,
    cartOrderBillShipSelector,
    cartOrderPaymentCardSelector,
    cartSelector,
    cartSubtotalSelector
} from 'state/cart/cart.selectors';
import {
    accountCreditCardsSelector,
    accountHasInsuranceSelector,
    accountPlansSelector
} from 'state/account/account.selectors';

// Utils
import { v4 as uuidv4 } from 'uuid';
import { EXPEDITED_SHIPPING_COST, EXPEDITED_SHIPPING_ID } from 'util/cart';

import { SidebarColumnProps } from 'components/sidebar-column/sidebar-column.component';
import SidebarCloseIconProps from 'ui-kit/icons/sidebar-close-icon/sidebar-close-icon';

// Hooks
import { useTranslation } from 'react-i18next';
import { medicineCabinetPrescriptionsSelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';
import { NewCartTotal } from 'components/new-medicine-cabinet-cart/new-cart-total/new-total-cart.component';

interface SidebarColumnMobileProps extends SidebarColumnProps {}

const SidebarColumnMobile: FC<SidebarColumnMobileProps> = () => {
    const { t } = useTranslation();

    const ref = useRef<HTMLDivElement>(null);
    const isMounted = useRef<boolean>(true);

    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
    const [isLabelVisible, setIsLabelVisible] = useState(true);
    const [isFloatingMenuVisible, setIsFloatingMenuVisible] = useState(false);
    const [isCartButtonVisible, setIsCartButtonVisible] = useState(true);

    const cartItemsTotal = useSelector(cartItemsTotalSelector);
    const orderBillShip = useSelector(cartOrderBillShipSelector);
    const selectedPaymentMethod = useSelector(cartOrderPaymentCardSelector);
    const shipMethodId = useSelector(cartOrderBillShipMethodSelector);
    const allPaymentData = useSelector(accountCreditCardsSelector);
    const cartObject = useSelector(cartSelector);
    const prescriptionsObject = useSelector(medicineCabinetPrescriptionsSelector);
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const accountPlans = useSelector(accountPlansSelector);
    const estimatedSubtotal = useSelector(cartSubtotalSelector);

    const userId = uuidv4();

    const toggleMobileMenuVisibility = () => {
        document.body.classList.toggle('scroll-lock');
        setIsMobileMenuVisible(!isMobileMenuVisible);
    };

    const handleCartClick = () => {
        if (!isFloatingMenuVisible) {
            setIsFloatingMenuVisible(true);
            setIsCartButtonVisible(false);
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (isMounted.current && ref.current && !ref.current.contains(event.target as Node)) {
            setIsFloatingMenuVisible(false);
            setIsCartButtonVisible(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY === 0) {
                setIsLabelVisible(true);
            } else {
                setIsLabelVisible(false);
            }
        });
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <>
            {cartItemsTotal === 0 ? (
                <Chatbot uniqueID={userId} />
            ) : (
                <>
                    {isCartButtonVisible && (
                        <Button
                            itemCount={cartItemsTotal}
                            cartIcon={true}
                            separator={true}
                            type="button"
                            label={
                                isLabelVisible ? t('components.medicineCabinetCart.responsiveCart.cartButtonLabel') : ''
                            }
                            aria-label={'Cart Button'}
                            onClick={handleCartClick}
                            className={`d-lg-none sidebar-column__drawer-expanded-btn btn border-0 p-3 bg-cathams-blue no-min-width btn-primary d-flex align-items-center justify-content-between`}
                            style={isLabelVisible ? { width: '177px' } : ({ width: '112px' } as CSSProperties)}
                            chevron="up"
                        />
                    )}
                    <div className={isFloatingMenuVisible ? 'blur-background' : ''}>
                        <Container
                            ref={ref}
                            fluid
                            className={classNames(
                                'sidebar-column__drawer d-lg-none justify-content-center',
                                `sidebar-column__drawer--${isMobileMenuVisible ? 'open' : 'closed'}`
                            )}
                        >
                            {isFloatingMenuVisible && (
                                <>
                                    <p className="sidebar-column__drawer-chat-text">
                                        {t('components.medicineCabinetCart.responsiveCart.chatButtonLabel')}
                                    </p>
                                    <Chatbot uniqueID={userId} isInCart />
                                    <p className="sidebar-column__drawer-cart-text">
                                        {t('components.medicineCabinetCart.responsiveCart.cartButtonLabel')}
                                    </p>
                                    <Button
                                        label=""
                                        itemCount={cartItemsTotal!}
                                        cartIcon={true}
                                        type="button"
                                        aria-label={'Cart Button'}
                                        className={`sidebar-column__drawer-toggle btn border-0 p-3 bg-cathams-blue no-min-width btn-primary`}
                                        onClick={toggleMobileMenuVisibility}
                                    />
                                </>
                            )}

                            <div className="sidebar-column__drawer-content">
                                <div className="sidebar-column__drawer-close">
                                    <ReactButton
                                        className="thumbsup bg-transparent p-0 border-0 no-min-width"
                                        onClick={toggleMobileMenuVisibility}
                                    >
                                        <SidebarCloseIconProps style={{ height: '1rem', width: '1rem' }} />
                                    </ReactButton>
                                </div>
                                <div className="new-medicine-cabinet-cart__header">
                                    <CartIcon className={'header-icon-container'} />
                                    <h3>{t('components.medicineCabinetCart.title')}</h3>
                                </div>

                                {cartItemsTotal! > 0 ? (
                                    <>
                                        <ShippingAddress />
                                        <ShippingMethod />
                                        <PaymentMethod
                                            addNewPaymentButtonLabel={t(
                                                'components.medicineCabinetCart.paymentMethod.addPaymentMethod'
                                            )}
                                            buttonVariant="text-blue"
                                            selectedPaymentMethod={selectedPaymentMethod}
                                            paymentData={allPaymentData || []}
                                            showSelectCardRadioInput
                                            showSetDefaultLink
                                            orderBillShip={orderBillShip!}
                                        />
                                        <HealthProfile />
                                        <PrescriptionInformation
                                            cartObject={cartObject}
                                            isInsuranceAccount={accountHasInsurance}
                                            prescriptions={prescriptionsObject}
                                            accountPlans={accountPlans}
                                        />
                                        <NewCartTotal
                                            cartObject={cartObject}
                                            prescriptionsObject={prescriptionsObject}
                                            isUnknownPrice={false}
                                            initialOrderPrice={estimatedSubtotal}
                                            currentShippingPrice={
                                                shipMethodId === EXPEDITED_SHIPPING_ID ? EXPEDITED_SHIPPING_COST : 0
                                            }
                                            isInsuranceAccount={false}
                                            // DRX-1660: As the new Cart has two new functionallities, i decide to create a new component
                                            // to mantain the compability
                                        />
                                    </>
                                ) : (
                                    <EmptyCart
                                        title={t('components.medicineCabinetCart.empty.title')}
                                        body={t('components.medicineCabinetCart.empty.body')}
                                    />
                                )}
                            </div>
                        </Container>
                    </div>
                </>
            )}
        </>
    );
};
export default SidebarColumnMobile;
