import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// UI Kit & Components
import { Container } from 'react-bootstrap';
import ColumnSectionEditModeToggle from 'components/sidebar-column/column-section-toggle/column-section-toggle.component';
import PrescriptionItem, { PrescriptionItemProps } from './prescription-item/prescription-item.component';

// Modal
import { FailureUpdateCartModalContent } from 'pages/secure/cart/intra-page-items/_cart-update-modal-item';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

// Account, Drugs, Profile, Prescriptions & Cart
import { CartObjectPayload, ExtendedCartObjectPayload } from 'state/cart/cart.services';
import { PrescriptionObjectPayload } from 'state/medicine-cabinet/medicine-cabinet.services';
import { PlansObjectPayload } from 'state/account/account.services';
import { accountFetchPlansRoutine, accountFetchProfileRoutine } from 'state/account/account.routines';
import { ExtendedRefillRxs } from 'types/order-prescription';
import { cancelOrderLine, getCartRoutine } from 'state/cart/cart.routines';
import { accountProfileSelector } from 'state/account/account.selectors';
import { drugDiscountPriceRoutine } from 'state/drug/drug.routines';
import { drugSelector } from 'state/drug/drug.selectors';
import { medicineCabinetGetAllPrescriptions } from 'state/medicine-cabinet/medicine-cabinet.routines';

//Utils
import { ALLOW_INSURED_BIRDI_PRICE } from 'gatsby-env-variables';
import { EXPEDITED_SHIPPING_COST, processCart } from 'util/cart';
import { noop } from 'util/function';
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';

import './prescription-information.style.scss';

export interface PrescriptionInformationProps {
    prescriptions: PrescriptionObjectPayload[];
    isInsuranceAccount: boolean;
    cartObject?: CartObjectPayload;
    accountPlans?: PlansObjectPayload[];
    onPrescriptionRemoved?: (removedCard: ExtendedRefillRxs) => void;
    isToggleDisabled?: boolean;
}

const PrescriptionInformation: React.FC<PrescriptionInformationProps> = (props) => {
    const {
        onPrescriptionRemoved = noop,
        prescriptions,
        isInsuranceAccount,
        isToggleDisabled = true,
        cartObject,
        accountPlans
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [extendedCartObject, setExtendedCartObject] = useState<ExtendedCartObjectPayload>();

    //Profile Selector
    const profileObject = useSelector(accountProfileSelector);

    const { drugDiscountPrices } = useSelector(drugSelector);

    const handlePrescriptionsError = React.useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.shippingOption')} />,
                ctas: [
                    {
                        label: t('modals.updateCart.labels.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'UpdateCartError'
                    }
                ]
            })
        );
    }, [dispatch, t]);

    const handleRemovePrescription = React.useCallback(
        (prescriptionToRemove: ExtendedRefillRxs): PrescriptionItemProps['onRemovePrescriptionClick'] =>
            () => {
                if (!prescriptionToRemove) {
                    return;
                }

                dispatch(
                    cancelOrderLine.trigger({
                        rxNumber: prescriptionToRemove.rxNumber,
                        onSuccess: () => {
                            TrackCheckoutStep({
                                stepName: 'remove',
                                step: '1',
                                cart: extendedCartObject,
                                prescriptions: prescriptions,
                                t: t,
                                shippingCost: extendedCartObject?.orderHeader?.orderHighPriority
                                    ? `${EXPEDITED_SHIPPING_COST}`
                                    : '0',
                                accountHasInsurance: isInsuranceAccount
                            });
                            onPrescriptionRemoved(prescriptionToRemove);
                            dispatch(accountFetchPlansRoutine());
                            dispatch(medicineCabinetGetAllPrescriptions({ showNewRxModal: true }));
                        },
                        onFailure: () => {
                            handlePrescriptionsError();
                        }
                    })
                );
            },
        [
            isInsuranceAccount,
            dispatch,
            extendedCartObject,
            handlePrescriptionsError,
            onPrescriptionRemoved,
            prescriptions,
            t
        ]
    );

    useEffect(() => {
        async function getCartOrder() {
            if (cartObject) {
                const extendedCart = processCart(
                    cartObject,
                    isInsuranceAccount,
                    prescriptions,
                    drugDiscountPrices,
                    accountPlans
                );
                setExtendedCartObject(extendedCart);
            }
        }
        getCartOrder();
    }, [cartObject, isInsuranceAccount, drugDiscountPrices, accountPlans, prescriptions]);

    useEffect(() => {
        dispatch(getCartRoutine.trigger());
        dispatch(accountFetchProfileRoutine.trigger());
        dispatch(accountFetchPlansRoutine.trigger());
    }, [dispatch]);

    // Get discount drug prices after the profile and all prescriptions are loaded.
    useEffect(() => {
        if (profileObject !== undefined && prescriptions?.length > 0) {
            let birdiPricePrescriptions: PrescriptionObjectPayload[] = [];
            // DRX-583 - get prices for Insured users to pre-fetch for cart. We only need to get
            // prices for items in cart.

            if (isInsuranceAccount && ALLOW_INSURED_BIRDI_PRICE) {
                // DRX-996 - Since we have to show a different message when an item is added to the cart,
                // we need to get all Rxs that are in the cart, or could be added to the cart.
                birdiPricePrescriptions = prescriptions.filter((rx) => {
                    return (
                        rx.inOrderCart === true ||
                        rx.webEligibilityStatus === 'ELIGIBLE' ||
                        rx.webEligibilityStatus === 'AUTH_REQ'
                    );
                });
            } else {
                // No need to get "pending" prescriptions for Birdi price users
                if (!isInsuranceAccount) {
                    birdiPricePrescriptions = prescriptions.filter(function (rx) {
                        return !!(rx.dispensedProductNumber || rx.writtenProductNumber);
                    });
                }
            }

            dispatch(
                drugDiscountPriceRoutine.trigger({
                    prescriptions: birdiPricePrescriptions,
                    forceBirdiInsurance: !!(isInsuranceAccount && ALLOW_INSURED_BIRDI_PRICE),
                    location: 'MedCabinet'
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, profileObject?.epostPatientNum, prescriptions?.length]);

    return (
        <>
            <ColumnSectionEditModeToggle
                bodyClassName="prescription-information__content"
                className="prescription-information"
                editModeContent={<></>}
                headerClassName="prescription-information__header"
                title="Prescription information"
                isToggleDisabled={isToggleDisabled}
            >
                <>
                    <Container className="cart-review">
                        <div className="cart-review--border-bottom">
                            {extendedCartObject?.extendedRefillRxs?.map((item: ExtendedRefillRxs) => {
                                const discountPrice = drugDiscountPrices.find((dc) => dc.rxNumber === item.rxNumber);
                                const currentPrescription = prescriptions.find((obj: PrescriptionObjectPayload) => {
                                    return obj.rxNumber === item.rxNumber;
                                });
                                return (
                                    <PrescriptionItem
                                        isLoading={false}
                                        key={item.rxNumber}
                                        onRemovePrescriptionClick={handleRemovePrescription(item)}
                                        prescription={item}
                                        currentPrescription={currentPrescription}
                                        discountPrice={discountPrice}
                                        isInsuranceAccount={isInsuranceAccount}
                                    />
                                );
                            })}
                        </div>
                    </Container>
                </>
            </ColumnSectionEditModeToggle>
        </>
    );
};

export default PrescriptionInformation;
