import axiosClient from 'util/axiosClient';
import { v4 as uuidv4 } from 'uuid';
import { ROBIN_IMAGE_URL, GATSBY_API_BASE_URL } from 'gatsby-env-variables';

export interface PrescriberPayload {
    PrescriberName: string;
    PrescriberAddress: string;
    PrescriberCity: string;
    PrescriberState: string;
    PrescriberZip: string;
    PrescriberPhone: string;
    PrescriberFax: string;
}

export interface PharmacyPayload {
    PharmacyName: string;
    PharmacyAddress: string;
    PharmacyCity: string;
    PharmacyState: string;
    PharmacyZip: string;
    PharmacyPhone: string;
}

export interface AddPrescriptionPayload extends PrescriberPayload {
    RequestType: string;
    DrugName: string;
    DrugDisplayName?: string;
    DrugForm: string;
    Strength: string;
    StrengthWithPackage?: string;
    Qty: string;
}

export interface TransferPrescriptionPayload extends PharmacyPayload, AddPrescriptionPayload {
    RxNumber: string;
    ImageExtractedData?: string;
    onSucces?: Function;
    onFailure?: Function;
}

export interface CancelPrescriptionPayload {
    rxNumber: string;
    onSuccess?: Function;
    onFailure?: Function;
}

/**
 * Anything related to Medicine Cabinet in the system.
 */
export default class AddTransferPrescriptionService {
    static transferRx() {
        return {
            post: (payload: any): Promise<any> => {
                return axiosClient
                    .post(
                        `${GATSBY_API_BASE_URL}/api/prescriptions/patient/${payload.patientId}/transfer`,
                        payload.request
                    )
                    .then((response) => response.data);
            }
        };
    }
    static cancelRx() {
        return {
            post: (rxNumber: string): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/Prescriptions/Discontinue/${rxNumber}`)
                    .then((response) => {
                        return response.data;
                    });
            }
        };
    }
    static extractPrescriberData(transferObject: TransferPrescriptionPayload): PrescriberPayload {
        const prescriberData = (({
            PrescriberName,
            PrescriberAddress,
            PrescriberCity,
            PrescriberState,
            PrescriberZip,
            PrescriberPhone,
            PrescriberFax
        }) => ({
            PrescriberName,
            PrescriberAddress,
            PrescriberCity,
            PrescriberState,
            PrescriberZip,
            PrescriberPhone,
            PrescriberFax
        }))(transferObject);
        return prescriberData;
    }

    static extractPharmacyData(transferObject: TransferPrescriptionPayload): PharmacyPayload {
        const pharmacyData = (({
            PharmacyName,
            PharmacyAddress,
            PharmacyCity,
            PharmacyState,
            PharmacyZip,
            PharmacyPhone
        }) => ({
            PharmacyName,
            PharmacyAddress,
            PharmacyCity,
            PharmacyState,
            PharmacyZip,
            PharmacyPhone
        }))(transferObject);
        return pharmacyData;
    }

    static dataURItoBlob(dataURI: string): Blob {
        // Borrowed from https://stackoverflow.com/questions/12168909/blob-from-dataurl
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([ab], { type: mimeString });
        return blob;
    }

    static largePhotoUpload(payload?: any): Promise<any> {
        const myuuid = uuidv4();
        const largeEndpoint = `robin-presignedurlpython?key=${myuuid}`;
        return axiosClient
            .get(`${ROBIN_IMAGE_URL}/${largeEndpoint}`, { headers: null })
            .then((response) => {
                const postURL = response.data?.body;
                const img = AddTransferPrescriptionService.dataURItoBlob(payload);
                if (postURL) {
                    return axiosClient.put(`${postURL}`, img, { headers: null }).then(() => {
                        return { data: { body: myuuid } };
                    });
                } else {
                    return null;
                }
            })
            .catch((error) => {
                return error;
            });
    }

    static photoUpload() {
        return {
            post: (payload?: any): Promise<any> => {
                if (payload.length >= 9000000) {
                    return AddTransferPrescriptionService.largePhotoUpload(payload);
                } else {
                    return axiosClient
                        .post(`${ROBIN_IMAGE_URL}/${'robin-uploadfile'}`, payload.split('base64,')[1])
                        .then((response) => {
                            return response;
                        })
                        .catch((error) => {
                            if (error.response.data?.message === 'Request Too Long') {
                                return AddTransferPrescriptionService.largePhotoUpload(payload);
                            } else {
                                return error;
                            }
                        });
                }
            }
        };
    }

    // WORKING - BUT NO DELAY BETWEEN FETCH ATTEMPTS
    // When a delay was attempted the ".catch((error)" on line 85 was triggered
    static getFileFetch() {
        const fetchRetry = (url: string, options = {}, retries: number): Promise<void | Response> =>
            fetch(url, options)
                .then((res) => {
                    if (res.ok) {
                        return res;
                    }
                    if (retries > 0) {
                        return fetchRetry(url, options, retries - 1);
                    }
                    throw new Error(res.status.toString());
                })
                .catch((error) => console.error(error.message));

        return {
            get: (payload?: any) => {
                return fetchRetry(
                    `${ROBIN_IMAGE_URL}/robin-read-file?key=${payload}`,
                    {
                        method: 'GET',
                        redirect: 'follow'
                    },
                    10
                )
                    .then((response) => response.json())
                    .then((myJson) => myJson)
                    .catch((error) => console.error('error', error));
            }
        };
    }
}
