import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import { MenuProps, NavPath, HeaderLogo } from 'components/navigation/navigation.props';

import PillLogo from 'ui-kit/logo/pill-logo/pill-logo';
import Logo from 'ui-kit/logo/logo';
import EvioLogo from 'ui-kit/logo/evio-logo/evio-logo';
import Link from 'ui-kit/link/link';
import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';

import './desktop-menu.style.scss';

import {
    IntraPageMenuItemProps,
    IntraPageMenuItemContentValue
} from 'components/navigation/intra-page-menu/intra-page-menu.props';
import useMediaQuery from 'hooks/useMediaQuery';
import { useGlobalLink } from 'hooks/useGlobalLink';
import useSiteClient from 'hooks/useSiteClient';

const DesktopMenu = ({ isScrolled, paths, userName, profileMenu, isLoggedIn, cartItemsTotal }: MenuProps) => {
    const [navPaths, setNavPaths] = useState<NavPath[] | undefined>();
    const [cartTotal, setCartTotal] = useState(cartItemsTotal);
    const longMenuTest = useMediaQuery('(max-width: 1128px)'); // DRX-1254: Use pill logo to prevent wrapping menus (need to allow for co-branding logo)
    const { clientId } = useSiteClient();
    const { t } = useTranslation();

    useEffect(() => {
        setNavPaths(paths);
    }, [paths, isLoggedIn]);

    useEffect(() => {
        setCartTotal(cartItemsTotal);
    }, [cartItemsTotal]);

    const globalLink = useGlobalLink();
    const { trackFormAbandonment, handleSignOut } = globalLink;

    const headerLogo: HeaderLogo = {
        birdi: { default: <Logo variant="primary" />, isScrolled: <PillLogo variant="primary" /> },
        evio: { default: <EvioLogo variant="primary" />, isScrolled: <EvioLogo variant="light" /> }
    };

    return (
        <Container fluid="xl" className="desktop-menu d-none d-lg-flex">
            <Navbar.Brand>
                <Link
                    onClick={trackFormAbandonment}
                    to={isLoggedIn ? '/secure/medicine-cabinet' : '/'}
                    label={
                        <>
                            <div className="co-brand-logo d-flex"></div>
                            {isScrolled || (navPaths && navPaths?.length > 5 && longMenuTest)
                                ? headerLogo[clientId].isScrolled
                                : headerLogo[clientId].default}
                        </>
                    }
                    variant="site"
                    dataGALocation="HeaderLogo"
                    ariaLabel="Birdi Homepage"
                />
            </Navbar.Brand>
            <Navbar.Collapse id="site-navbar-paths" className="justify-content-end">
                <Nav>
                    {navPaths &&
                        navPaths.map(({ link, label, isButton }: NavPath) => (
                            <Link
                                onClick={trackFormAbandonment}
                                key={`site-nav-${link.url}`}
                                to={link.url}
                                label={label}
                                variant={Boolean(isButton) === true ? 'nav-button' : 'nav'}
                                dataGALocation="HeaderNav"
                            />
                        ))}
                    {profileMenu && (
                        <>
                            <div className="menu-links-profile">
                                <DropdownButton id="dropdown-basic-button" title={userName}>
                                    {profileMenu.map(
                                        ({
                                            key,
                                            value,
                                            label,
                                            isChild
                                        }: IntraPageMenuItemProps<IntraPageMenuItemContentValue>) => (
                                            <React.Fragment key={`profile-item-${key}`}>
                                                {value && (
                                                    <Dropdown.Item
                                                        as={Link}
                                                        to={value}
                                                        label={label}
                                                        data-tagtype="anchorlink"
                                                        dataGALocation="HeaderNav"
                                                        className={isChild ? 'dropdown-item-child' : ''}
                                                        onClick={trackFormAbandonment}
                                                    />
                                                )}
                                                {!value && <Dropdown.ItemText>{label}</Dropdown.ItemText>}
                                            </React.Fragment>
                                        )
                                    )}
                                    <Dropdown.Item
                                        className="menu-links-profile__logout"
                                        key={'profile-item-logout'}
                                        onClick={handleSignOut}
                                        data-tagtype="anchorlink"
                                        data-ga-location="HeaderNav"
                                    >
                                        {t('menus.intraPage.profile.signOut')}
                                    </Dropdown.Item>
                                </DropdownButton>
                            </div>
                            <Link
                                to={'/secure/cart/review/'}
                                label={<CartIcon itemCount={cartTotal} className={'border-white'} />}
                                variant={'nav'}
                                className={'no-active-state'}
                                dataGALocation="HeaderNav"
                            />
                        </>
                    )}
                </Nav>
            </Navbar.Collapse>
        </Container>
    );
};

export default DesktopMenu;
