import React, { ReactElement } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { useDispatch } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import { paragraphToComponent } from 'providers/paragraphs/paragraphs';
import './discount-drag-modal.style.scss';
import DrugFormularyTable from 'components/drug-formulary-table/drug-formulary-table.component';
interface DiscountDrugModalProps {
    accountHasInsurance: boolean;
}

const DiscountDrugModal = ({ accountHasInsurance }: DiscountDrugModalProps): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pageData = useStaticQuery(graphql`
        query {
            birdiPriceDrugPage: allNodeLandingPage(
                filter: { path: { alias: { eq: "/drugs-available-birdi-price" } } }
            ) {
                nodes {
                    title
                    id
                    path {
                        langcode
                        alias
                    }
                    relationships {
                        field_landing_page_content {
                            ...supportedParagraphs
                        }
                    }
                }
            }
            formulary: allFormularyDrug(filter: { acCode: { eq: "BRD01" } }) {
                nodes {
                    drugName: onSaleDrugName
                    onSaleDrugName
                    brandDrugName
                    brandNameCode
                    genericProductCode
                    dosageForms {
                        dosageForm
                        strengths {
                            fullStrength
                            gpi
                            strength
                            strengthUnit
                        }
                    }
                }
            }
        }
    `);
    const handleLinkClick = () => {
        let modalBody;
        if (pageData.birdiPriceDrugPage.nodes.length === 1) {
            modalBody = pageData.birdiPriceDrugPage.nodes[0]?.relationships?.field_landing_page_content.map(
                (paragraph: any, index: number) => (
                    <React.Fragment key={`discountdrugs_${index}`}>
                        {paragraphToComponent(paragraph?.internal.type, paragraph, index + 10)}
                    </React.Fragment>
                )
            );
        } else {
            modalBody = (
                <>
                    <h1 className="h2">
                        {t('pages.drugsList.title', {
                            formularyTitle: t('pages.drugsList.BRD01')
                        })}
                    </h1>
                    <DrugFormularyTable formulary={pageData.formulary?.nodes} />
                </>
            );
        }

        dispatch(
            openModal({
                showClose: true,
                className: 'scroll-modal',
                bodyContent: <BirdiModalContent icon={'none'} body={modalBody} />,
                ctas: [
                    {
                        label: t('modals.default.submit'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'DiscountDrug'
                    }
                ]
            })
        );
    };
    return !accountHasInsurance ? (
        <div className="mt-2">
            <span
                role="button"
                tabIndex={0}
                className={'discount-link'}
                onClick={() => handleLinkClick()}
                onKeyPress={() => handleLinkClick()}
            >
                {t('prescriptionInfoForm.messages.discountLink')}
            </span>
            <span>{t('prescriptionInfoForm.messages.discountMessage')}</span>
        </div>
    ) : (
        <></>
    );
};
export default DiscountDrugModal;
