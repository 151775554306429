import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { accountProfileAddressesSelector, accountCreditCardsSelector } from '../account/account.selectors';

export const CartSelector = (state: RootState) => state.cartReducer;

export const cartSelector = createSelector(CartSelector, (cart) => cart.cart);
export const cartItemsSelector = createSelector(CartSelector, (state) => state.cart?.refillRxs);
export const cartItemsTotalSelector = createSelector(CartSelector, (state) => state.cart?.refillRxs?.length);
export const cartSubtotalSelector = createSelector(CartSelector, (state) => state.cart?.orderTotal);
export const cartOrderBillShipSelector = createSelector(CartSelector, (state) => state.cart?.orderBillShip);
export const cartOrderBillShipMethodSelector = createSelector(
    CartSelector,
    (state) => state.cart?.orderBillShip?.shipMethodId
);

export const cartIsBusySelector = createSelector(CartSelector, (cart) => cart.isBusy || false);

export const cartOrderShippingAddressSelector = createSelector(
    [accountProfileAddressesSelector, cartSelector],
    (addresses, cart) => {
        return addresses.find((address) => address.addressSeqNum === cart?.orderBillShip.patientShipAddressSeq);
    }
);

export const cartOrderBillingAddressSelector = createSelector(
    [accountProfileAddressesSelector, cartSelector],
    (addresses, cart) => {
        return addresses.find((address) => address.addressSeqNum === cart?.orderBillShip.patientBillAddressSeq);
    }
);

export const cartOrderPaymentCardSelector = createSelector(
    [accountCreditCardsSelector, cartSelector],
    (cards, cart) => {
        return cards?.find((card) => card.cardSeqNum === cart?.orderBillShip.paymentCardSeqNum);
    }
);

export const cartOrderHeaderSelector = createSelector(cartSelector, (cart) => cart && cart.orderHeader);
export const cartOrderPlacedSelector = createSelector(CartSelector, (cart) => cart.orderPlaced);
