import React from 'react';

import { LogoProps } from 'ui-kit/logo/logo.props';

import 'ui-kit/icons/social-media-icon/social-media-icon.style.scss';

const QuoteIcon = ({ variant }: LogoProps) => {
    const getVariantStyle = (variant: string | undefined) => {
        switch (variant) {
            case 'light': {
                return { fill: '#FFFFFF', background: '#039fd3', height: '25', width: '11.842' };
            }
            case 'primary': {
                return { fill: '#039fd3', background: 'rgb(34,42,63)', height: '25', width: '11.842' };
            }
            default: {
                return { fill: '#039fd3', background: 'rgb(34,42,63)', height: '100%', width: '100%' };
            }
        }
    };

    const variantStyle = getVariantStyle(variant);

    return (
        <svg
            className="quote-icon"
            xmlns="http://www.w3.org/2000/svg"
            width={variantStyle.width}
            height={variantStyle.height}
            viewBox="0 0 95 95"
        >
            <g>
                <path
                    fill={variantStyle.background}
                    fillRule="evenodd"
                    d="M47.5 95C73.7335201 95 95 73.73452345 95 47.49371007 95 21.26547655 73.7335201 0 47.5 0S0 21.26547655 0 47.49371007C0 73.73452345 21.2664799 95 47.5 95"
                />
                <path
                    fill="none"
                    stroke={variantStyle.fill}
                    strokeLinecap="butt"
                    strokeLinejoin="round"
                    strokeWidth="5"
                    d="M43.96844482 62.8671875c-2.06903076 2.02246094-4.50897216 3.0336914-7.31976318 3.0336914-3.27929687 0-6.07061768-1.18310546-8.37390137-3.54882812-2.30328369-2.3659668-3.45495605-5.53320312-3.45495605-9.50170898 0-2.97631836.52703857-5.85742188 1.58105469-8.6430664 1.05407714-2.78564454 2.57659912-5.3803711 4.56756591-7.78442384 1.99102784-2.40405273 4.31384278-4.5600586 6.96850586-6.46801758l7.37835694 5.83837891c-1.95196533.91577149-3.92340088 2.46118164-5.91442871 4.63623047-1.9909668 2.17504883-3.2597046 4.25488281-3.80627442 6.23901367.23425293-.07617187.5855713-.15258789 1.05407715-.22875976.2341919-.07641602.48797607-.11450196.76123047-.11450196h.7612915c2.57653809 0 4.7041626.85839844 6.38287354 2.5756836 1.6786499 1.71704101 2.51800537 3.94946289 2.51800537 6.69702148q0 4.2355957-3.1036377 7.26928711zm22.2522583 0c-2.06903076 2.02246094-4.50897216 3.0336914-7.31976318 3.0336914-3.27929687 0-6.07061767-1.18310546-8.37390137-3.54882812-2.30328369-2.3659668-3.45495605-5.53320312-3.45495605-9.50170898 0-2.97631836.54656982-5.85742188 1.63964844-8.6430664 1.09307861-2.78564454 2.61560058-5.3803711 4.56756592-7.78442384q2.92797851-3.60595703 7.02703857-6.46801758l7.26123047 5.83837891c-1.95196533.91577149-3.92340088 2.46118164-5.91442871 4.63623047-1.9909668 2.17504883-3.2597046 4.25488281-3.80627442 6.23901367.23425293-.07617187.6246338-.15258789 1.17114258-.22875976.15618897-.07641602.37091065-.11450196.64416504-.11450196h.7612915c2.57653809 0 4.7041626.85839844 6.38287354 2.5756836 1.6786499 1.71704101 2.51800537 3.94946289 2.51800537 6.69702148q0 4.2355957-3.1036377 7.26928711z"
                />
            </g>
        </svg>
    );
};

export default QuoteIcon;
