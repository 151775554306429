import React from 'react';

import { withPrefix } from 'gatsby';
import { BackgroundImageProps } from 'ui-kit/background-image/background-image.props';

import './background-image.style.scss';

const BackgroundImage = ({ image, children, style, useRoundedCorners, className }: BackgroundImageProps) => {
    return (
        <div className={`backgroundImage ${className} ${useRoundedCorners ? 'rounded-corners' : ''} `}>
            <div
                className={`bkgImage`}
                style={{
                    backgroundImage: `url(${withPrefix(image.childImageSharp.gatsbyImageData.images.fallback.src)})`,
                    ...(style ?? {})
                }}
            />
            <div
                style={{
                    position: 'relative'
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default BackgroundImage;
