import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';

// Utils
import { formatPrice } from 'schema/price.schema';
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';

// UI Kit & Components
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'ui-kit/button/button';

// Cart, Account & plans
import { cartCompleteOrderRoutine, getCartRoutine } from 'state/cart/cart.routines';
import { EXPEDITED_SHIPPING_COST, processCart } from 'util/cart';
import { CartObjectPayload, ExtendedCartObjectPayload } from 'state/cart/cart.services';
import { PrescriptionObjectPayload } from 'state/medicine-cabinet/medicine-cabinet.services';
import { drugSelector } from 'state/drug/drug.selectors';
import { accountPlansSelector } from 'state/account/account.selectors';
import { cartOrderPaymentCardSelector } from 'state/cart/cart.selectors';
import { useHealthConditions } from 'hooks/useHealthConditions';

import './new-cart-total.style.scss';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

interface ReviewNewCartTotalProps {
    isUnknownPrice: boolean;
    initialOrderPrice?: string;
    currentShippingPrice: number;
    isInsuranceAccount: boolean;
    cartObject?: CartObjectPayload;
    prescriptionsObject: PrescriptionObjectPayload[];
}

export const NewCartTotal = ({
    isUnknownPrice,
    initialOrderPrice,
    currentShippingPrice,
    isInsuranceAccount,
    cartObject,
    prescriptionsObject
}: ReviewNewCartTotalProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [extendedCartObject, setExtendedCartObject] = useState<ExtendedCartObjectPayload>();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const { userHasNotSubmittedAllergies, userHasNotSubmittedConditions } = useHealthConditions();

    const { drugDiscountPrices } = useSelector(drugSelector);
    const accountPlans = useSelector(accountPlansSelector);
    const selectedPaymentMethod = useSelector(cartOrderPaymentCardSelector);

    const isOneClickOrderAvailable =
        !userHasNotSubmittedConditions && !userHasNotSubmittedAllergies && !!selectedPaymentMethod;

    const handleSubmitOrderClick = () => {
        setIsBusy(true);
        dispatch(
            cartCompleteOrderRoutine({
                orderTotal: String(Number(initialOrderPrice) + Number(currentShippingPrice)),
                lineItems: extendedCartObject?.extendedRefillRxs,
                onSuccess: () => {
                    setIsBusy(false);
                    TrackCheckoutStep({
                        stepName: 'purchase',
                        step: '1',
                        cart: extendedCartObject,
                        prescriptions: prescriptionsObject,
                        t: t,
                        shippingCost: extendedCartObject?.orderHeader?.orderHighPriority
                            ? `${EXPEDITED_SHIPPING_COST}`
                            : '0',
                        accountHasInsurance: isInsuranceAccount
                    });
                    navigate('/secure/cart/order-confirmation');
                },
                onFailure: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            bodyContent: (
                                <BirdiModalContent
                                    icon={'alert'}
                                    title={t(`pages.cart.error.errorTitle`)}
                                    body={t(`pages.cart.error.errorMessage`)}
                                />
                            ),
                            ctas: [
                                {
                                    label: t(`pages.cart.error.cta`),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                    dispatch(getCartRoutine.trigger());
                    setIsBusy(false);
                }
            })
        );
    };

    useEffect(() => {
        if (cartObject) {
            const extendedCart = processCart(
                cartObject,
                isInsuranceAccount,
                prescriptionsObject,
                drugDiscountPrices,
                accountPlans
            );
            setExtendedCartObject(extendedCart);
        }
    }, [cartObject, isInsuranceAccount, prescriptionsObject, drugDiscountPrices, accountPlans]);

    return (
        <Container fluid className="new-cart-total d-flex flex-column mt-4 mb-4">
            <Row>
                <Col sm={12} lg={12}>
                    <div className="cart-total d-flex justify-content-between">
                        <div>
                            {t('pages.cart.subTotal')}
                            {isUnknownPrice ? <sup>*</sup> : ''}:
                        </div>
                        <div>{formatPrice(initialOrderPrice)}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="cart-total--shipping d-flex justify-content-between mt-3">
                        <div>{t('pages.cart.shipping')}</div>
                        <div>{formatPrice(currentShippingPrice)}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="cart-total--total d-flex justify-content-between my-3 pt-4 border-top border-geyser">
                        <div className="h6 cart-total--title">
                            {t('components.medicineCabinetCart.newCartTotal.total')}
                            {isUnknownPrice ? <sup>*</sup> : ''}:
                        </div>
                        <div className="h6">
                            {formatPrice(String(Number(initialOrderPrice) + Number(currentShippingPrice)))}
                        </div>
                    </div>
                </Col>
            </Row>
            {isUnknownPrice && isInsuranceAccount && (
                <Row className="mt-4">
                    <Col>
                        <small>
                            <sup>*</sup> {t('pages.cart.totalDisclaimer')}
                        </small>
                    </Col>
                </Row>
            )}
            <Row>
                <Col className="d-flex flex-column new-cart-total__container">
                    <div className="new-cart-total__note">
                        {t('components.medicineCabinetCart.newCartTotal.disclaimer')}
                    </div>

                    <div className="new-cart-total__note">{t('components.medicineCabinetCart.newCartTotal.note')}</div>

                    <Button
                        async
                        customFontSize="18"
                        isBusy={isBusy}
                        disabled={!isOneClickOrderAvailable || isBusy}
                        type="button"
                        className="btn btn-primary no-min-width"
                        label={t('components.medicineCabinetCart.newCartTotal.submit')}
                        onClick={handleSubmitOrderClick}
                    />
                    <Button
                        customFontSize="18"
                        type="button"
                        variant="text"
                        className="btn btn-text text-cerulean no-min-width"
                        label={t('components.medicineCabinetCart.newCartTotal.review')}
                        onClick={() => navigate('/secure/cart/review')}
                    />
                </Col>
            </Row>
            <br />
        </Container>
    );
};
