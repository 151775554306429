import React from 'react';

const Facebook = ({ label }: { label: string }) => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>{label}</title>
            <path
                d="M40.9473 20.2646C40.9473 9.21965 31.9923 0.264648 20.9473 0.264648C9.90227 0.264648 0.947266 9.21965 0.947266 20.2646C0.947266 30.248 8.2606 38.5213 17.8223 40.0213V26.0463H12.7439V20.263H17.8223V15.8596C17.8223 10.848 20.8089 8.07798 25.3773 8.07798C27.5639 8.07798 29.8539 8.46965 29.8539 8.46965V13.3913H27.3306C24.8456 13.3913 24.0706 14.933 24.0706 16.5146V20.2646H29.6173L28.7306 26.048H24.0706V40.023C33.6339 38.5213 40.9473 30.2463 40.9473 20.2646Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Facebook;
