import React from 'react';

import { ChevronProps } from 'ui-kit/icons/chevron-icon/chevron-icon.props';

import './chevron-icon.style.scss';

const ChevronIcon = ({ direction, style }: ChevronProps) => {
    return (
        <div className={`chevron-icon ${direction}`} style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 8.091 13.277">
                <g>
                    <path
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M5.53050456 12.83790672c.58578649.58578524 1.53553358.58578577 2.12132049.00000119.54917509-.54917877.58349903-1.41823545.10297148-2.00740046l-.1029703-.11392217L3.57386685 6.6386182l4.07796398-4.07795486.10297042-.11392206C8.23532945 1.85757682 8.2010065.9885201 7.65183201.43934071c-.58578625-.58578524-1.53553334-.58578577-2.12132049-.0000012L.43934026 5.53050793l-.10297019.11392206c-.23480813.28788745-.3466829.64260845-.3356243.99418622-.011059.35157775.10081538.70630651.33562319.99419422l.10297006.11392217 5.09116554 5.09117413z"
                    />
                </g>
            </svg>
        </div>
    );
};

export default ChevronIcon;
