/**
 * parseFloat helper that always returns a number regardless of input. Will
 * not return NaN values like the standard parseFloat
 */
export function safeParseFloat(valueString?: string): number {
    if (!valueString) {
        return 0;
    }

    const result = parseFloat(valueString);
    return isNaN(result) ? 0 : result;
}
