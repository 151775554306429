import React from 'react';

const LinkedIn = ({ label }: { label: string }) => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 42 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>{label}</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.2329 0.142578C32.5201 0.142578 41.684 9.1043 41.684 20.1426C41.684 31.1809 32.5201 40.1426 21.2329 40.1426C9.9456 40.1426 0.781738 31.1809 0.781738 20.1426C0.781738 9.1043 9.9456 0.142578 21.2329 0.142578ZM14.8389 31.3825V15.7637H9.52931V31.3825H14.8389ZM33.9815 31.3825V22.4258C33.9815 17.6282 31.3622 15.3964 27.8694 15.3964C25.053 15.3964 23.7914 16.9112 23.0851 17.9751V15.7637H17.7768C17.8472 17.2291 17.7768 31.3825 17.7768 31.3825H23.085V22.6598C23.085 22.193 23.1193 21.7262 23.26 21.3926C23.6431 20.4602 24.5172 19.4942 25.9838 19.4942C27.9038 19.4942 28.673 20.927 28.673 23.0258V31.3825H33.9815ZM12.22 8.23242C10.4034 8.23242 9.21647 9.40039 9.21647 10.9312C9.21647 12.4298 10.3672 13.63 12.1497 13.63H12.184C14.0353 13.63 15.1877 12.4298 15.1877 10.9312C15.1534 9.40039 14.0354 8.23242 12.22 8.23242Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default LinkedIn;
