import axiosClient from 'util/axiosClient';
import { EASY_REFILL_API_URL } from 'gatsby-env-variables';
import {
    AuthenticateUserRequest,
    AuthenticateUserResponse,
    VerifyUserRequest,
    VerifyUserResponse,
    GetEasyAutoRefillPatientDataResponse,
    ToggleAutoRefillRequest,
    AutoRefillResponse
} from 'types/auto-refill';

export class AutoRefillService {
    static verifyUser() {
        return {
            post: async (payload: VerifyUserRequest): Promise<VerifyUserResponse> => {
                const response = await axiosClient.post(`${EASY_REFILL_API_URL}/Authentication/VerifyUser`, payload);
                return response.data;
            }
        };
    }
    static authenticateUser() {
        return {
            post: async (payload: AuthenticateUserRequest): Promise<AuthenticateUserResponse> => {
                const response = await axiosClient.post(`${EASY_REFILL_API_URL}/Authentication/Authenticate`, payload);
                return response.data;
            }
        };
    }
    static getSecretKeyHash() {
        return {
            get: async (payload: string): Promise<string> => {
                const response = await axiosClient.get(
                    `${EASY_REFILL_API_URL}/Authentication/CreateSecretKeyHash?securityToken=${payload}`
                );
                return response.data;
            }
        };
    }
    static getPatientData() {
        return {
            get: async (bearerToken: string): Promise<GetEasyAutoRefillPatientDataResponse> => {
                const response = await axiosClient.get(
                    `${EASY_REFILL_API_URL}/EasyAutoRefill/GetEasyAutoRefillPatientData`,
                    {
                        headers: { Authorization: `Bearer ${bearerToken}` }
                    }
                );
                return response.data;
            }
        };
    }
    static toggleAutoRefillForRx() {
        return {
            get: async (payload: ToggleAutoRefillRequest & { bearerToken: string }): Promise<AutoRefillResponse> => {
                const response = await axiosClient.get(
                    `${EASY_REFILL_API_URL}/EasyAutoRefill/ToggleAutoRefill?rxSeqNum=${payload.rxSeqNum}&newStatus=${payload.newStatus}`,
                    {
                        headers: { Authorization: `Bearer ${payload.bearerToken}` }
                    }
                );
                return response.data;
            }
        };
    }
}
