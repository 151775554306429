import { navigate } from 'gatsby';
import React, { useContext, createContext, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'state/account/account.reducers';
import { TrackFlowAbandonment } from 'util/google_optimize/optimize_helper';

const GlobalLinkContext = createContext({
    lastFormField: '',
    setFormName: (formName: string) => {},
    setLastFormField: (field: string) => {},
    setStepName: (step: string) => {},
    setFlowName: (flowName: string) => {},
    handleFieldFocus: (fieldName: string) => {},
    trackFormAbandonment: () => {},
    flowName: '',
    handleSignOut: () => {}
});

export function GlobalLinkProvider({ children }: any) {
    const globalLinkVal = useProvideGlobalLink();

    return <GlobalLinkContext.Provider value={globalLinkVal}>{children}</GlobalLinkContext.Provider>;
}

export const useGlobalLink = () => {
    return useContext(GlobalLinkContext);
};

const useProvideGlobalLink = () => {
    const [lastFormField, setLastFormField] = useState<string>('');
    const [formName, setFormName] = useState<string>('');
    const [flowName, setFlowName] = useState<string>('');
    const [stepName, setStepName] = useState<string>('');

    const dispatch = useDispatch();

    const trackFormAbandonment = useCallback(() => {
        // if we do not have a last form field set or flow name, break out of this call
        if (!flowName) return;

        TrackFlowAbandonment({ lastFormField: lastFormField || 'Not Available', formName, flowName, stepName });

        // reset fields
        setLastFormField('');
        setFormName('');
        setFlowName('');
        setStepName('');
    }, [flowName, formName, lastFormField, stepName]);

    const handleFieldFocus = useCallback((fieldName: string) => {
        setLastFormField(fieldName);
    }, []);

    const handleSignOut = useCallback(() => {
        trackFormAbandonment();
        dispatch(logout());
        navigate('/sign-in');
        return false;
    }, [dispatch, trackFormAbandonment]);

    return {
        lastFormField,
        setLastFormField,
        formName,
        setFormName,
        flowName,
        setFlowName,
        stepName,
        setStepName,
        trackFormAbandonment,
        handleFieldFocus,
        handleSignOut
    };
};
