import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Order, Prescriptions & Cart
import { ExtendedRefillRxs } from 'types/order-prescription';
import { PrescriptionObjectPayload } from 'state/medicine-cabinet/medicine-cabinet.services';
import { DrugWithDiscountPrice } from 'state/drug/drug.reducers';

// Utils
import { hasAdjudicatedPrice } from 'util/cart';
import { noop } from 'util/function';
import { formatPrice } from 'schema/price.schema';
import { safeParseFloat } from 'util/number';

// UI Kit & Components
import AutoRefillBadge from '../auto-refill-badge/auto-refill-badge';
import BirdiPriceIcon from 'ui-kit/icons/birdi-price/birdi-price-icon';
import EmptyAlertBox from 'components/new-medicine-cabinet-cart/empty-alert-box/empty-alert-box';
import Button from 'ui-kit/button/button';
import { ButtonProps } from 'ui-kit/button/button.props';
import { Row } from 'react-bootstrap';

import './prescription-item.style.scss';

export interface PrescriptionItemProps {
    isLoading?: boolean;
    onRemovePrescriptionClick?: ButtonProps['onClick'];
    prescription?: ExtendedRefillRxs;
    currentPrescription?: PrescriptionObjectPayload;
    isInsuranceAccount?: boolean;
    discountPrice?: DrugWithDiscountPrice;
}

const PrescriptionItem: React.FC<PrescriptionItemProps> = (props) => {
    const { isInsuranceAccount = false, onRemovePrescriptionClick = noop, currentPrescription, prescription } = props;

    const { t } = useTranslation();

    const formatBirdiSavings = React.useCallback(
        (item: ExtendedRefillRxs) => {
            if (item) {
                const savingsAsDollar = safeParseFloat(item.awpPrice) - safeParseFloat(item.birdiPrice);
                return t('components.medicineCabinetCart.prescriptionInfo.save', {
                    save: formatPrice(savingsAsDollar)
                });
            } else {
                return '';
            }
        },
        [t]
    );

    return (
        <div className="py-4">
            <section className="prescription-item">
                {/* First Section */}
                <Row className="d-flex flex-nowrap justify-content-between">
                    {/* Drug price */}
                    <div className="prescription-item-info text-ebony-clay">
                        {/* Product Name */}

                        {currentPrescription?.dispensedProductName}

                        {/* When the price is not adjudicted */}

                        {!hasAdjudicatedPrice(prescription, currentPrescription) &&
                        !prescription?.hasKnownPrice &&
                        currentPrescription ? (
                            <sup>*</sup>
                        ) : (
                            ''
                        )}

                        {/* Prescription number */}

                        <div className="prescription-item-rx">
                            {t('components.medicineCabinetCart.prescriptionInfo.rx', {
                                number: prescription?.rxNumber
                            })}
                        </div>
                    </div>

                    {hasAdjudicatedPrice(prescription, currentPrescription) && prescription?.hasKnownPrice ? (
                        <div className="left-n1 ml-lg-auto">
                            {!prescription.insurancePayment && (
                                <div className="text-silver text-decoration-line-through text-right">
                                    {prescription.awpPrice}
                                </div>
                            )}
                            <div className="prescription-item-price">
                                {formatPrice(
                                    prescription.isUsingBirdiPrice ? prescription.birdiPrice : prescription.patientCopay
                                )}
                            </div>
                            {/* Est.Copay - just for insurance patients */}
                            {prescription.insurancePayment !== null && prescription.insurancePayment > 0 && (
                                <div>{t('components.medicineCabinetCart.prescriptionInfo.copay')}</div>
                            )}
                        </div>
                    ) : (
                        <div className="prescription-item-no-price left-n1 text-right">
                            {' '}
                            {t('components.medicineCabinetCart.prescriptionInfo.noPrice')}{' '}
                        </div>
                    )}
                </Row>

                {/* Qunatities */}
                <Row className="d-flex justify-content-between">
                    <div className="prescription-item-quantities d-flex justify-content-between align-items-center">
                        {currentPrescription?.fillQuantity && (
                            <div>{t('pages.cart.quantity', { fillQuantity: currentPrescription.fillQuantity })}</div>
                        )}

                        {(currentPrescription?.dispensedProductStrength ||
                            currentPrescription?.dispensedProductUnitOfMeasure) && (
                            <span className="divider mx-1 text-slate prescription-item-rx">|</span>
                        )}

                        {(currentPrescription?.dispensedProductStrength ||
                            currentPrescription?.dispensedProductUnitOfMeasure) && (
                            <div>
                                {currentPrescription.dispensedProductStrength}
                                {currentPrescription.dispensedProductUnitOfMeasure}
                            </div>
                        )}

                        {currentPrescription?.dispensedProductDosageForm && (
                            <span className="divider mx-1 text-slate prescription-item-rx">|</span>
                        )}

                        {currentPrescription?.dispensedProductDosageForm && (
                            <div>{currentPrescription.dispensedProductDosageForm}</div>
                        )}
                    </div>

                    {prescription?.hasKnownPrice && !prescription.insurancePayment && (
                        <div>
                            {/* Birdi price */}
                            <div className="d-flex justify-content-end">
                                <BirdiPriceIcon />
                                <div>{t('components.medicineCabinetCart.prescriptionInfo.birdiPrice')}</div>
                            </div>

                            {/* Disccount price - Just for insurance patients */}
                            <div className="d-flex text-right">{formatBirdiSavings(prescription)}</div>
                        </div>
                    )}

                    {prescription?.hasKnownPrice &&
                        prescription.insurancePayment !== null &&
                        prescription.insurancePayment > 0 && (
                            <Button
                                type="button"
                                variant="text"
                                className="px-0 pt-0 no-min-width text-cerulean left-n1 ml-lg-auto"
                                label={t('shipping.removeAddress')}
                                onClick={onRemovePrescriptionClick}
                                dataGAFormName="addMorePrescriptions"
                            />
                        )}
                </Row>

                <Row className="d-flex flex-row justify-content-between">
                    {currentPrescription?.autoRefillEnabled ? (
                        <AutoRefillBadge text={t('components.medicineCabinetCart.prescriptionInfo.autoRefill')} />
                    ) : null}
                    {/* Remove Button */}
                    {!prescription?.insurancePayment && (
                        <Button
                            type="button"
                            variant="text"
                            className="px-0 pt-0 no-min-width text-cerulean left-n1 ml-lg-auto"
                            label={t('shipping.removeAddress')}
                            onClick={onRemovePrescriptionClick}
                            dataGAFormName="addMorePrescriptions"
                        />
                    )}
                </Row>
            </section>

            {!prescription?.hasKnownPrice && (
                <Row className="d-flex justify-content-between pt-4">
                    <EmptyAlertBox
                        text={t(
                            isInsuranceAccount
                                ? 'pages.cart.rxItemErrorMessage'
                                : 'pages.cart.rxItemErrorMessageDiscount'
                        )}
                    />
                </Row>
            )}

            {prescription?.showDisclaimer && prescription?.disclaimerTranslationKey && (
                <Row className="d-flex justify-content-between pt-4">
                    <EmptyAlertBox
                        text={t(
                            isInsuranceAccount
                                ? 'pages.cart.rxItemErrorMessage'
                                : 'pages.cart.rxItemErrorMessageDiscount'
                        )}
                    />
                </Row>
            )}
        </div>
    );
};

export default PrescriptionItem;
