import axiosClient from 'util/axiosClient';
import { GATSBY_API_BASE_URL } from 'gatsby-env-variables';
import {
    ExtendedRefillRxs,
    Images,
    NewRxs,
    Note,
    OrderBillShip,
    OrderHeader,
    OTCs,
    RefillRxs
} from 'types/order-prescription';

export interface CartObjectPayload {
    doNotSubmitToWorkflow: boolean;
    originationNum: string;
    orderTotal: string;
    cartId: string | null;
    messageErrorText: string;
    messageStatus: true;
    messageText: string;
    otcs: OTCs;
    images: Images[];
    orderHeader: OrderHeader;
    note: Note[];
    newRxs: NewRxs[];
    refillRxs: RefillRxs[];
    orderBillShip: OrderBillShip;
}

export interface ExtendedCartObjectPayload extends CartObjectPayload {
    orderTotalCalculated: number;
    itemHasUnknownPrice: boolean;
    extendedRefillRxs: ExtendedRefillRxs[];
}

export interface CancelOrderLinePayload {
    rxNumber: string;
    lineId: string; // lineId is actually epostRxScriptId in the order object
    orderNum: string;
}

/**
 * Anything related to Cart in the system.
 */
export default class CartService {
    static startCart() {
        return {
            getAll: (): Promise<any> => {
                return axiosClient.get(`${GATSBY_API_BASE_URL}/api/Order/GetCart`).then((response) => {
                    const { refillRxs, orderHeader, ...params } = response.data;

                    return {
                        orderHeader: {
                            ...orderHeader
                        },
                        refillRxs: refillRxs,
                        ...params
                    };
                });
            },
            post: (payload?: CartObjectPayload): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/Order/StartOrder`, payload)
                    .then((response) => response.data);
            }
        };
    }
    static completeOrder() {
        return {
            post: (payload?: CartObjectPayload): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/Order/CompleteOrder`, payload)
                    .then((response) => response.data);
            }
        };
    }
    static updateRefillLines() {
        return {
            post: (payload?: CartObjectPayload): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/Order/updateRefillLines`, payload)
                    .then((response) => response.data);
            }
        };
    }
    static cancelOrderLine() {
        return {
            post: (payload?: CartObjectPayload): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/Order/CancelOrderLine`, payload)
                    .then((response) => response.data);
            }
        };
    }
    static cancelOrder() {
        return {
            post: (payload?: CancelOrderLinePayload): Promise<any> => {
                return axiosClient
                    .post(
                        `${GATSBY_API_BASE_URL}/api/Order/CancelOrderLine?rxNumber=${payload?.rxNumber}&lineId=${payload?.lineId}&orderNum=${payload?.orderNum}`
                    )
                    .then((response) => response.data);
            }
        };
    }
    static updateCart() {
        return {
            post: (payload?: CartObjectPayload): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/Order/UpdateCart/false`, payload)
                    .then((response) => {
                        return response.data;
                    });
            }
        };
    }
}
