import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'state/store';

export const easyRefillSelector = (state: RootState) => state.easyRefillReducer;

export const easyRefillUserVerificationFailedSelector = createSelector(easyRefillSelector, (easyRefill) => {
    return easyRefill.userVerified !== 'UserVerified';
});

export const easyRefillUserVerifiedSelector = createSelector(easyRefillSelector, (easyRefill) => {
    return easyRefill.userVerified === 'UserVerified';
});

export const easyRefillSecurityTokenSelector = createSelector(easyRefillSelector, (easyRefill) => {
    return easyRefill.securityToken;
});

export const easyRefillSecretKeyHashSelector = createSelector(easyRefillSelector, (easyRefill) => {
    return easyRefill.secretKeyHash;
});

export const easyRefillErrorMessageSelector = createSelector(easyRefillSelector, (easyRefill) => {
    return easyRefill.error.messageText ?? '';
});

export const easyRefillUserBearerTokenSelector = createSelector(easyRefillSelector, (easyRefill) => {
    return easyRefill.bearerToken;
});

export const easyRefillFirstNameSelector = createSelector(easyRefillSelector, (easyRefill) => {
    return easyRefill.firstName;
});

export const easyRefillLoadingRxsSelector = createSelector(easyRefillSelector, (easyRefill) => {
    return easyRefill.loadingPrescriptions;
});

export const easyRefillRxsSelector = createSelector(easyRefillSelector, (easyRefill) => {
    return easyRefill.rxResults;
});

export const easyRefillLoadingAddressSelector = createSelector(easyRefillSelector, (easyRefill) => {
    return easyRefill.loadingAddress;
});

export const easyRefillPatientAddressSelector = createSelector(easyRefillSelector, (easyRefill) => {
    const addresses = easyRefill.addresses;
    return addresses.find((address) => address.defaultShip === true);
});

export const easyRefillPatientBillAddressSelector = createSelector(easyRefillSelector, (easyRefill) => {
    const addresses = easyRefill.addresses;
    return addresses.find((address) => address.defaultBill === true);
});

export const easyRefillLoadingPaymentCardSelector = createSelector(easyRefillSelector, (easyRefill) => {
    return easyRefill.loadingPaymentCard;
});

export const easyRefillPatientPaymentCardSelector = createSelector(easyRefillSelector, (easyRefill) => {
    const paymentCards = easyRefill.paymentCards;
    return paymentCards.find((paymentCard) => paymentCard.defaultCard === true);
});

export const easyRefillRxsToRefillSelector = createSelector(easyRefillSelector, (easyRefill) => {
    return easyRefill.refillOrder.rxsToRefill;
});

export const easyRefillExpeditedShippingSelector = createSelector(easyRefillSelector, (easyRefill) => {
    return easyRefill.refillOrder.expeditedShipping;
});

export const easyRefillPlanNumberSelector = createSelector(easyRefillSelector, (easyRefill) => {
    return easyRefill.epostPlanNum;
});

export const easyRefillPlanAliasSelector = createSelector(easyRefillSelector, (easyRefill) => {
    return easyRefill.planAlias;
});

export const easyRefillAjudicatedRxsSelector = createSelector(easyRefillSelector, (easyRefill) => {
    return easyRefill.adjudicatedRxes;
});

export const easyRefillRxsLoadedSelector = createSelector(easyRefillSelector, (easyRefill) => {
    return easyRefill.prescriptionsLoaded;
});

export const easyRefillRxsToOrderSelector = createSelector(easyRefillSelector, (easyRefill) => {
    const rxs = easyRefill.refillOrder.rxsToRefill;
    return easyRefill.rxResults.filter((rxResult) => rxs.includes(rxResult.rxNumber));
});

export const easyRefillOrderInvoiceNumberSelector = createSelector(easyRefillSelector, (easyRefill) => {
    return easyRefill.orderInvoiceNumber;
});

export const easyRefillAllRxsSelector = createSelector(
    [easyRefillRxsSelector, easyRefillRxsToRefillSelector],
    (allRxs, selectedRxs) => {
        const allRxNumbers = allRxs.map((rx) => rx.rxNumber);
        return allRxNumbers.length === selectedRxs.length;
    }
);

export const easyRefillAccountHasInsuranceSelector = createSelector(
    easyRefillSelector,
    (easyRefill) => easyRefill.planAlias !== 'BRD01'
);
