import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';
import { createRoutine } from 'redux-saga-routines';
import {
    AuthenticateUserResponse,
    AutoRefillFlag,
    GetEasyAutoRefillPatientDataResponse,
    RxResult,
    VerifyUserResponse
} from 'types/auto-refill';

export interface AutoRefillState {
    userVerified: 'UserNotVerified' | 'UserVerified' | 'MaxAttemptsReached' | '';
    securityToken: string;
    secretKeyHash: string;
    bearerToken: string;
    error: {
        messageText?: string;
    };
    loadingPrescriptions: boolean;
    prescriptionsLoaded: boolean;
    firstName: string;
    rxResults: RxResult[];
    autoRefillFlags: AutoRefillFlag[];
    orderInvoiceNumber: string;
    emailSent: boolean;
    isBusy: boolean;
    epostPlanNum: string;
    planAlias: string;
}

const initialState: AutoRefillState = {
    userVerified: '',
    securityToken: '',
    secretKeyHash: '',
    bearerToken: '',
    error: {
        messageText: undefined
    },
    loadingPrescriptions: false,
    prescriptionsLoaded: false,
    firstName: '',
    rxResults: [],
    autoRefillFlags: [],
    orderInvoiceNumber: '',
    emailSent: false,
    isBusy: false,
    epostPlanNum: '',
    planAlias: ''
};

// Routines
export const autoRefillVerifyUserRoutine = createRoutine('auto-refill/VERIFY_USER');
export const autoRefillGetSecretKeyHashRoutine = createRoutine('auto-refill/GET_SECRET_KEY_HASH');
export const autoRefillAuthenticateUserRoutine = createRoutine('auto-refill/AUTHENTICATE_USER');
export const autoRefillGetPatientDataRoutine = createRoutine('auto-refill/GET_PATIENT_DATA');
export const autoRefillToggleAutoRefillRxStatusRoutine = createRoutine('auto-refill/TOGGLE_AUTO_REFILL_RX_STATUS');

const autoRefillSlice = createSlice({
    name: 'auto-refill',
    initialState,
    reducers: {
        setSecurityToken: (state, action: PayloadAction<{ securityToken: string }>) => {
            state.securityToken = action.payload.securityToken;
        },
        resetBearerToken: (state) => {
            state.bearerToken = initialState.bearerToken;
        }
    },
    extraReducers: ({ addCase }) => {
        /**
         * Auto Refill reducers
         */
        // Verify User
        addCase(autoRefillVerifyUserRoutine.SUCCESS, (state, { payload }: PayloadAction<VerifyUserResponse>) =>
            produce(state, (draftState) => {
                draftState.userVerified = payload.verificationStatus;
            })
        );
        addCase(autoRefillVerifyUserRoutine.FAILURE, (state, { payload }: PayloadAction<VerifyUserResponse>) =>
            produce(state, (draftState) => {
                draftState.error.messageText = payload.messageText;
            })
        );
        // Getting user secret key hash for authentication
        addCase(autoRefillGetSecretKeyHashRoutine.SUCCESS, (state, { payload }: PayloadAction<string>) =>
            produce(state, (draftState) => {
                draftState.secretKeyHash = payload;
            })
        );
        addCase(autoRefillGetSecretKeyHashRoutine.FAILURE, (state, { payload }: PayloadAction<string>) =>
            produce(state, (draftState) => {
                draftState.error.messageText = payload;
            })
        );
        // authenticate user
        addCase(
            autoRefillAuthenticateUserRoutine.SUCCESS,
            (state, { payload }: PayloadAction<AuthenticateUserResponse>) =>
                produce(state, (draftState) => {
                    draftState.bearerToken = payload.bearerToken;
                })
        );
        addCase(
            autoRefillAuthenticateUserRoutine.FAILURE,
            (state, { payload }: PayloadAction<AuthenticateUserResponse>) =>
                produce(state, (draftState) => {
                    draftState.bearerToken = initialState.bearerToken;
                    draftState.error.messageText = payload.messageText;
                })
        );
        // Fetching Patient data
        addCase(autoRefillGetPatientDataRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.loadingPrescriptions = true;
            })
        );
        addCase(
            autoRefillGetPatientDataRoutine.SUCCESS,
            (state, { payload }: PayloadAction<GetEasyAutoRefillPatientDataResponse>) =>
                produce(state, (draftState) => {
                    draftState.loadingPrescriptions = false;
                    draftState.rxResults = payload.rxResults;
                    draftState.firstName = payload.firstName;
                    draftState.autoRefillFlags = payload.autoRefillFlags;
                    draftState.prescriptionsLoaded = true;
                })
        );
        addCase(autoRefillGetPatientDataRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.loadingPrescriptions = false;
                draftState.rxResults = initialState.rxResults;
                draftState.autoRefillFlags = initialState.autoRefillFlags;
            })
        );
        addCase(autoRefillToggleAutoRefillRxStatusRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
            })
        );
        addCase(
            autoRefillToggleAutoRefillRxStatusRoutine.SUCCESS,
            (state, { payload }: PayloadAction<{ rxNumber: string; autoRefillEnabled: boolean }>) =>
                produce(state, (draftState) => {
                    const autoRefillFlags = draftState.autoRefillFlags;

                    const rxIndex = autoRefillFlags?.findIndex((rx) => rx.rxNumber === payload.rxNumber);
                    draftState.autoRefillFlags = autoRefillFlags?.map((autoRefillFlag, index) => {
                        if (index !== rxIndex) {
                            return autoRefillFlag;
                        }

                        return { ...autoRefillFlag, autoRefillEnabled: payload.autoRefillEnabled };
                    });
                    draftState.isBusy = false;
                })
        );
        addCase(autoRefillToggleAutoRefillRxStatusRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = false;
            })
        );
    }
});

export const autoRefillActions = autoRefillSlice.actions;

export default autoRefillSlice.reducer;
