import React, { ReactElement, useState, useRef } from 'react';
import classNames from 'classnames';

// import ChevronIcon from 'ui-kit/chevron-icon/chevron-icon';
import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { SelectOptionValue } from 'types/select';
import { TrackInputFocus } from 'util/google_optimize/optimize_helper';
import InputError from 'ui-kit/input-error/input-error';
import Form from 'react-bootstrap/Form';
import { useField } from 'formik';

import { SelectOptionProps, SelectProps } from './select.props';
import './select.style.scss';

const Select = <T extends SelectOptionValue>(props: SelectProps<T>): ReactElement => {
    const inputEl = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState<T | undefined>(props.value);
    const [, meta] = useField(props.name);
    const formikValue = meta.value;
    const { t } = useTranslation();

    const {
        className,
        errors,
        placeholder,
        onChange,
        options = [],
        touched,
        disabled = false,
        showSelectPlaceholder,
        showTitle = false,
        onFocus
    } = props;

    const selectedOption = options && props.value ? options.find((opt) => opt.value === props.value) : undefined;
    const controlTitle = selectedOption ? selectedOption.label : undefined;

    const classes = classNames(
        'select',
        className,
        { 'is-open': isMenuOpen },
        { 'has-value': props.formikControlled ? formikValue || (showSelectPlaceholder && !disabled) : selectedValue },
        { 'has-errors': errors && touched }
    );

    const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        TrackInputFocus(event.target, placeholder);
        onFocus && onFocus(event.target.name);
    };

    const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
        const currentTarget = e.currentTarget;

        // Check the newly focused element in the next tick of the event loop
        setTimeout(() => {
            // Check if the new activeElement is a child of the original container
            if (!currentTarget.contains(document.activeElement)) {
                // You can invoke a callback or add custom logic here
                setIsMenuOpen(false);
            }
        }, 0);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        if (target) {
            const selectedOptions = target.selectedOptions;
            if (selectedOptions && selectedOptions[0]) {
                // subtract one, since the select starts with a blank option
                const selectedOption = options[selectedOptions[0].index - 1];
                if (selectedOption) {
                    if (!props.formikControlled) {
                        setSelectedValue(selectedOption.value);
                    }
                    if (onChange) onChange({ key: selectedOption.key, option: selectedOption });
                }
            }
        }
    };

    return (
        <div className={classes} onBlur={handleBlur} onFocus={handleOnFocus}>
            <div className="select-display-text">
                <label htmlFor={props.name} className="select-placeholder">
                    {placeholder}
                </label>
            </div>
            <Form.Control
                ref={inputEl}
                name={props.name}
                id={props.name}
                as="select"
                value={
                    props.formikControlled
                        ? showSelectPlaceholder && !disabled && !formikValue
                            ? ''
                            : formikValue
                        : selectedValue
                }
                title={showTitle && controlTitle ? controlTitle : undefined}
                onChange={handleChange}
                className="select-display"
                disabled={disabled}
            >
                <option disabled={showSelectPlaceholder && !disabled} value="">
                    {showSelectPlaceholder && !disabled ? t('components.select.placeholderOption') : ''}
                </option>
                {options &&
                    options.map((opt: SelectOptionProps<T>) => (
                        <option key={`select-${opt.value}`} value={opt.value}>
                            {opt.label}
                        </option>
                    ))}
            </Form.Control>
            <ChevronIcon direction="down" />
            <InputError
                inputElement={inputEl}
                touched={touched}
                label={placeholder}
                errors={errors}
                className="select-errors"
            />
        </div>
    );
};

export default Select;
