import * as yup from 'yup';
import valid from 'card-validator';

const isValidCardType = (cardNumber: string): boolean => {
    const cardTypes = [
        /^4[0-9]{12}(?:[0-9]{3})?$/,
        /^5[1-5][0-9]{14}$/,
        /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        /^3[47][0-9]{13}$/
    ];
    return cardTypes.map((cardType) => cardType.test(cardNumber)).includes(true);
};

export const PAYMENT_SCHEMA = yup.object().shape({
    cardNumber: yup
        .string()
        .required()
        .min(13)
        .max(19)
        .test(
            'test-number', // this is used internally by yup
            (value) => valid.number(value).isValid
        )
        .test(
            'test-number', // this is used internally by yup
            (value) => isValidCardType(value)
        ),
    nameOnCard: yup
        .string()
        .required()
        .test('test-name', (value) => valid.cardholderName(value).isValid),
    expMonth: yup
        .string()
        .required()
        .test('test-month', (value, { parent }) => {
            const currentYear: number = new Date().getFullYear();
            const currentMonth: number = new Date().getMonth();
            const expiryYear: number = parent.expYear ? parseInt(parent.expYear) : currentYear;
            const expiryMonth: number = new Date(`${value}, 1`).getMonth();
            if (expiryYear === currentYear) {
                return expiryMonth > currentMonth ? true : false;
            } else {
                return true;
            }
        }),
    expYear: yup.string().required(),
    cvcNumber: yup
        .string()
        .required()
        .test('cvc-number', (value) => valid.cvv(value, [3, 4]).isValid)
});
