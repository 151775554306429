import React from 'react';

import { Container, Col, Row } from 'react-bootstrap';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import useSiteClient from 'hooks/useSiteClient';

import { ContentBlock } from 'types/content-block';
import { HowItWorksProps } from 'components/how-it-works/how-it-works.props';

import CtaStack from 'ui-kit/cta-stack/cta-stack';

import './how-it-works.style.scss';

const HowItWorksContentBlock = ({ image, imageAltText, title, text }: ContentBlock, sectionIndex: number) => {
    return (
        <Container fluid className="content-block">
            <Row>
                <Col>{image && <GatsbyImage image={getImage(image)} alt={imageAltText} />}</Col>
            </Row>
            <Row className="content-block-typography mt-4">
                <Col xs={12} className="d-flex justify-content-center text-center">
                    {title && sectionIndex === 0 && <h2 className="h3 content-block-title">{title}</h2>}
                    {title && sectionIndex > 0 && <h3 className="content-block-title">{title}</h3>}
                </Col>
            </Row>
            <Row className="content-block-typography justify-content-center">
                <Col xs={12} md={10} xl={9} className="d-flex justify-content-center">
                    {text && <p className="content-block-text">{text}</p>}
                </Col>
            </Row>
        </Container>
    );
};

const HowItWorks = ({
    className,
    ctaText,
    ctaTo,
    eyebrowText,
    title,
    backgroundColor,
    backgroundColorFullWidth,
    blocks,
    sectionIndex
}: HowItWorksProps) => {
    const { isPrivateLabel } = useSiteClient();
    const containerClasses = classNames(
        'how-it-works',
        { [`${className}`]: className },
        { [`${backgroundColor}`]: backgroundColor },
        { 'has-background-color-full-width': backgroundColorFullWidth }
    );

    return (
        <Container
            fluid
            className={containerClasses}
            data-ga-index={`${sectionIndex > -1 ? 1 + sectionIndex : 0}`}
            data-ga-location={`Content Block`}
            data-ga-title={title}
        >
            <Row className="how-it-works-header justify-content-center">
                {eyebrowText && (
                    <Col xs={12} className="d-flex justify-content-center">
                        <div className="h5 eyebrow-text">{eyebrowText}</div>
                    </Col>
                )}
                {title && (
                    <Col xs={12} md={4} xl={4} className="d-flex justify-content-center">
                        {sectionIndex === 0 && <h1 className="h2 title">{title}</h1>}
                        {sectionIndex > 0 && <h2 className="title">{title}</h2>}
                    </Col>
                )}
                {ctaText && ctaTo && (
                    <Col xs={12} className="d-flex justify-content-center">
                        <div className="how-it-works-cta-container">
                            <div className="spacer" />
                            <CtaStack to={ctaTo} label={ctaText} hiddenIcon={isPrivateLabel} />
                        </div>
                    </Col>
                )}
            </Row>
            {blocks && (
                <Row className="how-it-works-content-blocks justify-content-center">
                    {blocks.map((block: ContentBlock, idx: number) => (
                        <React.Fragment key={`how-it-works-content-keyed-fragment-${idx}`}>
                            <Col xs={12} md={4}>
                                {HowItWorksContentBlock(block, sectionIndex)}
                            </Col>
                        </React.Fragment>
                    ))}
                </Row>
            )}
        </Container>
    );
};

export default HowItWorks;
