import classNames from 'classnames';
import { PlusIconProps } from './plus-icon.props';
import './plus-icon.style.scss';

const PlusIcon = ({ className, style, type = 'primary' }: PlusIconProps) => {
    const classes = classNames(className, {
        'plus-icon': type === 'primary',
        'secondary-plus-icon': type !== 'primary'
    });

    return (
        <>
            <div className={classes} style={style}>
                {type === 'primary' ? (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        viewBox="0 0 38 38"
                        height="38px"
                        width="38px"
                    >
                        <g>
                            <g fill="#039fd3">
                                <circle cx="19" cy="19" r="19"></circle>
                            </g>
                            <path
                                d="M25.4772727,17.7045455 L20.2954545,17.7045455 L20.2954545,12.5227273 C20.2954545,11.8072675 19.7154598,11.2272727 19,11.2272727 C18.2845402,11.2272727 17.7045455,11.8072675 17.7045455,12.5227273 L17.7045455,17.7045455 L12.5227273,17.7045455 C11.8072675,17.7045455 11.2272727,18.2845402 11.2272727,19 C11.2272727,19.7154598 11.8072675,20.2954545 12.5227273,20.2954545 L17.7045455,20.2954545 L17.7045455,25.4772727 C17.7045455,26.1927325 18.2845402,26.7727273 19,26.7727273 C19.7154598,26.7727273 20.2954545,26.1927325 20.2954545,25.4772727 L20.2954545,20.2954545 L25.4772727,20.2954545 C26.1927325,20.2954545 26.7727273,19.7154598 26.7727273,19 C26.7727273,18.2845402 26.1927325,17.7045455 25.4772727,17.7045455 Z"
                                fill="#FFFFFF"
                                fillRule="nonzero"
                            ></path>
                        </g>
                    </svg>
                ) : (
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="3.61523" width="1.80796" height="10" rx="0.903978" fill="#03A4DC" />
                        <rect
                            x="9.03906"
                            y="4"
                            width="2"
                            height="9.03978"
                            rx="1"
                            transform="rotate(90 9.03906 4)"
                            fill="#03A4DC"
                        />
                    </svg>
                )}
            </div>
        </>
    );
};

export default PlusIcon;
