import React from 'react';
import { graphql } from 'gatsby';
import { HeroProps } from 'components/hero/hero.props';
import { HeroWithBackgroundImage } from 'components/hero/hero.component';

export default function MultipleImageHeroProvider({
    paragraph,
    sectionIndex
}: {
    paragraph: GatsbyTypes.MultipleImageHeroProviderFragment;
    sectionIndex: number;
}) {
    const heroProps: HeroProps = {
        title: paragraph.field_mihc_title ? paragraph.field_mihc_title : 'ERROR: Title was not passed from drupal',
        subTitle: paragraph.field_mihc_subtitle,
        eyebrowText: paragraph.field_mihc_eyebrow,
        eyebrowColor: paragraph.field_mihc_eyebrow_color,
        backgroundColor: paragraph.field_rhc_background_color,
        backgroundColorFullWidth: paragraph.field_rhc_background_full_width,
        text: paragraph.field_mihc_body,
        backgroundImage:
            paragraph.relationships?.field_mihc_background_image?.relationships?.field_media_image?.localFile,
        image: paragraph.relationships?.field_mihc_secondary_image?.relationships?.field_media_image?.localFile,
        imageAltText: paragraph.relationships?.field_mihc_secondary_image?.field_media_image?.alt,
        linkTo: paragraph.field_mihc_cta_button?.uri ? paragraph.field_mihc_cta_button?.uri : '/404',
        linkLabel: paragraph.field_mihc_cta_button?.title
            ? paragraph.field_mihc_cta_button?.title
            : 'ERROR: Link Title not found',
        useRoundedCorners: true,
        sectionIndex: sectionIndex
    };
    return <HeroWithBackgroundImage {...heroProps} />;
}

export const query = graphql`
    fragment MultipleImageHeroProvider on paragraph__multiple_image_hero_component {
        field_mihc_eyebrow
        field_mihc_eyebrow_color
        field_rhc_background_color
        field_rhc_background_full_width
        field_mihc_subtitle
        field_mihc_title
        field_mihc_body
        field_mihc_cta_button {
            title
            uri: url
        }
        relationships {
            field_mihc_background_image {
                relationships {
                    field_media_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(formats: [AUTO])
                            }
                        }
                    }
                }
            }
            field_mihc_secondary_image {
                relationships {
                    field_media_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(formats: [AUTO, WEBP])
                            }
                        }
                    }
                }
                field_media_image {
                    alt
                }
            }
        }
    }
`;
