import React from 'react';
import './ListIcon.style.scss';

export interface ListIconProps {
    icon: JSX.Element;
    subtitle: string;
    bodyText: string;
}

export default function ListIcon({ icon, subtitle, bodyText }: ListIconProps) {
    return (
        <div className="list-icon justify-content-center mb-2">
            <div className="list-icon__icon">{icon}</div>
            <div className="h6 list-icon__title">{subtitle}</div>
            <p className="body-text">{bodyText}</p>
        </div>
    );
}
