import React, { ReactElement } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { InternalHeaderProps } from './internal-header.props';

import BackgroundImage from 'ui-kit/background-image/background-image';

import './internal-header.style.scss';

const InternalHeader = ({
    eyebrowText,
    title,
    body,
    backgroundImage,
    backgroundStyle,
    sectionIndex
}: InternalHeaderProps): ReactElement => {
    return (
        <div className="internal-header">
            {backgroundImage && (
                <BackgroundImage image={backgroundImage} style={backgroundStyle} className="header-background" />
            )}
            <Container fluid="xl">
                <Row className="justify-content-lg-center">
                    <Col xs={12} md={12} lg={8}>
                        <div className="internal-header-text">
                            {eyebrowText && <div className="h5 internal-header-eyebrow">{eyebrowText}</div>}
                            {sectionIndex === 0 && <h1 className="internal-header-title">{title}</h1>}
                            {sectionIndex > 0 && <h2 className="h1 internal-header-title">{title}</h2>}
                            {body && (
                                <>
                                    <div className="spacer" />
                                    <div className="col-lg-10 col-md-10 col-12">
                                        <div className="h4 internal-header-body">{body}</div>
                                    </div>
                                </>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default InternalHeader;
