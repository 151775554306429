/* eslint-disable no-unused-vars */

import { PrescriptionCardProps } from 'components/prescriptions-list-v2/prescriptions-card/types';
import { PrescriptionObjectPayload } from 'state/medicine-cabinet/medicine-cabinet.services';
import moment from 'moment';

export enum CURRENT_STEP_DESCRIPTION {
    UNKNOWN = 0,
    PROCESSING = 1,
    FILLING_IN_PHARMACY = 2,
    PACKAGED = 3,
    SHIPPED = 4,
    OUT_FOR_DELIVERY = 5,
    DELIVERED = 6
}

export enum RX_SUB_STATUS {
    PLEASE_CALL_BIRDI = 7,
    AUTH_REQUIRED = 8,
    WAITING_ON_NEW_RX = 9
}

export function addDays(date: string, days: number): Date {
    const origDate = new Date(date);
    origDate.setDate(origDate.getDate() + days);
    return origDate;
}

export function isShipStatusExpired(shipDate: string): Boolean {
    if (!shipDate) return false;
    const showShippedStatusDays = 14;
    const shippedExpirationDate = addDays(shipDate, showShippedStatusDays);
    const today = new Date(Date.now());
    return !!(shippedExpirationDate < today);
}

/**
 * Function to check the order is on hold or ordered/shipped state
 * @param orderLineQueueStatus - Order Line queue status of prescription
 * @returns boolean
 */
export const isOrderLineQueued = (orderLineQueueStatus: string): boolean => {
    if (!orderLineQueueStatus) {
        return true;
    }
    return ['SHIPPED', 'NOTFOUND', 'OPEN'].includes(orderLineQueueStatus);
};

export const isRxOrderedInProgress = (rx: PrescriptionObjectPayload) => {
    return (
        rx.webEligibilityStatus === 'NOT_ELIGIBLE' &&
        (rx.itemInWorkflow === true ||
            (isOrderLineQueued(rx.orderLineQueueStatus) &&
                rx.itemInWorkflow === false &&
                !isShipStatusExpired(rx.lastFillDate)))
    );
};

export const isRxOnHold = (rx: PrescriptionObjectPayload) => {
    return rx.webEligibilityStatus === 'NOT_ELIGIBLE' && rx.itemInWorkflow === true && rx.orderQueueStatus === 'HOLD';
};

export const isRxWhiteCard = (orderStatus: PrescriptionCardProps['orderStatus']) => {
    return ['NOT_ON_FORMULARY', 'PENDING', 'MDO_WAITING', 'MDO_CANCELLED', 'ON_HOLD'].includes(orderStatus);
};

export function canRxBeCancelled(rx: PrescriptionObjectPayload): boolean {
    if (rx.itemInWorkflow === true) {
        return false;
    }

    const isInProgressStatus = ['PACKAGED', 'SHIP READY', 'SHIPPED'].includes(rx.orderStatus);

    return !isInProgressStatus;
}

export function isRxExpired(expirationDate: string): boolean {
    const today = moment(new Date()).format('MM/DD/YYYY');
    return moment(expirationDate).isBefore(today, 'day');
}
