import { createSelector } from '@reduxjs/toolkit';
import { BirdiModalState } from './birdi-modal.reducers';

export const birdiModalSelector = (state: any) => state.birdiModalReducer;
export const birdiModalClassnameSelector = createSelector(
    birdiModalSelector,
    (state: BirdiModalState) => state.className
);
export const birdiModalOpenSelector = createSelector(birdiModalSelector, (state: BirdiModalState) => state.isOpen);
export const birdiModalShowCloseSelector = createSelector(
    birdiModalSelector,
    (state: BirdiModalState) => state.showClose
);
export const birdiModalOnCloseSelector = createSelector(birdiModalSelector, (state: BirdiModalState) => state.onClose);
export const birdiModalHeaderContentSelector = createSelector(
    birdiModalSelector,
    (state: BirdiModalState) => state.headerContent
);
export const birdiModalContentSelector = createSelector(birdiModalSelector, (state: BirdiModalState) => ({
    contentClassName: state.contentClassName,
    bodyContent: state.bodyContent,
    ctas: state.ctas
}));
export const birdiModalBusySelector = createSelector(birdiModalSelector, (state: BirdiModalState) => state.isBusy);
export const birdiModalBackdropSelector = createSelector(
    birdiModalSelector,
    (state: BirdiModalState) => state.backdrop
);
export const birdiModalSizeSelector = createSelector(birdiModalSelector, (state: BirdiModalState) => state.size);
export const birdiModalTypeSelector = createSelector(birdiModalSelector, (state: BirdiModalState) => state.type);
