import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import {
    autoRefillAuthenticateUserRoutine,
    autoRefillVerifyUserRoutine,
    autoRefillGetSecretKeyHashRoutine,
    autoRefillGetPatientDataRoutine,
    autoRefillToggleAutoRefillRxStatusRoutine
} from './auto-refill.reducer';
import {
    AuthenticateUserRequest,
    AuthenticateUserResponse,
    AutoRefillFlag,
    AutoRefillResponse,
    GetEasyAutoRefillPatientDataResponse,
    ToggleAutoRefillRequest,
    VerifyUserRequest,
    VerifyUserResponse
} from 'types/auto-refill';
import { AutoRefillService } from './auto-refill.services';
import { baseEffectHandler } from 'util/sagas/sagas';
import { TrackError } from 'util/google_optimize/optimize_helper';
import {
    autoRefillFlagsSelector,
    autoRefillSecretKeyHashSelector,
    autoRefillSecurityTokenSelector,
    autoRefillUserBearerTokenSelector
} from './auto-refill.selectors';
import { string } from 'yargs';

function* generateSecretKeySaga(
    action: PayloadAction<{
        onFailure?: (response: string) => void;
    }>
) {
    try {
        const securityToken: string = yield select(autoRefillSecurityTokenSelector);

        yield baseEffectHandler({
            service: AutoRefillService.getSecretKeyHash().get,
            isAuthenticatedService: false,
            data: securityToken,
            *onResponse(response: AuthenticateUserResponse) {
                yield put(autoRefillGetSecretKeyHashRoutine.success(response));
            },
            *onError() {
                yield put(autoRefillGetSecretKeyHashRoutine.failure('The security token is invalid'));
                const { onFailure } = action.payload;
                if (onFailure) {
                    onFailure('The security token is invalid');
                }
            }
        });
    } catch (error) {
        yield put(autoRefillGetSecretKeyHashRoutine.failure(error));
    }
}

function* authenticateUserSaga() {
    try {
        const securityToken: string = yield select(autoRefillSecurityTokenSelector);
        const secretKeyHash: string = yield select(autoRefillSecretKeyHashSelector);
        // if successful verification - authenticate user
        const authenticateData: AuthenticateUserRequest = {
            securityToken,
            secretKeyHash,
            authenticationType: 'EasyAutoRefill'
        };

        yield baseEffectHandler({
            service: AutoRefillService.authenticateUser().post,
            isAuthenticatedService: false,
            data: authenticateData,
            *onResponse(response: AuthenticateUserResponse) {
                if (response && response.messageText !== 'Authenticated') {
                    yield put(autoRefillAuthenticateUserRoutine.failure(response));
                    TrackError('auto-refill.sagas.ts', 'authenticateUserSaga', response.messageText);
                } else {
                    yield put(autoRefillAuthenticateUserRoutine.success(response));
                }
            },
            *onError(error) {
                yield put(autoRefillAuthenticateUserRoutine.failure({ messageText: 'An error has occured' }));
            }
        });
    } catch (error) {}
}

function* verifyUserSaga(
    action: PayloadAction<{
        dateOfBirth: string;
        zipcode: string;
        onSuccess?: () => void;
        onFailure?: (response: VerifyUserResponse) => void;
        onMaxNumAttemptsReached?: (response: VerifyUserResponse) => void;
    }>
) {
    try {
        const securityToken: string = yield select(autoRefillSecurityTokenSelector);
        const { dateOfBirth, zipcode, onFailure, onMaxNumAttemptsReached, onSuccess } = action.payload;
        const data: VerifyUserRequest = {
            dateOfBirth,
            zipcode,
            securityToken,
            authenticationType: 'EasyAutoRefill'
        };

        yield baseEffectHandler({
            service: AutoRefillService.verifyUser().post,
            isAuthenticatedService: false,
            data: data,
            *onResponse(response: VerifyUserResponse) {
                if (response && response.verificationStatus === 'MaxAttemptsReached') {
                    yield put(autoRefillVerifyUserRoutine.failure(response));
                    if (onMaxNumAttemptsReached) onMaxNumAttemptsReached(response);
                } else if (response && response.verificationStatus !== 'UserVerified') {
                    yield put(autoRefillVerifyUserRoutine.failure(response));
                    TrackError('auto-refill.sagas.ts', 'verifyUserSaga', response.messageText);
                    if (onFailure) onFailure(response);
                } else {
                    yield put(autoRefillVerifyUserRoutine.success(response));
                    yield put(autoRefillAuthenticateUserRoutine.trigger());
                    if (onSuccess) onSuccess();
                }
            },
            *onError(error) {
                yield put(autoRefillVerifyUserRoutine.failure({ messageText: 'An error has occured' }));
            }
        });
    } catch (error) {
        console.log(error);
    }
}

function* getPatientDataSaga(action: PayloadAction<{ onFailure?: () => void }>) {
    try {
        const bearerToken: string = yield select(autoRefillUserBearerTokenSelector);
        yield baseEffectHandler({
            service: AutoRefillService.getPatientData().get,
            isAuthenticatedService: false,
            data: bearerToken,
            *onResponse(response: GetEasyAutoRefillPatientDataResponse) {
                yield put(autoRefillGetPatientDataRoutine.success(response));
            },
            *onError() {
                yield put(autoRefillGetPatientDataRoutine.failure({ messageText: 'An error has occured' }));
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    } catch (error) {
        yield put(autoRefillGetPatientDataRoutine.failure({ messageText: 'An error has occurred' }));
    }
}

function* toggleAutoRefillForRxSaga(
    action: PayloadAction<{
        rxsToToggle: { rxSeqNum: string; newStatus: boolean; rxNumber: string };
        onFailure?: () => void;
        onSuccess?: () => void;
    }>
) {
    try {
        const { rxsToToggle, onSuccess } = action.payload;
        const bearerToken: string = yield select(autoRefillUserBearerTokenSelector);
        const data: ToggleAutoRefillRequest = {
            rxSeqNum: rxsToToggle.rxSeqNum,
            newStatus: rxsToToggle.newStatus
        };

        yield baseEffectHandler({
            service: AutoRefillService.toggleAutoRefillForRx().get,
            data: { ...data, bearerToken: bearerToken },
            isAuthenticatedService: false,
            *onResponse(response: AutoRefillResponse) {
                if (response.messageStatus) {
                    yield put(
                        autoRefillToggleAutoRefillRxStatusRoutine.success({
                            rxNumber: rxsToToggle.rxNumber,
                            autoRefillEnabled: rxsToToggle.newStatus
                        })
                    );
                } else {
                    yield put(
                        autoRefillToggleAutoRefillRxStatusRoutine.failure({ messageText: 'An error has occured' })
                    );
                }
            },
            *onError() {
                yield put(autoRefillToggleAutoRefillRxStatusRoutine.failure({ messageText: 'An error has occured' }));
            }
        });
        if (onSuccess) onSuccess();
    } catch (error) {
        yield put(autoRefillToggleAutoRefillRxStatusRoutine.failure({ messageText: 'An error has occurred' }));
    }
}

function* autoRefillSaga() {
    yield takeLatest(autoRefillVerifyUserRoutine.TRIGGER, verifyUserSaga);
    yield takeLatest(autoRefillAuthenticateUserRoutine.TRIGGER, authenticateUserSaga);
    yield takeLatest(autoRefillGetSecretKeyHashRoutine.TRIGGER, generateSecretKeySaga);
    yield takeLatest(autoRefillGetPatientDataRoutine.TRIGGER, getPatientDataSaga);
    yield takeEvery(autoRefillToggleAutoRefillRxStatusRoutine.TRIGGER, toggleAutoRefillForRxSaga);
}

export default autoRefillSaga;
