import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { isRxOnHold, isRxOrderedInProgress } from 'util/prescription';

export const MedicineCabinetSelector = (state: RootState) => state.medicineCabinetReducer;

export const medicineCabinetPrescriptionsSelector = createSelector(MedicineCabinetSelector, (medicineCabinet) =>
    medicineCabinet.currentPrescriptions ? medicineCabinet.currentPrescriptions : []
);

export const medicineShowNewPrescriptionModalSelector = createSelector(
    MedicineCabinetSelector,
    (medicineCabinet) => medicineCabinet.showNewPrescriptionModal
);

export const medicineCabinetFilteredRxsForStatusSelector = createSelector(
    medicineCabinetPrescriptionsSelector,
    (currentPrescriptions) => {
        return currentPrescriptions.filter((rx) => !isRxOnHold(rx) && isRxOrderedInProgress(rx));
    }
);

export const medicineCabinetAutoRefillPlanEligibleSelector = createSelector(
    medicineCabinetPrescriptionsSelector,
    (currentPrescriptions) => {
        return currentPrescriptions.some((prescription) => prescription.autorefillPlanEligible);
    }
);

export const medicineCabinetShowToggleAutoRefillAllSelector = createSelector(
    [medicineCabinetAutoRefillPlanEligibleSelector, medicineCabinetPrescriptionsSelector],
    (autoRefillPlanEligible, currentPrescriptions) => {
        return autoRefillPlanEligible && currentPrescriptions.some((prescription) => prescription.autoRefillEligible);
    }
);

export const medicineCabinetAutoRefillRxsSelector = createSelector(
    medicineCabinetPrescriptionsSelector,
    (currentPrescriptions) => {
        const eligibleRxs = currentPrescriptions.filter((prescription) => prescription.autoRefillEligible);
        return eligibleRxs.map((rx) => rx.rxNumber);
    }
);

export const medicineCabinetAutoRefillToggleAllSelector = createSelector(
    medicineCabinetPrescriptionsSelector,
    (currentPrescriptions) => {
        const eligibleRxs = currentPrescriptions.filter((prescription) => prescription.autoRefillEligible);
        const rxsAutoRefillEnabled = eligibleRxs.filter((rx) => rx.autoRefillEnabled);
        return rxsAutoRefillEnabled.length === eligibleRxs.length;
    }
);

export const medicineCabinetAutoRefillToggleDisabledSelector = createSelector(
    [medicineCabinetAutoRefillPlanEligibleSelector, medicineCabinetPrescriptionsSelector],
    (autoRefillPlanEligible, currentPrescriptions) => {
        return autoRefillPlanEligible && currentPrescriptions.every((prescription) => !prescription.autoRefillEligible);
    }
);

export const medicineCabinetAutoRefillToggleBusySelector = createSelector(
    MedicineCabinetSelector,
    (medicineCabinet) => medicineCabinet.autoRefillToggleBusy
);

export const medicineCabinetPopulatedSelector = createSelector(
    MedicineCabinetSelector,
    (medicineCabinet) => medicineCabinet.medicineCabinetLoaded
);
