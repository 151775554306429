import React from 'react';

const Twitter = ({ label }: { label: string }) => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>{label}</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.8369 0.142578C31.8752 0.142578 40.8369 9.1043 40.8369 20.1426C40.8369 31.1809 31.8752 40.1426 20.8369 40.1426C9.79863 40.1426 0.836914 31.1809 0.836914 20.1426C0.836914 9.1043 9.79863 0.142578 20.8369 0.142578ZM17.3142 30.7799C26.1644 30.7799 31.0036 23.4463 31.0036 17.0906C31.0036 16.8811 31.0036 16.6716 30.9936 16.472C31.9314 15.7935 32.7496 14.9454 33.3982 13.9776C32.5401 14.3567 31.6122 14.6162 30.6343 14.7359C31.6321 14.1372 32.3904 13.1993 32.7496 12.0719C31.8217 12.6206 30.794 13.0197 29.6964 13.2392C28.8184 12.3013 27.5712 11.7227 26.1843 11.7227C23.5303 11.7227 21.3751 13.8778 21.3751 16.5319C21.3751 16.911 21.415 17.2802 21.5048 17.6294C17.5038 17.4298 13.9617 15.5141 11.5871 12.6006C11.1779 13.3091 10.9385 14.1372 10.9385 15.0152C10.9385 16.6815 11.7866 18.1582 13.0837 19.0162C12.2954 18.9963 11.5571 18.7768 10.9086 18.4177V18.4775C10.9086 20.8123 12.5648 22.7479 14.7699 23.197C14.3708 23.3066 13.9418 23.3666 13.5028 23.3666C13.1934 23.3666 12.8941 23.3366 12.5948 23.2767C13.2034 25.1924 14.9794 26.5794 17.0847 26.6192C15.4384 27.9064 13.3631 28.6747 11.1081 28.6747C10.7189 28.6747 10.3398 28.6547 9.96066 28.6048C12.066 29.9817 14.6003 30.7799 17.3142 30.7799Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Twitter;
