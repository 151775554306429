import AlertIcon from 'ui-kit/icons/alert/alert-icon';
import './empty-alert-box.style.scss';

interface EmptyAlertBoxProps {
    text: string;
}
const EmptyAlertBox = ({ text }: EmptyAlertBoxProps) => (
    <div className="empty-alert-box__header">
        <AlertIcon className={'header-icon-container'} />
        <p>{text}</p>
    </div>
);
export default EmptyAlertBox;
