import { FluidObject } from 'gatsby-image';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames';
import BackgroundImage from 'ui-kit/background-image/background-image';
import './IconList.style.scss';

export interface IconListProps {
    eyebrowText: string;
    title: string;
    subtitle: string;
    image: FluidObject;
    imageMobile: FluidObject;
    sectionIndex: number;
    children: React.ReactNode | React.ReactNode[];
}

export default function IconList({
    eyebrowText,
    title,
    subtitle,
    image,
    imageMobile,
    sectionIndex,
    children
}: IconListProps) {
    const iconListClasses = classNames('icon-list', { 'mobile-background': imageMobile });

    return (
        <div className={iconListClasses}>
            {image && <BackgroundImage image={image} className="header-background desktop" />}
            {imageMobile && <BackgroundImage image={imageMobile} className="header-background mobile" />}
            <Container fluid="xl">
                <Row className="justify-content-lg-center">
                    <Col xs={12} md={12} lg={8}>
                        <div className="icon-list__container">
                            <div className={'h6 icon-list__header__eyebrowText'}>{eyebrowText}</div>
                            {sectionIndex === 0 && <h1 className={'h2 icon-list__header__title'}>{title}</h1>}
                            {sectionIndex > 0 && <h2 className={'icon-list__header__title'}>{title}</h2>}
                            <div className={'spacer'}></div>
                            <div className={'h4 icon-list__header__subtitle col-md-6 my-0'}>{subtitle}</div>
                            <Row className={'icon-list__list-icons my-5 w-100'}>
                                {children &&
                                    Array.isArray(children) &&
                                    children.map((child, index) => (
                                        <Col
                                            xs={6}
                                            md={3}
                                            className="icon-list__icon-container"
                                            key={`icon-list-icon-${index}`}
                                        >
                                            {child}
                                        </Col>
                                    ))}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
