import React, { useEffect, useMemo } from 'react';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';

import Button from 'ui-kit/button/button';
import Text from 'ui-kit/text/text';

import { Payment, PaymentProps } from 'types/payment';
import { PAYMENT_SCHEMA } from 'schema/payment';
import FormSelect from 'ui-kit/form-select/form-select';
import Tooltip from 'ui-kit/tooltip/tooltip';
import { creditCardMonthOptions, getExpYears } from 'const/options';
import './_payment-item.style.scss';
import { PaymetricDetailsPayload, ProfileAddPaymentObjectPayload } from 'state/account/account.services';
import { useDispatch, useSelector } from 'react-redux';

import {
    accountAddPaymentRoutine,
    accountGetAllCreditCardsRoutine,
    accountGetPaymetricDetailsRoutine,
    accountGetTokenizedCardNumberRoutine
} from 'state/account/account.routines';
import { accountProfileSelector, paymetricDetailsSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

const getMonthNum = (month: string) => {
    return (creditCardMonthOptions.map((month) => month.value).indexOf(month) + 1).toString();
};

const detectCardType = (cardNumber: string) => {
    var re = {
        visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
        mastercard: /^5[1-5][0-9]{14}$/,
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        amex: /^3[47][0-9]{13}$/
    };

    for (var key in re) {
        if (re[key].test(parseInt(cardNumber))) {
            const cardKeys = Object.keys(re);
            return { key, index: (cardKeys.indexOf(key) + 1).toString() };
        }
    }
};

const PaymentFailureModalContent = ({ title, text }: { title: string; text: string }) => {
    return (
        <BirdiModalContent
            icon="alert"
            body={
                <Container fluid>
                    <Row>
                        <h1 className="w-100">{title}</h1>
                        <p className="mb-4 h4 w-100">{text}</p>
                    </Row>
                </Container>
            }
        />
    );
};

const PaymentForm = ({
    centerFormSubmit = false,
    instructions = 'pages.profile.payment.detailsHeader',
    isInModal = false,
    isInProfile = false,
    onCancel,
    showCancel = true,
    submitButtonText = 'pages.profile.payment.labels.savePayment',
    style
}: PaymentProps) => {
    const { t } = useTranslation();
    const formName = 'PaymentForm';
    const dispatch = useDispatch();
    const handlePaymentExit = () => {
        if (isInProfile) {
            // if component is in the profile
            navigate('/secure/profile/payment');
        }
        if (isInModal) {
            // if component is in a modal
            dispatch(closeModal({}));
            dispatch(accountGetPaymetricDetailsRoutine.trigger());
        }
    };

    const expYears = useMemo(() => getExpYears({ dates: 9 }), []);

    useEffect(() => {
        dispatch(accountGetPaymetricDetailsRoutine.trigger());
        dispatch(accountGetAllCreditCardsRoutine.trigger());
    }, []);
    const profileObject = useSelector(accountProfileSelector);
    const paymetricDetails = useSelector(paymetricDetailsSelector);
    const getMiddleNames = (names: []) => {
        if (names.length < 2) {
            return;
        } else {
            return names
                .map((name, index) => {
                    return index !== 0 && index !== names.length - 1 ? name : '';
                })
                .join(' ');
        }
    };

    const handleFormSubmit = async (
        values: ProfileAddPaymentObjectPayload,
        paymetricDetails: PaymetricDetailsPayload
    ) => {
        if (window !== undefined && window.$XIPlugin !== undefined && paymetricDetails !== undefined) {
            //Step 1 - Server side call to get the Access Token from Paymetric.
            const names = values.nameOnCard.split(' ');
            let cardNumber = values.cardNumber;
            let cardData = window.$XIPlugin.createJSRequestPacket(
                paymetricDetails?.MerchantId,
                paymetricDetails?.AccessToken
            );
            //Step 2 – call the Paymetric script method by sending card number.
            cardData.addField(window.$XIPlugin.createField('SecureCardNumber', true, cardNumber));
            window.$XIPlugin.ajax({
                //Step 3 – call the Paymetric POST by sending card number along with access token from step 1.
                url: paymetricDetails.ServiceUrl + 'Ajax',
                global: false,
                type: 'POST',
                data: cardData,
                success: function () {
                    dispatch(
                        accountGetTokenizedCardNumberRoutine.trigger({
                            onSuccess: (pciToken) => {
                                const creditCard = {
                                    cardActive: true,
                                    cardFirstName: `${names[0]}`,
                                    cardLastName: `${names[names.length - 1]}`,
                                    cardMiddleName: `${getMiddleNames(names)}`,
                                    defaultCard: false,
                                    epostPatientNum: profileObject?.epostPatientNum,
                                    cardMonthNum: getMonthNum(values.expMonth),
                                    cardMonthDesc: values.expMonth,
                                    cardNumber: pciToken,
                                    cardSeqNum: '-1',
                                    secureCardNumber: `**************${values.cardNumber.slice(-4)}`,
                                    cardTypeNum: `${detectCardType(values.cardNumber).index}`,
                                    cardType: `${detectCardType(values.cardNumber).key}`,
                                    cardYear: values.expYear.toString(),
                                    pciToken: pciToken,
                                    cardExpiration: `${getMonthNum(values.expMonth)}/${values.expYear.toString()}`,
                                    cardName: values.nameOnCard
                                };
                                dispatch(
                                    accountAddPaymentRoutine({
                                        ...creditCard,
                                        onSuccess: () => {
                                            handlePaymentExit();
                                        }
                                    })
                                );
                            },
                            onFailure: () => {
                                dispatch(
                                    openModal({
                                        showClose: false,
                                        onClose: () => {
                                            handlePaymentExit();
                                        },
                                        bodyContent: (
                                            <PaymentFailureModalContent
                                                title={t('modals.addPaymentModal.error')}
                                                text={t('modals.addPaymentModal.followUp')}
                                            />
                                        ),
                                        ctas: [
                                            {
                                                label: t('modals.addPaymentModal.labels.gotIt'),
                                                variant: 'primary',
                                                onClick: () => {
                                                    dispatch(closeModal({}));
                                                    handlePaymentExit();
                                                }
                                            },
                                            {
                                                label: t('modals.addPaymentModal.labels.tryAgain'),
                                                variant: 'primary',
                                                onClick: () => {
                                                    dispatch(closeModal({}));
                                                    navigate('/secure/profile/payment/add-new-payment');
                                                },
                                                className: 'mt-4'
                                            }
                                        ]
                                    })
                                );
                            }
                        })
                    );
                },
                error: function (response) {
                    dispatch(
                        openModal({
                            showClose: false,
                            onClose: () => {
                                handlePaymentExit();
                            },
                            bodyContent: (
                                <PaymentFailureModalContent
                                    title={t('modals.addPaymentModal.error')}
                                    text={t('modals.addPaymentModal.followUp')}
                                />
                            ),
                            ctas: [
                                {
                                    label: t('modals.addPaymentModal.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                        handlePaymentExit();
                                    }
                                }
                            ]
                        })
                    );
                }
            });
        }
    }; // end formSubmit

    const handleFormCancel = () => {
        if (onCancel) {
            onCancel();
        } else {
            navigate('/secure/profile/payment');
        }
    };

    return (
        <div style={style} data-ga-location="Payment">
            <Row>
                <Col className="d-flex flex-column">
                    <Formik<Partial<Payment>>
                        onSubmit={(values) => {
                            handleFormSubmit(values, paymetricDetails);
                        }}
                        validationSchema={PAYMENT_SCHEMA}
                        initialValues={{
                            cardNumber: '',
                            nameOnCard: '',
                            expMonth: '',
                            expYear: '',
                            cvcNumber: ''
                        }}
                    >
                        {(formik: any) => (
                            <Form
                                id="payment-form"
                                data-ga-form-name={formName}
                                onSubmit={formik.handleSubmit}
                                autoComplete="off"
                            >
                                <p className="profile-form-instructions text-left">{t(instructions)}</p>
                                <Row>
                                    <Col>
                                        <Text
                                            label={t('pages.profile.payment.labels.cardNumber')}
                                            name="cardNumber"
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.cardNumber
                                                    ? formik.values.cardNumber === ''
                                                        ? t('forms.errorMessages.requiredField', {
                                                              label: t('pages.profile.payment.labels.cardNumber')
                                                          })
                                                        : t('forms.errorMessages.invalidCard')
                                                    : undefined
                                            }
                                            touched={formik.touched.cardNumber}
                                            value={formik.values?.cardNumber}
                                            defaultValue={formik.initialValues?.cardNumber}
                                            maxLength={19}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Text
                                            label={t('pages.profile.payment.labels.nameOnCard')}
                                            name="nameOnCard"
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.nameOnCard
                                                    ? formik.values.nameOnCard === ''
                                                        ? t('forms.errorMessages.requiredField', {
                                                              label: t('pages.profile.payment.labels.nameOnCard')
                                                          })
                                                        : t('forms.errorMessages.invalidName')
                                                    : undefined
                                            }
                                            touched={formik.touched.nameOnCard}
                                            value={formik.values?.nameOnCard}
                                            defaultValue={formik.initialValues?.nameOnCard}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={4}>
                                        <Field
                                            name="expMonth"
                                            component={FormSelect}
                                            options={creditCardMonthOptions}
                                            placeholder={t('pages.profile.payment.labels.expMonth')}
                                            errors={
                                                formik.errors?.expMonth
                                                    ? formik.values.expMonth === ''
                                                        ? t('forms.errorMessages.requiredField', {
                                                              label: t('pages.profile.payment.labels.expMonth')
                                                          })
                                                        : t('forms.errorMessages.invalidMonth')
                                                    : undefined
                                            }
                                            touched={formik.touched.expMonth}
                                        />
                                    </Col>
                                    <Col xs={12} md={12} lg={4}>
                                        <Field
                                            name="expYear"
                                            component={FormSelect}
                                            options={expYears}
                                            placeholder={t('pages.profile.payment.labels.expYear')}
                                            errors={
                                                formik.errors?.expYear
                                                    ? t('forms.errorMessages.requiredField', {
                                                          label: t('pages.profile.payment.labels.expYear')
                                                      })
                                                    : undefined
                                            }
                                            touched={formik.touched.expYear}
                                        />
                                    </Col>
                                    <Col xs={12} md={12} lg={4}>
                                        <Text
                                            label={t('pages.profile.payment.labels.cvcNumber')}
                                            name="cvcNumber"
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.cvcNumber
                                                    ? formik.values.cvcNumber === ''
                                                        ? t('forms.errorMessages.requiredField', {
                                                              label: t('pages.profile.payment.labels.cvcNumber')
                                                          })
                                                        : t('forms.errorMessages.invalidCVC')
                                                    : undefined
                                            }
                                            touched={formik.touched.cvcNumber}
                                            value={formik.values?.cvcNumber}
                                            defaultValue={formik.initialValues?.cvcNumber}
                                            maxLength={4}
                                        />
                                        <div className="tooltip-wrapper">
                                            <Tooltip tip={t('pages.profile.payment.labels.cvcNumberToolTip')} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row
                                    className={`d-flex mt-3 justify-content-between ${
                                        centerFormSubmit && 'flex-column'
                                    }`}
                                >
                                    <Col className={`${centerFormSubmit ? 'text-center' : ''}`}>
                                        <Button
                                            className="sm-full md-full"
                                            label={t(submitButtonText)}
                                            variant="primary"
                                            type="submit"
                                            disabled={!formik.dirty}
                                            onClick={formik.handleSubmit}
                                            dataGAFormName={formName}
                                        />
                                    </Col>
                                    {showCancel && (
                                        <Col
                                            className={`${
                                                !centerFormSubmit
                                                    ? 'd-flex justify-content-end mt-0'
                                                    : 'mt-4 text-center'
                                            }`}
                                        >
                                            <Button
                                                className="sm-full"
                                                label={t('pages.profile.payment.labels.cancel')}
                                                variant="text"
                                                type="button"
                                                onClick={handleFormCancel}
                                                dataGAFormName={formName}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
            {paymetricDetails?.ScriptUrl ? (
                <Helmet>
                    <script id="service-url" src={paymetricDetails?.ScriptUrl} />
                </Helmet>
            ) : (
                <></>
            )}
        </div>
    );
};

export default PaymentForm;
