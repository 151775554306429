import * as yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const validatePhoneNumber = (
    phoneNumber: string | undefined,
    context: yup.TestContext<Record<string, any>>,
    countryCode: 'US' | 'CA'
): boolean | yup.ValidationError => {
    const { createError } = context;
    const error = createError({ message: 'phoneNumber' });

    if (!phoneNumber) return error;

    const parsedNumber = parsePhoneNumberFromString(phoneNumber, countryCode);

    if (!parsedNumber?.isValid()) return error;

    return true;
};
