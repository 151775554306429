import React, { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
    NavPath,
    MenuProps,
    HeaderLogo,
    MobileToggleMap,
    MobileCloseConfig
} from 'components/navigation/navigation.props';

import CloseIcon from 'ui-kit/icons/close-icon/close-icon';
import Logo from 'ui-kit/logo/logo';
import EvioLogo from 'ui-kit/logo/evio-logo/evio-logo';
import Link from 'ui-kit/link/link';
import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';
import MobileToggle from 'ui-kit/mobile-toggle/mobile-toggle';
import EvioMobileToggle from 'ui-kit/mobile-toggle/evio';
import PillLogo from 'ui-kit/logo/pill-logo/pill-logo';
import Button from 'ui-kit/button/button';

import './mobile-menu.style.scss';
import 'ui-kit/utils/scroll-lock/scroll-lock.style.scss';

import {
    IntraPageMenuItemProps,
    IntraPageMenuItemContentValue
} from 'components/navigation/intra-page-menu/intra-page-menu.props';
import { useGlobalLink } from 'hooks/useGlobalLink';
import useSiteClient from 'hooks/useSiteClient';

const MobileMenu = ({ isScrolled, paths, userName, profileMenu, isLoggedIn, cartItemsTotal }: MenuProps) => {
    const [navPaths, setNavPaths] = useState<NavPath[] | undefined>();
    const [cartTotal, setCartTotal] = useState(cartItemsTotal);
    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
    const toggleMobileMenuVisibility = () => {
        document.body.classList.toggle('scroll-lock');
        setIsMobileMenuVisible(!isMobileMenuVisible);
    };
    const { t } = useTranslation();

    useEffect(() => {
        setNavPaths(paths);
    }, [paths, isLoggedIn]);

    useEffect(() => {
        setCartTotal(cartItemsTotal);
    }, [cartItemsTotal]);

    const globalLink = useGlobalLink();
    const { clientId, isEvio } = useSiteClient();
    const { trackFormAbandonment, handleSignOut } = globalLink;

    const mobileHeaderLogo: HeaderLogo = {
        birdi: { default: <Logo variant="primary" />, isScrolled: <PillLogo variant="primary" /> },
        evio: { default: <EvioLogo variant="light" />, isScrolled: <EvioLogo variant="light" /> }
    };

    const mobileToggle: MobileToggleMap = {
        birdi: (
            <MobileToggle
                variant="primary"
                onClick={() => toggleMobileMenuVisibility()}
                style={{ height: '2.625rem', width: '2.625rem' }}
            />
        ),
        evio: (
            <EvioMobileToggle
                onClick={() => toggleMobileMenuVisibility()}
                style={{ height: '2.125rem', width: '2.125rem' }}
            />
        )
    };

    const mobileCloseConfig: MobileCloseConfig = {
        birdi: '3rem',
        evio: '2.125rem'
    };

    return (
        <Container
            fluid
            className={`mobile-menu d-lg-none justify-content-center ${
                isMobileMenuVisible ? 'menu-open' : 'menu-closed'
            }`}
        >
            <Row className="w-100">
                <Col xs={8} sm={8} md={8} lg={8} className="d-flex justify-content-start mobile-header-logo">
                    <Link
                        onClick={trackFormAbandonment}
                        to={'/secure/medicine-cabinet'}
                        label={
                            <>
                                <div className="co-brand-logo d-flex"></div>
                                {isScrolled
                                    ? mobileHeaderLogo[clientId].isScrolled
                                    : mobileHeaderLogo[clientId].default}
                            </>
                        }
                        variant="site"
                        dataGALocation="HeaderLogo"
                        ariaLabel="Birdi Homepage"
                    />
                </Col>
                <Col xs={4} sm={4} md={4} lg={4} className="d-flex justify-content-end align-items-center">
                    {navPaths?.length > 0 && mobileToggle[clientId]}
                </Col>
            </Row>
            <div className="menu">
                <div className="menu-header">
                    <div className="d-flex justify-content-start">
                        <div className="co-brand-logo d-flex"></div>
                        {isEvio ? <EvioLogo variant="light" /> : <Logo variant="light" />}
                    </div>
                    <div className="d-flex justify-content-end">
                        <CloseIcon
                            onClick={() => toggleMobileMenuVisibility()}
                            style={{ height: mobileCloseConfig[clientId], width: mobileCloseConfig[clientId] }}
                        />
                    </div>
                </div>
                <div className="menu-links">
                    {navPaths &&
                        navPaths.map(({ link, label, isButton }: NavPath) => (
                            <React.Fragment key={`site-mobile-nav-${link?.url}-${label}`}>
                                {!isButton && <div className="menu-spacer" />}
                                <Link
                                    className={Boolean(isButton) === true ? 'w-100' : ''}
                                    onClick={trackFormAbandonment}
                                    to={link?.url}
                                    label={label}
                                    variant={Boolean(isButton) === true ? 'nav-button' : 'nav'}
                                    dataGALocation="HeaderNav"
                                />
                            </React.Fragment>
                        ))}
                    {profileMenu && (
                        <>
                            <Link
                                onClick={trackFormAbandonment}
                                to={'/secure/cart/review/'}
                                label={
                                    <>
                                        <CartIcon
                                            className={'position-absolute border-cathams-blue'}
                                            itemCount={cartTotal}
                                            style={{ left: '0' }}
                                        />
                                        <small>{t('menus.cart.title')}</small>
                                    </>
                                }
                                variant={'nav'}
                                dataGALocation="HeaderNav"
                                className="btn btn-outline-smoke mt-4 px-4 text-center w-100"
                            />

                            <div className="menu-links-profile">
                                <div className="h5 user-name">{userName}</div>
                                {profileMenu.map(
                                    ({ key, value, label }: IntraPageMenuItemProps<IntraPageMenuItemContentValue>) =>
                                        value && (
                                            <Link
                                                onClick={trackFormAbandonment}
                                                key={`site-mobile-nav-profile-${key}`}
                                                to={value.toString()}
                                                label={label}
                                                variant={'nav'}
                                                dataGALocation="HeaderNav"
                                            />
                                        )
                                )}
                                <div className="link" data-variant="nav">
                                    <Button
                                        variant="text"
                                        type="button"
                                        onClick={handleSignOut}
                                        label={t('menus.intraPage.profile.signOut')}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Container>
    );
};

export default MobileMenu;
