import React, { MutableRefObject } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { noop } from 'util/function';
import Button from 'ui-kit/button/button';
import { ButtonProps } from 'ui-kit/button/button.props';
import CreditCardIcon from 'ui-kit/icons/creditcard-icon/creditcard-icon';
import { ellipsify, lowercaseAndCapitalize } from 'util/cart';
import './payment-item.style.scss';

type HTMLInputChange = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
>['onChange'];

export interface PaymentItemProps {
    index: number;
    cardHolder: string;
    cardType: string;
    endingDigits: string;
    expiryMonth: string;
    expiryYear: string;
    isDefaultCard?: boolean;
    isSelectCardRadioInputChecked?: boolean;
    onSelectCardRadioInputChange?: HTMLInputChange;
    onSetDefaultClick?: ButtonProps['onClick'];
    onShowPaymentMethods?: ButtonProps['onClick'];
    showSelectCardRadioInput?: boolean;
    showSetDefaultLink?: boolean;
    isInnerWidthViewport?: MutableRefObject<boolean>;
}

const PaymentItem: React.FC<PaymentItemProps> = (props) => {
    const {
        index,
        cardHolder,
        cardType,
        endingDigits,
        expiryMonth,
        expiryYear,
        isDefaultCard,
        isSelectCardRadioInputChecked,
        onSelectCardRadioInputChange = noop,
        onSetDefaultClick = noop,
        showSelectCardRadioInput = false,
        showSetDefaultLink = false,
        isInnerWidthViewport
    } = props;

    const { t } = useTranslation();

    const handleSelectCardRadioInputClick = React.useCallback<NonNullable<HTMLInputChange>>(
        (event) => {
            event.currentTarget.blur();
            onSelectCardRadioInputChange(event);
        },
        [onSelectCardRadioInputChange]
    );

    const formattedExpiryMonth = parseInt(expiryMonth) < 10 ? `0${expiryMonth}` : expiryMonth;

    const showActionLinkSection = showSetDefaultLink;

    return (
        <section className="payment-item my-3">
            <div className="py-4">
                {showSelectCardRadioInput && (
                    <input
                        type="radio"
                        name="option"
                        id={`payment-${index}`}
                        checked={isSelectCardRadioInputChecked}
                        onChange={handleSelectCardRadioInputClick}
                    />
                )}
            </div>

            <div className="w-100">
                <div className="d-grid gap-2 w-100">
                    {showSetDefaultLink && (
                        <span className="cart-type">
                            {t(
                                `components.medicineCabinetCart.paymentMethod.${
                                    isDefaultCard ? 'primaryPayment' : 'secondaryPayment'
                                }`
                            )}
                        </span>
                    )}

                    <div className="card-info">
                        <div className="content">
                            <p>{`${
                                isInnerWidthViewport?.current ? ellipsify(lowercaseAndCapitalize(cardType)) : cardType
                            } ···· ${endingDigits}`}</p>
                            <p>{cardHolder}</p>
                            <p>
                                {t('components.medicineCabinetCart.paymentMethod.expiry', {
                                    month: formattedExpiryMonth,
                                    year: expiryYear
                                })}
                            </p>
                        </div>

                        <CreditCardIcon className="payment-card__credit-card-icon" variant={cardType} />
                    </div>

                    {showActionLinkSection && (
                        <div>
                            {showSetDefaultLink && !isDefaultCard && (
                                <Button
                                    className="px-0 pt-0 text-cerulean no-min-width"
                                    dataGAFormName="address-card"
                                    label={t('components.medicineCabinetCart.paymentMethod.labels.setAsDefault')}
                                    onClick={onSetDefaultClick}
                                    type="button"
                                    variant="text"
                                />
                            )}

                            {showSetDefaultLink && isDefaultCard && (
                                <p className="default-text">
                                    {t('components.medicineCabinetCart.paymentMethod.labels.defaultPayment')}
                                </p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default PaymentItem;
