import { ReactElement, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, Row, Col } from 'react-bootstrap';

import { BASIC_AUTH_SCHEMA } from 'schema/basic-auth.schema';
import { BasicAuth } from 'types/basic-auth';

import Button from 'ui-kit/button/button';
import Text from 'ui-kit/text/text';
import PasswordText from 'ui-kit/password-text/password-text';

import { InitOptimize } from 'util/google_optimize/optimize_helper';

import './basic-auth-sign-in-form.style.scss';

//
// --- Constants ---

const FORM_NAME = 'BasicAuthSignInForm';

//
// --- Types ---

export interface BasicAuthSignInFormProps {
    onSubmit: (login: BasicAuth) => void;
    onBasicAuthAttemptFailedChange: (attemptFailed: boolean) => void;
    basicAuthAttemptFailed: boolean;
}

//
// --- BasicAuthSignInForm Component ---

const BasicAuthSignInForm = ({
    onSubmit,
    onBasicAuthAttemptFailedChange,
    basicAuthAttemptFailed
}: BasicAuthSignInFormProps): ReactElement => {
    const { t } = useTranslation();

    useEffect(() => {
        InitOptimize();
    }, []);

    return (
        <Container className="basic-auth-sign-in-form text-center">
            <div className={`wrapper ${basicAuthAttemptFailed ? 'failed-attempt' : ''}`}>
                {basicAuthAttemptFailed ? (
                    <Row>
                        <Col>
                            <h1>{t('basicAuth.signInFail.title')}</h1>
                            <p>{t('basicAuth.signInFail.body')}</p>
                            <Button
                                async
                                className="sm-full mw-100"
                                label={t('basicAuth.signInFail.labels.button')}
                                variant={basicAuthAttemptFailed ? 'danger' : 'primary'}
                                type="button"
                                onClick={() => onBasicAuthAttemptFailedChange(false)}
                            />
                        </Col>
                    </Row>
                ) : (
                    <>
                        <Row>
                            <Col>
                                <h1>{t('basicAuth.signIn.title')}</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Formik<BasicAuth>
                                    onSubmit={onSubmit}
                                    validationSchema={BASIC_AUTH_SCHEMA}
                                    initialValues={{
                                        username: '',
                                        password: ''
                                    }}
                                >
                                    {(formik: any) => (
                                        <Form
                                            id="sign-in-form"
                                            data-ga-form-name={FORM_NAME}
                                            onSubmit={formik.handleSubmit}
                                            autoComplete="off"
                                        >
                                            <Text
                                                label={t('basicAuth.signIn.labels.username')}
                                                name="username"
                                                maxLength={255}
                                                onChange={formik.handleChange}
                                                errors={formik.errors?.username ? t('signIn.errors.email') : undefined}
                                                touched={formik.touched.username}
                                                value={formik.values?.username}
                                                autocomplete="off"
                                            />
                                            <PasswordText
                                                label={t('basicAuth.signIn.labels.password')}
                                                name="password"
                                                type="password"
                                                onChange={formik.handleChange}
                                                errors={
                                                    formik.errors?.password ? t('signIn.errors.password') : undefined
                                                }
                                                touched={formik.touched.password}
                                                value={formik.values?.password}
                                                autocomplete="off"
                                            />
                                            <div className="d-flex flex-column align-items-center">
                                                <Button
                                                    async
                                                    className="sm-full mw-100"
                                                    label={t('basicAuth.signIn.labels.submit')}
                                                    type="submit"
                                                    dataGAFormName={FORM_NAME}
                                                />
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Col>
                        </Row>
                    </>
                )}
            </div>
        </Container>
    );
};

export default BasicAuthSignInForm;
