import React from 'react';
import useSiteClient from 'hooks/useSiteClient';

import { CloseIconProps } from 'ui-kit/icons/close-icon/close-icon.props';

import './close-icon.style.scss';

interface CloseIconSVGProps {
    fill: string;
    stroke: string;
    strokeWidth: string;
}
interface CloseIconConfigProps {
    [key: string]: {
        ellipse: CloseIconSVGProps;
        path: CloseIconSVGProps;
    };
}

const CloseIcon = ({ onClick, style }: CloseIconProps) => {
    const { clientId } = useSiteClient();
    const closeIconConfig: CloseIconConfigProps = {
        birdi: {
            ellipse: { fill: 'none', stroke: '#FFFFFF', strokeWidth: '2.3' },
            path: { fill: 'none', stroke: '#FFFFFF', strokeWidth: '3.75' }
        },
        evio: {
            ellipse: { fill: 'none', stroke: '#FFFFFF', strokeWidth: '2.3' },
            path: { fill: 'none', stroke: '#4B3F72', strokeWidth: '2.3' }
        }
    };

    return (
        <div className="close-icon-container" style={style}>
            <svg
                className="close-icon"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="100%"
                height="100%"
                viewBox="0 0 30 30"
                onClick={onClick ?? undefined}
            >
                <g>
                    <ellipse {...closeIconConfig[clientId].ellipse} cx="15" cy="15" rx="13.8" ry="13.8" />
                    <g>
                        <path
                            {...closeIconConfig[clientId].path}
                            strokeLinecap="round"
                            strokeLinejoin="miter"
                            d="M11 11.2l8 7.6"
                        />
                        <path
                            {...closeIconConfig[clientId].path}
                            strokeLinecap="round"
                            strokeLinejoin="miter"
                            d="M19 11.2l-8 7.6"
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default CloseIcon;
