import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'state/store';

export const autoRefillSelector = (state: RootState) => state.autoRefillReducer;

export const autoRefillUserVerificationFailedSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.userVerified !== 'UserVerified';
});

export const autoRefillUserVerifiedSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.userVerified === 'UserVerified';
});

export const autoRefillSecurityTokenSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.securityToken;
});

export const autoRefillSecretKeyHashSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.secretKeyHash;
});

export const autoRefillErrorMessageSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.error.messageText ?? '';
});

export const autoRefillUserBearerTokenSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.bearerToken;
});

export const autoRefillFirstNameSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.firstName;
});

export const autoRefillLoadingRxsSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.loadingPrescriptions;
});

export const autoRefillRxsSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.rxResults;
});

export const autoRefillRxsLoadedSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.prescriptionsLoaded;
});

export const autoRefillOrderInvoiceNumberSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.orderInvoiceNumber;
});

export const autoRefillFlagsSelector = createSelector(autoRefillSelector, (autoRefill) => autoRefill.autoRefillFlags);

export const autoRefillEligibleRxsSelector = createSelector(autoRefillSelector, (autoRefill) => {
    const eligibleRxs = autoRefill.autoRefillFlags.filter((rx) => rx.autoRefillEligible).map((rx) => rx.rxNumber);
    return autoRefill.rxResults.filter((rx) => eligibleRxs.includes(rx.rxNumber));
});

export const autoRefillEligibleNotEnabledRxsSelector = createSelector(autoRefillSelector, (autoRefill) => {
    const eligibleRxs = autoRefill.autoRefillFlags
        .filter((rx) => rx.autoRefillEligible && !rx.autoRefillEnabled)
        .map((rx) => rx.rxNumber);
    return autoRefill.rxResults.filter((rx) => eligibleRxs.includes(rx.rxNumber));
});

export const autoRefillAllEnabledSelector = createSelector(autoRefillFlagsSelector, (autoRefillFlags) => {
    const eligibleRxs = autoRefillFlags.filter((rx) => rx.autoRefillEligible);
    return eligibleRxs.every((rx) => rx.autoRefillEnabled);
});

export const autoRefillToggleRxBusySelector = createSelector(autoRefillSelector, (autoRefill) => autoRefill.isBusy);

export const autoRefillAccountHasInsuranceSelector = createSelector(
    autoRefillSelector,
    (autoRefill) => autoRefill.planAlias !== 'BRD01'
);
