import { PharmacySearchItem } from 'types/pharmacy';

export const capitalizeFirstLetter = (s: string): string => {
    if (s) {
        return s.charAt(0).toUpperCase() + s.slice(1);
    } else {
        return '';
    }
};

/** capitalize first letter of each word of the string */
export const convertToTitleCase = (s: string): string => {
    if (s) {
        var splitStr = s.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }

        // Directly return the joined string
        return splitStr.join(' ');
    } else {
        return '';
    }
};

export const formatPhoneNumber = (f: string | null): string => {
    if (f) {
        const f_val = f.replace(/\D/g, '').substring(-10);
        return f_val.slice(0, 3) + '-' + f_val.slice(3, 6) + '-' + f_val.slice(6);
    } else {
        return '';
    }
};

export const CSSstring = (styles: string): object | undefined => {
    try {
        const css_json = `{"${styles.replace(/; /g, '", "').replace(/: /g, '": "').replace(';', '')}"}`;
        const obj = JSON.parse(css_json);
        const keyValues = Object.keys(obj).map((key) => {
            var camelCased = key.replace(/-[a-z]/g, (g) => g[1].toUpperCase());
            return { [camelCased]: obj[key] };
        });
        return Object.assign({}, ...keyValues);
    } catch (e) {
        return undefined;
    }
};

export const buildPharmacyAddressString = (pharmacy: PharmacySearchItem): string => {
    const { PhysicalLocationZipCode: originalZipCode } = pharmacy;

    // some zip codes come in format "123456789", convert to "12345-6789"
    const zip5 = originalZipCode.length > 5 ? originalZipCode.slice(0, 5) : originalZipCode;
    const zip4 = originalZipCode.length > 5 ? originalZipCode.slice(-4) : '';
    const zipCode = zip4 ? `${zip5}-${zip4}` : zip5;

    const addressPieces = [
        pharmacy.PhysicalLocationAddress1,
        pharmacy.PhysicalLocationAddress2,
        pharmacy.PhysicalLocationCity,
        `${pharmacy.PhysicalLocationStateCode} ${zipCode}`
    ];

    return addressPieces.filter((addressPiece) => !!addressPiece).join(', ');
};

export const padStartOfString = (stringToPad: string, padString: string, numberOfPadding: number) => {
    return stringToPad.toString().padStart(numberOfPadding, padString);
};

export const replaceStringWith = (stringToSearch: string, searchChar: RegExp, replaceChar: string) => {
    return stringToSearch.replace(searchChar, replaceChar);
};

export const maskPhoneNumber = (phoneNumber: string): string => {
    const allNumbers = phoneNumber.replace(/\D/g, '');
    return `(***) ***-${allNumbers.slice(allNumbers.length - 4)}`;
};

export const formatBirthDate = (year: string, month: string, day: string) => {
    return `${padStartOfString(month, '0', 2)}/${padStartOfString(day, '0', 2)}/${year}`;
};

export const removeHtmlTagsFromString = (val: string) => {
    return val.replace(/(<([^>]+)>)/gi, '');
};
