import { useDispatch } from 'react-redux';
import { FormikHelpers } from 'formik';
import { formatPrice } from 'schema/price.schema';
import { DrugDiscountPricePayload, DrugDescriptionObjectPayload } from 'state/drug/drug.services';
import { drugDiscountPriceRoutine, drugDescriptionRoutine } from 'state/drug/drug.routines';
import { PrescriptionObjectPayload } from 'state/medicine-cabinet/medicine-cabinet.services';
import { PrescriptionTypes } from 'types/prescription';

const useBirdiPrice = () => {
    const dispatch = useDispatch();

    const getPrice = (
        values: Partial<PrescriptionTypes>,
        actions: FormikHelpers<Partial<PrescriptionTypes>>,
        handlePriceSuccess: Function,
        handleDescriptionSuccess: Function
    ) => {
        // Convert values into a PrescriptionObjectPayload object, since that is what it expects
        const prescription: Partial<PrescriptionObjectPayload> = {
            dispensedProductNumber: values.ndc,
            fillQuantity: values.qty,
            fillDaysSupply: ''
        };

        // Fetch the drug price.
        dispatch(
            drugDiscountPriceRoutine.trigger({
                prescriptions: [prescription],
                onSuccess: (drugPriceResponse: DrugDiscountPricePayload) => {
                    // Update the submitting state of the prescription info form
                    // in order to reset the loading state of the submit button.
                    actions && actions.setSubmitting(false);

                    // If the price is not included in the response or is an empty string, then set the value to "NA".
                    const priceValue = drugPriceResponse.response?.prescriptionResult[0]?.pricingInformation?.memberCost
                        ?.patientPayAmount
                        ? formatPrice(
                              drugPriceResponse.response?.prescriptionResult[0].pricingInformation.memberCost
                                  .patientPayAmount
                          )
                        : 'NA';
                    // need to provide successHandler to set showPrice, SelectedDrug and scrallTo
                    handlePriceSuccess && handlePriceSuccess(priceValue);
                },
                onFailure: () => {
                    // On failure, we still scroll down, but we show an error message.
                    actions && actions.setSubmitting(false);
                    handlePriceSuccess && handlePriceSuccess();
                }
            })
        );

        // Fetch the drug description.
        if (values.gpi) {
            dispatch(
                drugDescriptionRoutine.trigger({
                    gpi: values.gpi, //selectedGpi,
                    onSuccess: (response: DrugDescriptionObjectPayload) => {
                        handleDescriptionSuccess && handleDescriptionSuccess(response);
                    },
                    onFailure: () => {
                        handleDescriptionSuccess && handleDescriptionSuccess();
                    }
                })
            );
        }
    };

    return { getPrice };
};

export default useBirdiPrice;
