import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    DrugLookupObjectPayload,
    DrugDetailsObjectPayload,
    DrugDiscountPricePayload,
    DrugDescriptionObjectPayload,
    DrugListObjectPayload
} from './drug.services';
import { produce } from 'immer';
import {
    drugListRoutine,
    drugLookupRoutine,
    drugDetailsLookupRoutine,
    drugFormLookupRoutine,
    drugDiscountPriceRoutine,
    drugDescriptionRoutine
} from './drug.routines';

type LookupStatus = 'BUSY' | 'FETCHED_DRUGS' | 'FETCHED_DETAILS' | 'FETCHED_PHOTO' | 'IDLE' | 'FAILED';

export interface DrugWithDiscountPrice {
    price: string;
    awpPrice: string;
    rxNumber: string;
}

export interface DrugState {
    drugLookupResults?: DrugLookupObjectPayload[];
    drugDetails: DrugDetailsObjectPayload;
    showDrugList: boolean;
    drugLookupStatus: LookupStatus;
    drugDiscountPrices: DrugWithDiscountPrice[];
    drugDescription: string;
    formularies?: DrugListObjectPayload[];
}

export const initialState: DrugState = {
    drugLookupResults: [],
    drugDetails: { drugName: '', dosageForms: [] },
    showDrugList: false,
    drugLookupStatus: 'IDLE',
    drugDiscountPrices: [],
    drugDescription: '',
    formularies: []
};

const drugSlice = createSlice({
    name: 'drug',
    initialState,
    reducers: {
        setDrugListVisibility(state, action: PayloadAction<boolean>) {
            state.showDrugList = action.payload;
        },
        setDrugLookupStatus(state, action: PayloadAction<LookupStatus>) {
            state.drugLookupStatus = action.payload;
        },
        resetDrugLookupResults(state) {
            state.drugLookupResults = initialState.drugLookupResults;
        },
        resetDrugDescription(state) {
            state.drugDescription = initialState.drugDescription;
        },
        resetDrugFormulary(state) {
            state.formularies = initialState.formularies;
        }
    },
    extraReducers: ({ addCase }) => {
        /**
         * Drug reducers
         */
        addCase(drugListRoutine.SUCCESS, (state, { payload }: PayloadAction<DrugListObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.formularies?.push(payload);
                draftState.showDrugList = true;
                draftState.drugLookupStatus = 'FETCHED_DRUGS';
            })
        );
        addCase(drugListRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.formularies = initialState.formularies;
                draftState.showDrugList = false;
                draftState.drugLookupStatus = 'IDLE';
            })
        );
        addCase(drugLookupRoutine.SUCCESS, (state, { payload }: PayloadAction<DrugLookupObjectPayload[]>) =>
            produce(state, (draftState) => {
                draftState.drugLookupResults = payload;
                draftState.showDrugList = true;
                draftState.drugLookupStatus = 'FETCHED_DRUGS';
            })
        );
        addCase(drugLookupRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.drugLookupResults = [];
                draftState.showDrugList = false;
            })
        );
        addCase(drugDetailsLookupRoutine.SUCCESS, (state, { payload }: PayloadAction<DrugDetailsObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.drugDetails = payload;
                draftState.drugLookupStatus = 'FETCHED_DETAILS';
            })
        );
        addCase(drugDetailsLookupRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.drugDetails = { drugName: '', dosageForms: [] };
                draftState.drugLookupStatus = 'IDLE';
            })
        );
        addCase(drugFormLookupRoutine.SUCCESS, (state, { payload }: PayloadAction<DrugDetailsObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.drugDetails = payload;
                draftState.drugLookupStatus = 'FETCHED_DETAILS';
            })
        );
        addCase(drugFormLookupRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.drugDetails = { drugName: '', dosageForms: [] };
                draftState.drugLookupStatus = 'IDLE';
            })
        );
        addCase(drugDiscountPriceRoutine.SUCCESS, (state, { payload }: PayloadAction<DrugDiscountPricePayload>) => {
            return produce(state, (draftState) => {
                // If the price is not included in the response or is an empty string, then set the value to "NA".
                const priceValue = payload.response?.prescriptionResult[0]?.pricingInformation?.memberCost
                    ?.patientPayAmount
                    ? payload.response.prescriptionResult[0].pricingInformation.memberCost.patientPayAmount
                    : 'NA'; // '8.25';

                const awpPriceValue = payload.response?.prescriptionResult[0]?.pricingInformation?.awpPrice
                    ? payload.response.prescriptionResult[0].pricingInformation.awpPrice
                    : 'NA'; // '400.00';

                // Check if the price already exists for the given prescription number.
                let existingIndex = draftState.drugDiscountPrices.findIndex((item) => {
                    return item.rxNumber === payload.rxNumber;
                });

                // Update the existing price or add the new price to the array.
                if (existingIndex > -1) {
                    // Only update the price if one was actually returned by the API.
                    if (priceValue !== 'NA') {
                        draftState.drugDiscountPrices[existingIndex].price = priceValue;
                    }
                } else {
                    draftState.drugDiscountPrices.push({
                        price: priceValue,
                        awpPrice: awpPriceValue,
                        rxNumber: payload.rxNumber
                    });
                }
            });
        });
        addCase(drugDiscountPriceRoutine.FAILURE, (state, { payload }: PayloadAction<string>) => {
            return produce(state, (draftState) => {
                // Set the price to "NA" for this prescription number.
                draftState.drugDiscountPrices.push({
                    price: 'NA',
                    awpPrice: 'NA',
                    rxNumber: payload
                });
            });
        });
        addCase(drugDescriptionRoutine.SUCCESS, (state, { payload }: PayloadAction<DrugDescriptionObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.drugDescription = payload.htmlDesc;
            })
        );
        addCase(drugDescriptionRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.drugDescription = '';
            })
        );
    }
});

export const { setDrugListVisibility, setDrugLookupStatus, resetDrugLookupResults, resetDrugDescription } =
    drugSlice.actions;

export default drugSlice.reducer;
