import React, { useEffect, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from 'react-bootstrap/Navbar';

import { accountProfileSelector, accountHasInsuranceSelector } from 'state/account/account.selectors';

import DesktopMenu from 'components/navigation/desktop-menu/desktop-menu.component';
import MobileMenu from 'components/navigation/mobile-menu/mobile-menu.component';

import { NavigationProps } from 'components/navigation/navigation.props';
import { getFilteredIntraPageMenuItems } from 'components/layouts/profile/profile.props';
import { cartItemsTotalSelector, cartOrderPlacedSelector, cartSelector } from 'state/cart/cart.selectors';

import './navigation.style.scss';
import { getCartRoutine } from 'state/cart/cart.routines';

const Navigation = ({ paths, isSecurePage, isLoggedIn }: NavigationProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const profileObject = useSelector(accountProfileSelector);
    const hasInsurance = useSelector(accountHasInsuranceSelector);
    const cartItemsTotal = useSelector(cartItemsTotalSelector);
    const cartOrderPlaced = useSelector(cartOrderPlacedSelector);
    const cartObject = useSelector(cartSelector);
    const [isScrolled, setIsScrolled] = useState(false);
    const [cartTotal, setCartTotal] = useState(cartItemsTotal);
    const isProfileLoaded = profileObject !== undefined;

    const intraPageMenuItems =
        isSecurePage || isLoggedIn ? getFilteredIntraPageMenuItems(t, isProfileLoaded, hasInsurance) : undefined;

    const userName = profileObject ? profileObject.patientFirstName : '';

    useEffect(() => {
        if (cartObject === undefined) {
            dispatch(getCartRoutine.trigger());
        }
    }, []);

    useEffect(() => {
        setCartTotal(cartOrderPlaced ? 0 : cartItemsTotal);
    }, [cartItemsTotal, cartOrderPlaced]);

    const handleScroll = () => {
        //  if the window scrollY passes below the bottom of the navs
        if (window.scrollY >= 1) {
            setIsScrolled(true);
            return;
        }
        //  if the window scrollY passes above the bottom of the nav
        if (window.scrollY - 74 <= 0 && isScrolled) {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        if (window) {
            window.addEventListener('scroll', handleScroll);
        }
        return () => window.removeEventListener('scroll', handleScroll);
    });

    return (
        <Navbar expand="lg" sticky="top" className={`${isScrolled ? 'scrolled' : ''}`}>
            <DesktopMenu
                paths={paths}
                isScrolled={isScrolled}
                userName={userName}
                profileMenu={intraPageMenuItems}
                isSecurePage={isSecurePage}
                isLoggedIn={isLoggedIn}
                cartItemsTotal={cartTotal}
            />
            <MobileMenu
                paths={paths}
                isScrolled={isScrolled}
                userName={userName}
                profileMenu={intraPageMenuItems}
                isSecurePage={isSecurePage}
                isLoggedIn={isLoggedIn}
                cartItemsTotal={cartTotal}
            />
        </Navbar>
    );
};

export default Navigation;
