import { ReactElement } from 'react';
import { Button as ReactButton } from 'react-bootstrap';
import classNames from 'classnames';

// UI-Kit
import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';
import CogIcon from 'ui-kit/icons/cog-icon/cog-icon';
import TrashIcon from 'ui-kit/icons/trash/trash-icon';
import BirdiPriceIcon from 'ui-kit/icons/birdi-price/birdi-price-icon';

// Icons
import PlusIcon from '../icons/plus-icon/plus-icon';
import Camera from '../icons/camera/camera';
import { ButtonProps } from './button.props';
import CartWhiteIcon from 'ui-kit/icons/cart-white-icon/cart-white-icon';

import './button.style.scss';

const Button = ({
    async,
    label,
    children,
    onClick,
    type,
    variant,
    disabled,
    className,
    chevron,
    isBusy,
    dataGAFormName,
    dataGAFormStep,
    dataGAFormStepName,
    dataGAType,
    dataGALocation,
    cameraIcon,
    trashIcon,
    plusIcon,
    birdipriceIcon,
    IconType,
    cartIcon,
    separator,
    itemCount,
    style,
    ref,
    customFontSize
}: ButtonProps): ReactElement => {
    const classes = classNames(className, { 'is-busy': isBusy });
    const carBtnClasses = classNames(
        'btn-label',
        { [`font-size-${customFontSize}`]: customFontSize },
        { 'font-weight-bold mx-1': cartIcon }
    );
    return (
        <ReactButton
            ref={ref}
            style={style}
            className={classes}
            variant={variant ?? 'primary'}
            type={type}
            onClick={onClick}
            disabled={disabled}
            data-ga-form-step={dataGAFormStep}
            data-ga-form-stepname={dataGAFormStepName}
            data-ga-form-name={dataGAFormName}
            data-ga-type={dataGAType}
            data-ga-location={dataGALocation}
        >
            {cameraIcon && <Camera className="mr-2" style={{ width: '24px' }} />}
            {cartIcon && <CartWhiteIcon itemCount={itemCount} />}
            {chevron === 'left' && <ChevronIcon direction={chevron} />}
            {trashIcon && <TrashIcon />}
            {birdipriceIcon && <BirdiPriceIcon />}
            {plusIcon && <PlusIcon type={IconType} className="btn-plus-icon" />}
            <div className={carBtnClasses}>{label}</div>
            {cartIcon && separator && <span className="divider mx-1 text-white font-weight-bold">|</span>}
            <div className={isBusy ? 'invisible' : 'visible'}>{children}</div>
            {async && <CogIcon className="btn-cog-icon" style={{ height: '100%', width: '2rem' }} />}
            {!!chevron && chevron !== 'left' && <ChevronIcon direction={chevron} />}
        </ReactButton>
    );
};

export default Button;
