import React, { CSSProperties } from 'react';

interface EvioMobileToggleProps {
    onClick: () => void;
    style?: CSSProperties;
}

const EvioMobileToggle = ({ onClick, style }: EvioMobileToggleProps) => {
    return (
        <div className="mobile-toggle-container" style={style}>
            <svg
                className="mobile-toggle"
                fill="none"
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                onClick={onClick ?? undefined}
            >
                <path d="M10 13H24" stroke="#4B3F72" stroke-width="3" stroke-linecap="round" />
                <path d="M10 21H24" stroke="#4B3F72" stroke-width="3" stroke-linecap="round" />
            </svg>
        </div>
    );
};

export default EvioMobileToggle;
