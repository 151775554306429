import React, { ReactElement } from 'react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

const UpdateProfileModalContent = ({ area }: { area: string }): ReactElement => {
    const { t } = useTranslation();
    return (
        <BirdiModalContent
            icon={'none'}
            title={t('modals.updateProfile.title')}
            body={t('modals.updateProfile.body', {
                area
            })}
        />
    );
};

export const FailureUpdateProfileModalContent = ({ area }: { area: string }): ReactElement => {
    const { t } = useTranslation();
    return (
        <BirdiModalContentAlt
            title={t('modals.updateProfile.failure', {
                area
            })}
        />
    );
};

export const VerifySmsRequestCanceled = (): ReactElement => {
    const { t } = useTranslation();
    return (
        <BirdiModalContent
            icon={'none'}
            title={t('modals.smsVerification.cancelRequest.title')}
            body={t('modals.smsVerification.cancelRequest.message')}
        />
    );
};

export const VerifySmsRequestSuccess = (): ReactElement => {
    const { t } = useTranslation();
    return (
        <BirdiModalContent
            icon={'none'}
            title={t('modals.smsVerification.successTitle')}
            body={t('modals.smsVerification.successPreferencesUpdated')}
        />
    );
};

export default UpdateProfileModalContent;
