import React from 'react';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { Field, Form, Formik } from 'formik';
import Button from 'ui-kit/button/button';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ADDRESS_VALIDATION_CONFIRMATION_SCHEMA } from 'schema/address';
import './address-verification-modal.style.scss';
import { USPSAddress } from 'util/usps';
import CircleInfo from 'ui-kit/icons/info/circle-info-icon';

interface AddressVerificationFormProps {
    originalAddress: USPSAddress;
    correctedAddress: USPSAddress;
    onSuccess?: Function;
}

interface AddressSchema {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
}

export const AddressVerificationForm = ({
    originalAddress,
    correctedAddress,
    onSuccess
}: AddressVerificationFormProps): ReactElement => {
    const formName = 'AddressVerificationForm';
    const { t } = useTranslation();

    const convertUSPStoAddress = (uspsAddress: USPSAddress): AddressSchema => {
        // Convert address back from USPS fields back to form fields
        const validAddress: AddressSchema = {
            address1: uspsAddress.Address2,
            address2: uspsAddress.Address1 ? uspsAddress.Address1 : '',
            city: uspsAddress.City,
            state: uspsAddress.State,
            zipcode: uspsAddress.Zip4 ? `${uspsAddress.Zip5}-${uspsAddress.Zip4}` : uspsAddress.Zip5
        };
        return validAddress;
    };

    const onSubmit = (e: any) => {
        // The console.log statement will be removed once the modal is fully implemented.
        if (onSuccess) {
            const payload =
                e?.addressChoice === 'original'
                    ? convertUSPStoAddress(originalAddress)
                    : convertUSPStoAddress(correctedAddress);
            onSuccess(payload);
        }
    };
    const formatAddress = (addressObject: USPSAddress) => {
        return (
            <div className="text-uppercase">
                <div className="mb-1">
                    {addressObject.Address2} {addressObject.Address1 ? addressObject.Address1 : ''}
                </div>
                <div>
                    {addressObject.City}, {addressObject.State} {addressObject.Zip5}
                    {addressObject.Zip4 ? `-${addressObject.Zip4}` : ''}
                </div>
            </div>
        );
    };

    return (
        <Formik
            onSubmit={onSubmit}
            validationSchema={ADDRESS_VALIDATION_CONFIRMATION_SCHEMA}
            initialValues={{
                addressChoice: undefined
            }}
        >
            {(formik: any) => (
                <Form
                    id="address-validation-form"
                    data-ga-form-name={formName}
                    onSubmit={formik.handleSubmit}
                    autoComplete="off"
                >
                    <div className="disclaimer-icon-container">
                        <CircleInfo />
                    </div>
                    <h3 className="font-weight-bold">{t('modals.addressVerification.title')}</h3>
                    <div className="body-text-container">
                        <p className="mb-0">{t('modals.addressVerification.body')}</p>
                        <p>{t('modals.addressVerification.instructions')}</p>
                    </div>
                    <div className="text-left">
                        <div className="h6 mb-2 pb-1">{t('modals.addressVerification.originalAddressTitle')}</div>
                        <label className="radio-toggle--label border rounded">
                            <Field
                                type="radio"
                                name={'addressChoice'}
                                value={'original'}
                                className="radio-toggle--input mr-2"
                            />
                            {originalAddress ? formatAddress(originalAddress) : null}
                        </label>
                        <div className="h6 mb-2 pb-1">{t('modals.addressVerification.correctedAddressTitle')}</div>
                        <label className="radio-toggle--label border rounded">
                            <Field
                                type="radio"
                                name={'addressChoice'}
                                value={'corrected'}
                                className="radio-toggle--input mr-2"
                            />
                            {correctedAddress ? formatAddress(correctedAddress) : null}
                        </label>
                    </div>

                    <Button
                        className="sm-full address-card__action mt-3 mt-lg-5"
                        variant="primary"
                        type="button"
                        onClick={formik.handleSubmit}
                        dataGAFormName={formName}
                        dataGALocation="AddressVerificationModal"
                        disabled={!formik.dirty || (formik.dirty && !formik.isValid)}
                        label={t('modals.addressVerification.submit')}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default function AddressVerificationModal({
    originalAddress,
    correctedAddress,
    onSuccess
}: AddressVerificationFormProps) {
    return (
        <BirdiModalContent
            icon={'none'}
            body={
                <AddressVerificationForm
                    originalAddress={originalAddress}
                    correctedAddress={correctedAddress}
                    onSuccess={onSuccess}
                />
            }
        />
    );
}
