import React from 'react';

import { LogoProps } from 'ui-kit/logo/logo.props';

const Logo = ({ variant }: LogoProps) => {
    const getVariantStyle = (variant: string | undefined) => {
        switch (variant) {
            case 'light': {
                return { fill: '#FFFFFF', height: '48', width: '160' };
            }
            case 'light-large': {
                return { fill: '#FFFFFF', height: '167', width: '500' };
            }
            case 'primary-large': {
                return { fill: '#03a4dc', height: '167', width: '500' };
            }
            case 'primary': {
                return { fill: '#03a4dc', height: '48', width: '160' };
            }
            default: {
                return { fill: '#03a4dc', height: '100%', width: '100%' };
            }
        }
    };

    const variantStyle = getVariantStyle(variant);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={variantStyle.width}
            height={variantStyle.height}
            viewBox="0 0 773.45 250.87"
        >
            <g transform="translate(-18 0)">
                <g>
                    <path
                        fill={variantStyle.fill}
                        d="M279.22,151.74v-34.73c-0.27-18.42-15.26-33.28-33.75-33.28c-18.49,0-33.48,14.86-33.75,33.28v34.73H279.22z"
                    />
                    <path
                        fill={variantStyle.fill}
                        d="M211.72,177.32v34.72c0.27,18.42,15.26,33.28,33.75,33.28c18.48,0,33.48-14.86,33.75-33.28v-34.72H211.72z"
                    />
                </g>
                <path
                    fill={variantStyle.fill}
                    d="M723.89,117.02c-0.27-18.42-15.26-33.28-33.75-33.28c-18.49,0-33.48,14.86-33.75,33.28v95.03
	c0.27,18.42,15.26,33.28,33.75,33.28c18.48,0,33.48-14.86,33.75-33.28V117.02z"
                />
                <path
                    fill={variantStyle.fill}
                    d="M625.6,38.31L625.6,38.31c-0.27-18.42-15.26-33.28-33.75-33.28c0,0-0.01,0-0.01,0s-0.01,0-0.01,0
	c-18.48,0-33.48,14.85-33.75,33.28l-0.03,42.14c-8.77-3.08-18.21-4.78-28.04-4.78c-46.73,0-84.61,37.88-84.61,84.61
	c0,46.73,37.88,84.61,84.61,84.61c13.78,0,26.77-3.31,38.27-9.16c3.8,3.7,8.45,6.52,13.65,8.12c0.44,0.14,0.9,0.23,1.35,0.34
	c0.61,0.16,1.2,0.34,1.82,0.46c2.17,0.44,4.41,0.67,6.71,0.67c0,0,0.01,0,0.01,0s0.01,0,0.01,0c0.22,0,0.42-0.03,0.64-0.03
	c0.91-0.02,1.82-0.05,2.72-0.14c0.62-0.06,1.21-0.18,1.82-0.27c0.48-0.07,0.97-0.12,1.44-0.22c0.71-0.14,1.41-0.34,2.11-0.52
	c0.34-0.09,0.7-0.16,1.04-0.26c0.76-0.23,1.5-0.51,2.23-0.79c0.26-0.1,0.53-0.18,0.78-0.28c0.78-0.32,1.53-0.68,2.28-1.06
	c0.19-0.1,0.4-0.18,0.59-0.28c0.79-0.41,1.55-0.87,2.3-1.34c0.14-0.09,0.28-0.16,0.41-0.25c0.79-0.51,1.55-1.07,2.3-1.65
	c0.08-0.06,0.16-0.11,0.23-0.17c0.77-0.61,1.51-1.25,2.22-1.92c0.04-0.04,0.09-0.08,0.13-0.12c0.74-0.7,1.45-1.44,2.12-2.21
	c0.01-0.01,0.02-0.02,0.03-0.03c5.1-5.83,8.25-13.41,8.37-21.73V38.31L625.6,38.31L625.6,38.31z M530.03,188.69
	c-15.69,0-28.41-12.72-28.41-28.41s12.72-28.41,28.41-28.41c15.69,0,28.41,12.72,28.41,28.41S545.72,188.69,530.03,188.69z"
                />
                <path
                    fill={variantStyle.fill}
                    d="M402.12,84.18c-13.37,0.22-26.43,2.77-38.7,7.36c-0.04-0.03-0.08-0.05-0.12-0.08
	c-1.29-1.07-2.68-2.02-4.13-2.88c-0.25-0.15-0.5-0.31-0.76-0.46c-1.43-0.81-2.92-1.5-4.46-2.1c-0.31-0.12-0.62-0.23-0.94-0.34
	c-1.57-0.55-3.19-1.01-4.86-1.33c-0.27-0.05-0.55-0.08-0.83-0.13c-1.74-0.28-3.52-0.47-5.34-0.48c-0.04,0-0.08-0.01-0.12-0.01
	c-2.31,0-4.57,0.23-6.75,0.68c-15.27,3.09-26.79,16.48-27.02,32.61v95.03c0,0.03,0.01,0.06,0.01,0.09v1.3h0.08
	c0.68,12.22,7.81,22.71,18.08,28.07c0.02,0.01,0.05,0.02,0.07,0.03c0.91,0.47,1.84,0.91,2.79,1.3c0.06,0.02,0.12,0.04,0.18,0.06
	c0.93,0.37,1.87,0.71,2.83,1c0.07,0.02,0.15,0.03,0.22,0.06c0.96,0.28,1.93,0.53,2.92,0.73c0.08,0.02,0.15,0.02,0.23,0.03
	c2.05,0.39,4.17,0.61,6.34,0.61c0.74,0,1.46-0.06,2.18-0.11c0.39-0.03,0.8-0.02,1.19-0.06c0.45-0.04,0.89-0.13,1.34-0.2
	c0.66-0.09,1.32-0.17,1.96-0.3c0.39-0.08,0.77-0.19,1.15-0.29c0.68-0.16,1.37-0.32,2.03-0.52c0.36-0.11,0.71-0.25,1.06-0.38
	c0.67-0.23,1.34-0.45,1.99-0.72c0.34-0.14,0.67-0.31,1-0.47c0.64-0.29,1.29-0.58,1.9-0.91c0.33-0.18,0.65-0.38,0.98-0.57
	c0.6-0.34,1.19-0.69,1.77-1.06c0.33-0.22,0.63-0.46,0.95-0.68c0.55-0.39,1.09-0.77,1.61-1.19c0.32-0.26,0.62-0.53,0.93-0.8
	c0.49-0.42,0.98-0.85,1.45-1.3c0.31-0.3,0.59-0.62,0.89-0.93c0.43-0.45,0.87-0.9,1.27-1.38c0.29-0.34,0.56-0.7,0.84-1.05
	c0.37-0.48,0.75-0.95,1.1-1.45c0.27-0.39,0.52-0.79,0.77-1.18c0.32-0.49,0.63-0.98,0.92-1.49c0.25-0.43,0.47-0.87,0.69-1.31
	c0.26-0.5,0.52-1.01,0.75-1.53c0.21-0.47,0.4-0.94,0.59-1.42c0.21-0.52,0.41-1.04,0.6-1.57c0.17-0.49,0.31-1,0.46-1.5
	c0.16-0.54,0.31-1.08,0.44-1.63c0.12-0.52,0.22-1.04,0.32-1.57c0.1-0.55,0.2-1.11,0.28-1.67c0.07-0.53,0.12-1.07,0.17-1.62
	c0.03-0.35,0.09-0.69,0.11-1.04h0.09V169.4c0-8.12,5.17-15.44,12.91-17.9c4.96-1.58,10.18-2.4,15.51-2.4
	c18.17,0,32.86-14.94,32.46-33.21C436.11,97.9,420.11,83.87,402.12,84.18z"
                />
                <path
                    fill={variantStyle.fill}
                    d="M100.65,75.67c-9.83,0-19.26,1.69-28.04,4.78V38.31h-0.02C72.32,19.88,57.32,5.03,38.84,5.03c0,0,0,0,0,0
	c-0.01,0-0.01,0-0.02,0c-18.48,0-33.48,14.86-33.75,33.28v173.74c0,0.03,0.01,0.06,0.01,0.09v1.3h0.08
	c0.68,12.22,7.81,22.71,18.08,28.07c0.01,0.01,0.02,0.01,0.04,0.02c0.92,0.48,1.87,0.92,2.83,1.32c0.02,0.01,0.05,0.02,0.07,0.03
	c0.96,0.39,1.94,0.74,2.94,1.04c0.02,0.01,0.04,0.01,0.06,0.01c3.05,0.91,6.29,1.41,9.64,1.41c9.17,0,17.48-3.66,23.56-9.6
	c11.5,5.84,24.49,9.16,38.28,9.16c46.73,0,84.61-37.88,84.61-84.61C185.26,113.55,147.38,75.67,100.65,75.67z M100.65,188.69
	c-15.69,0-28.41-12.72-28.41-28.41c0-15.69,12.72-28.41,28.41-28.41s28.41,12.72,28.41,28.41
	C129.05,175.98,116.34,188.69,100.65,188.69z"
                />
                <g transform="translate(-740 -240) scale(2 2)">
                    <path
                        fill={variantStyle.fill}
                        d="M747.78,228.39h-3.33c-0.52,0-0.64-0.04-0.64-0.56v-0.49c0-0.49,0.11-0.56,0.64-0.56h8.58
		c0.49,0,0.6,0.07,0.6,0.56v0.49c0,0.49-0.11,0.56-0.6,0.56h-3.22v10.34c0,0.52-0.11,0.64-0.64,0.64h-0.75
		c-0.49,0-0.64-0.11-0.64-0.64V228.39z"
                    />
                    <path
                        fill={variantStyle.fill}
                        d="M766.03,230l-3.3,8.69c-0.22,0.52-0.41,0.67-1.01,0.67c-0.56,0-0.79-0.15-0.97-0.67l-3.22-8.69v8.73
		c0,0.52-0.15,0.64-0.64,0.64h-0.67c-0.49,0-0.6-0.11-0.6-0.64v-11.2c0-0.6,0.11-0.75,0.6-0.75h1.46c0.52,0,0.71,0.15,0.86,0.71
		l3.18,8.65l3.3-8.69c0.22-0.52,0.38-0.67,0.9-0.67h1.54c0.49,0,0.6,0.15,0.6,0.75v11.2c0,0.52-0.11,0.64-0.6,0.64h-0.79
		c-0.49,0-0.64-0.11-0.64-0.64V230z"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Logo;
