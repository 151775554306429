import { put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { TrackError } from 'util/google_optimize/optimize_helper';

import cartService from 'state/cart/cart.services';
import {
    cancelOrderLine,
    cartCompleteOrderRoutine,
    updateRefillLinesRoutine,
    startCartRoutine,
    cartUpdateShippingRoutine,
    cartUpdatePaymentRoutine,
    cartUpdateExpeditedShippingRoutine,
    cartUpdateLineItemsRoutine
} from 'state/cart/cart.routines';
import { getCartRoutine } from 'state/cart/cart.routines';
import { baseEffectHandler } from 'util/sagas/sagas';
import { CartObjectPayload, CancelOrderLinePayload } from 'state/cart/cart.services';
import { AllCreditCardsPayload } from 'state/account/account.services';
import produce from 'immer';
import { ProfileObjectPayload } from 'state/account/account.services';
import {
    accountProfileSelector,
    accountCreditCardsSelector,
    accountIsLoggedInSelector
} from 'state/account/account.selectors';
import { cartItemsSelector, cartSelector } from 'state/cart/cart.selectors';
import { OrderBillShip, RefillRxs } from 'types/order-prescription';
import { medicineCabinetPrescriptionsSelector } from '../medicine-cabinet/medicine-cabinet.selectors';
import { PrescriptionObjectPayload } from '../medicine-cabinet/medicine-cabinet.services';

export default function* cartSaga() {
    yield takeLatest(getCartRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
        if (isLoggedIn) {
            yield baseEffectHandler<CartObjectPayload>({
                service: cartService.startCart().getAll,
                isAuthenticatedService: true,
                isLoggedIn,
                data: action.payload,
                *onResponse(data) {
                    yield put(getCartRoutine.success(data));
                },
                *onError(data) {
                    yield put(getCartRoutine.failure(data));
                }
            });
        }
    });

    yield takeLatest(
        startCartRoutine.TRIGGER,
        function* (
            action: PayloadAction<{
                rxNumbers: string[];
                onSuccess: (data: any) => void;
                onFailure: (data: any) => void;
            }>
        ) {
            try {
                const profileObject: ProfileObjectPayload = yield select(accountProfileSelector);
                const creditCardsObject: AllCreditCardsPayload = yield select(accountCreditCardsSelector);
                const orderObject: CartObjectPayload = yield select(cartSelector);
                const prescriptionsObject: PrescriptionObjectPayload = yield select(
                    medicineCabinetPrescriptionsSelector
                );
                const orderDate = new Date().toISOString();
                const defaultAddress = profileObject?.addresses.find((obj) => {
                    return obj.defaultShip;
                });

                const defaultCreditCard =
                    creditCardsObject !== undefined && creditCardsObject.length > 0
                        ? creditCardsObject.find((obj: { defaultCard: any }) => obj.defaultCard)?.cardSeqNum
                        : null;
                const currentPrescription = prescriptionsObject?.find((obj) => {
                    return obj.rxNumber;
                });

                const { rxNumbers, onFailure, onSuccess } = action.payload;

                let rxsToOrder: RefillRxs[] = [];
                rxNumbers.forEach((rxNumber) => {
                    const rxObject = {
                        rxNumber: rxNumber,
                        lastRefillScriptId: String(currentPrescription?.prevScriptId),
                        originationNum: '6',
                        messageText: '',
                        messageStatus: true,
                        rxLineError: '',
                        lineTotal: null,
                        fillNote: '',
                        epostRxScriptId: '',
                        orderNum: '',
                        patientCopay: null,
                        planNum: '',
                        insurancePayment: null,
                        messageErrorText: ''
                    };
                    rxsToOrder.push(rxObject);
                });

                const updatedCartObject = produce(orderObject, (draftState) => {
                    if (!draftState) return;
                    draftState.doNotSubmitToWorkflow = true;
                    draftState.orderBillShip.ordShipDate = orderDate;
                    draftState.orderBillShip.orderPaymentOwner = profileObject?.epostPatientNum;
                    draftState.orderBillShip.paymentMethodId = '2';
                    draftState.orderBillShip.shipMethodId = '505';
                    draftState.orderBillShip.orderNum = null;
                    draftState.orderHeader.forceReview = false;
                    draftState.orderHeader.locationId = orderObject.orderHeader.locationId;
                    draftState.orderHeader.orderDate = orderDate;
                    draftState.orderHeader.orderHighPriority = false;
                    // DRX-1548: use orderStatusNum: null (was previously '1'); ANS has advised that this is the optimal way to handle
                    draftState.orderHeader.orderStatusNum = null;
                    draftState.orderHeader.originationNum = '6';
                    draftState.orderHeader.workflowTypeNum = '3';
                    draftState.orderHeader.orderInvoiceNumber = null;
                    draftState.orderHeader.orderNum = null;
                    draftState.originationNum = '6';
                    draftState.refillRxs = rxsToOrder;
                    if (defaultAddress !== undefined) {
                        draftState.orderBillShip.patientBillAddressSeq = defaultAddress.addressSeqNum;
                        draftState.orderBillShip.patientShipAddressSeq = defaultAddress.addressSeqNum;
                    }
                    if (defaultCreditCard !== undefined) {
                        draftState.orderBillShip.paymentCardSeqNum = defaultCreditCard;
                    }
                    draftState.cartId = null;
                });

                const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
                yield baseEffectHandler<CartObjectPayload>({
                    service: cartService.startCart().post,
                    isAuthenticatedService: true,
                    isLoggedIn,
                    data: updatedCartObject,
                    *onResponse(data) {
                        if (onSuccess) onSuccess(data);
                        yield put(startCartRoutine.success(data));
                    },
                    *onError(data) {
                        if (onFailure) onFailure(data);
                        yield put(startCartRoutine.failure(data));
                    }
                });
            } catch (e) {
                const { onFailure } = action.payload;
                if (onFailure) onFailure(e);
                yield put(startCartRoutine.failure(undefined));
                TrackError('cart.sagas.ts', 'startCartRoutine', e);
            }
        }
    );

    yield takeLatest(
        updateRefillLinesRoutine.TRIGGER,
        function* (
            action: PayloadAction<{
                rxNumbers: string[];
                onSuccess: (data: any) => void;
                onFailure: (data: any) => void;
            }>
        ) {
            try {
                const orderObject: CartObjectPayload = yield select(cartSelector);
                const prescriptionsObject: PrescriptionObjectPayload = yield select(
                    medicineCabinetPrescriptionsSelector
                );
                const currentPrescription = prescriptionsObject?.find((obj) => {
                    return obj.rxNumber;
                });

                const { rxNumbers, onFailure, onSuccess } = action.payload;

                let rxObjects: RefillRxs[] = [];
                rxNumbers.forEach((rxNumber: string) => {
                    const newObject: RefillRxs = {
                        rxNumber: rxNumber,
                        lastRefillScriptId: String(currentPrescription?.prevScriptId),
                        originationNum: '6',
                        messageText: '',
                        messageStatus: true,
                        rxLineError: '',
                        lineTotal: null,
                        fillNote: '',
                        epostRxScriptId: '',
                        orderNum: '',
                        patientCopay: null,
                        insurancePayment: null,
                        messageErrorText: '',
                        planNum: ''
                    };
                    rxObjects.push(newObject);
                });
                const updatedCartObject = produce(orderObject, (draftState) => {
                    if (draftState) {
                        rxObjects.forEach((newObject) => {
                            draftState.refillRxs.push(newObject);
                        });
                    }
                });

                const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
                yield baseEffectHandler<CartObjectPayload>({
                    service: cartService.startCart().post,
                    isAuthenticatedService: true,
                    isLoggedIn,
                    data: updatedCartObject,
                    *onResponse(data) {
                        if (onSuccess) onSuccess(data);
                        yield put(updateRefillLinesRoutine.success(data));
                    },
                    *onError(data) {
                        if (onFailure) onFailure(data);
                        yield put(updateRefillLinesRoutine.failure(data));
                    }
                });
            } catch (e) {
                const { onFailure } = action.payload;
                if (onFailure) onFailure(e);
                yield put(updateRefillLinesRoutine.failure());
                TrackError('cart.sagas.ts', 'updateRefillLinesRoutine', e);
            }
        }
    );

    yield takeLatest(cancelOrderLine.TRIGGER, function* (action: PayloadAction<any>) {
        try {
            const {
                payload: { rxNumber, onSuccess }
            } = action;

            const cartObject: CartObjectPayload = yield select(cartSelector);
            const cartItems: RefillRxs = yield select(cartItemsSelector);
            const currentPrescription = cartItems?.find((obj: any) => {
                return obj.rxNumber === rxNumber;
            });
            const cancelObject = {
                rxNumber: rxNumber,
                lineId: currentPrescription?.epostRxScriptId,
                orderNum: cartObject?.orderHeader.orderNum
            };

            const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
            yield baseEffectHandler<CancelOrderLinePayload>({
                service: cartService.cancelOrder().post,
                isAuthenticatedService: true,
                isLoggedIn,
                data: cancelObject,
                *onResponse(data) {
                    yield put(cancelOrderLine.success(data));
                    if (onSuccess) onSuccess();
                },
                *onError(data) {
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                    yield put(cancelOrderLine.failure(data));
                }
            });
        } catch (e) {
            const { onFailure } = action.payload;
            if (onFailure) onFailure();
            yield put(cancelOrderLine.failure());
            TrackError('cart.sagas.ts', 'cancelOrderLine', e);
        }
    });

    yield takeLatest(cartCompleteOrderRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const {
            payload: { onSuccess, onFailure, orderTotal, lineItems }
        } = action;
        try {
            const orderObject: CartObjectPayload = yield select(cartSelector);
            const profileObject: ProfileObjectPayload = yield select(accountProfileSelector);
            const creditCardsObject: AllCreditCardsPayload = yield select(accountCreditCardsSelector);
            const defaultAddress = profileObject?.addresses.find((obj) => {
                return obj.defaultShip;
            });
            const defaultCreditCard = creditCardsObject?.find((obj: { defaultCard: any }) => {
                return obj.defaultCard;
            });
            const updatedCartObject = produce(orderObject, (draftState) => {
                if (draftState) {
                    draftState.orderTotal = String(orderTotal);
                    if (defaultAddress !== undefined) {
                        draftState.orderBillShip.patientBillAddressSeq = orderObject.orderBillShip.patientBillAddressSeq
                            ? orderObject.orderBillShip.patientBillAddressSeq
                            : defaultAddress.addressSeqNum;
                        draftState.orderBillShip.patientShipAddressSeq = orderObject.orderBillShip.patientShipAddressSeq
                            ? orderObject.orderBillShip.patientShipAddressSeq
                            : defaultAddress.addressSeqNum;
                    }
                    if (defaultCreditCard !== undefined) {
                        draftState.orderBillShip.paymentCardSeqNum = orderObject.orderBillShip.paymentCardSeqNum
                            ? orderObject.orderBillShip.paymentCardSeqNum
                            : defaultCreditCard.cardSeqNum;
                    }
                    draftState.orderBillShip.paymentMethodId =
                        draftState.orderBillShip.paymentMethodId === '6' && draftState.orderBillShip.paymentCardSeqNum
                            ? '2'
                            : draftState.orderBillShip.paymentMethodId;

                    if (lineItems !== undefined) {
                        const cleanRefillRxs: RefillRxs[] = [];
                        draftState.refillRxs.map((rx: RefillRxs) => {
                            let currentPrescription = lineItems.find((lineItem: RefillRxs) => {
                                return rx.rxNumber === lineItem.rxNumber;
                            });
                            if (currentPrescription) {
                                // Exclude extra attributes included in the ExtendedRefillRxs object
                                let cleanRefillRx: RefillRxs = rx;
                                Object.keys(rx).forEach(function (key) {
                                    cleanRefillRx[key] = currentPrescription[key];
                                });
                                cleanRefillRxs.push(cleanRefillRx);
                            } else {
                                cleanRefillRxs.push(rx);
                            }
                        });
                        draftState.refillRxs = cleanRefillRxs;
                    }
                }
            });

            const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
            yield baseEffectHandler<CartObjectPayload>({
                service: cartService.completeOrder().post,
                isAuthenticatedService: true,
                isLoggedIn,
                data: updatedCartObject,
                *onResponse(data) {
                    yield put(cartCompleteOrderRoutine.success({ cart: data, orderPlaced: true }));
                    if (onSuccess) onSuccess();
                },
                *onError(data) {
                    yield put(cartCompleteOrderRoutine.failure({ cart: data, orderPlaced: false }));
                    if (onFailure) onFailure(data);
                }
            });
        } catch (e) {
            yield put(cartCompleteOrderRoutine.failure());
            if (onFailure) onFailure();
            TrackError('cart.sagas.ts', 'cartCompleteOrderRoutine', e);
        }
    });

    yield takeLatest(cartUpdateShippingRoutine.TRIGGER, function* (action: PayloadAction<OrderBillShip>) {
        try {
            const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
            const cartObject: CartObjectPayload = yield select(cartSelector);
            const cartObjectPayload = produce(cartObject, (draftCartObject) => {
                if (draftCartObject) {
                    draftCartObject.orderBillShip = action.payload;
                }
            });

            yield baseEffectHandler<CartObjectPayload>({
                service: cartService.updateCart().post,
                isAuthenticatedService: true,
                isLoggedIn,
                data: cartObjectPayload,
                *onResponse(data) {
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess();
                    yield put(cartUpdateShippingRoutine.success(cartObjectPayload));
                },
                *onError(data) {
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                    yield put(cartUpdateShippingRoutine.failure(data));
                }
            });
        } catch (e) {
            const { onFailure } = action.payload;
            if (onFailure) onFailure();
            yield put(cartUpdateShippingRoutine.failure());
            TrackError('cart.sagas.ts', 'cartUpdateShippingRoutine', e);
        }
    });

    yield takeLatest(cartUpdateExpeditedShippingRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const { orderHighPriority, shipMethodId, onSuccess, onFailure } = action.payload;
        try {
            const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
            const cartObject: CartObjectPayload = yield select(cartSelector);
            const cartObjectPayload = produce(cartObject, (draftCartObject) => {
                if (draftCartObject) {
                    draftCartObject.orderBillShip.shipMethodId = shipMethodId;
                    draftCartObject.orderHeader.orderHighPriority = orderHighPriority;
                }
            });

            yield baseEffectHandler<CartObjectPayload>({
                service: cartService.updateCart().post,
                isAuthenticatedService: true,
                isLoggedIn,
                data: cartObjectPayload,
                *onResponse(data) {
                    if (onSuccess) onSuccess();
                    yield put(cartUpdateExpeditedShippingRoutine.success(cartObjectPayload));
                },
                *onError(data) {
                    if (onFailure) onFailure();
                    yield put(cartUpdateExpeditedShippingRoutine.failure(data));
                }
            });
        } catch (e) {
            if (onFailure) onFailure();
            yield put(cartUpdateExpeditedShippingRoutine.failure());
            TrackError('cart.sagas.ts', 'cartUpdateExpeditedShippingRoutine', e);
        }
    });

    yield takeLatest(cartUpdateLineItemsRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const { lineItems, onSuccess, onFailure } = action.payload;
        try {
            const cartObject: CartObjectPayload = yield select(cartSelector);
            const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
            const updatedCartObject = produce(cartObject, (draftState) => {
                if (draftState) {
                    const cleanRefillRxs: RefillRxs[] = [];
                    draftState.refillRxs.map((rx: RefillRxs) => {
                        let currentPrescription = lineItems.find((lineItem: RefillRxs) => {
                            return rx.rxNumber === lineItem.rxNumber;
                        });
                        cleanRefillRxs.push(currentPrescription ? currentPrescription : rx);
                    });
                    draftState.refillRxs = cleanRefillRxs;
                }
            });

            yield baseEffectHandler<CartObjectPayload>({
                service: cartService.updateCart().post,
                isAuthenticatedService: true,
                isLoggedIn,
                data: updatedCartObject,
                *onResponse(data) {
                    if (onSuccess) onSuccess(data);
                    yield put(cartUpdateLineItemsRoutine.success(data));
                },
                *onError(data) {
                    if (onFailure) onFailure(data);
                    yield put(cartUpdateLineItemsRoutine.failure(data));
                    TrackError('cart.sagas.ts', 'cartUpdateLineItemsRoutine', data);
                }
            });
        } catch (e) {
            if (onFailure) onFailure();
            yield put(cartUpdateLineItemsRoutine.failure());
            TrackError('cart.sagas.ts', 'cartUpdateLineItemsRoutine', e);
        }
    });

    yield takeLatest(cartUpdatePaymentRoutine.TRIGGER, function* (action: PayloadAction<OrderBillShip>) {
        try {
            const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
            const cartObject: CartObjectPayload = yield select(cartSelector);
            const cartObjectPayload = produce(cartObject, (draftCartObject) => {
                if (draftCartObject) {
                    draftCartObject.orderBillShip = action.payload;
                }
            });

            yield baseEffectHandler<CartObjectPayload>({
                service: cartService.updateCart().post,
                isAuthenticatedService: true,
                isLoggedIn,
                data: cartObjectPayload,
                *onResponse(data) {
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess();
                    yield put(cartUpdatePaymentRoutine.success(cartObjectPayload));
                },
                *onError(data) {
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                    yield put(cartUpdatePaymentRoutine.failure(data));
                }
            });
        } catch (e) {
            const { onFailure } = action.payload;
            if (onFailure) onFailure();
            yield put(cartUpdatePaymentRoutine.failure());
            TrackError('cart.sagas.ts', 'cartUpdatePaymentRoutine', e);
        }
    });
}
