import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CartObjectPayload } from './cart.services';
import { produce } from 'immer';
import {
    getCartRoutine,
    cartUpdateCartRoutine,
    startCartRoutine,
    updateRefillLinesRoutine,
    cancelOrderLine,
    cartUpdateShippingRoutine,
    cartUpdatePaymentRoutine,
    cartCompleteOrderRoutine
} from './cart.routines';
import { CreditCardPayload, ProfileObjectAddressPayload } from 'state/account/account.services';

export interface CartOrderSnapshot {
    cart: CartObjectPayload;
    shippingAddress: ProfileObjectAddressPayload;
    paymentCard: CreditCardPayload;
}

export interface cartState {
    error?: string;
    isBusy?: boolean;
    cart?: CartObjectPayload;
    orderPlaced: boolean;
    orderSnapshot?: CartOrderSnapshot;
}

export const initialState: cartState = {
    cart: undefined,
    orderPlaced: false,
    orderSnapshot: undefined
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setCart(state: cartState, action) {
            state.error = undefined;
            state.isBusy = false;
            state.cart = action.payload;
        },
        setOrderExpeditedShipping(state: cartState, action) {
            if (state.cart) {
                state.cart.orderHeader.orderHighPriority = action.payload.expeditedShipping;
                state.cart.orderBillShip.shipMethodId = action.payload.code;
            }
        }
    },
    extraReducers: ({ addCase }) => {
        /**
         * Web Profile Fetching Reducers
         */
        addCase(getCartRoutine.SUCCESS, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = payload;
                draftState.orderPlaced = initialState.orderPlaced;
            })
        );
        addCase(getCartRoutine.FAILURE, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = undefined;
                draftState.orderPlaced = initialState.orderPlaced;
            })
        );

        /**
         * Cart Start Cart Reducers
         */
        addCase(startCartRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
            })
        );
        addCase(startCartRoutine.SUCCESS, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = payload;
                draftState.isBusy = false;
            })
        );
        addCase(startCartRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.cart = undefined;
                draftState.isBusy = false;
            })
        );

        /**
         * Cart Update Cart Reducers
         */
        addCase(cartUpdateCartRoutine.SUCCESS, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = payload;
            })
        );
        addCase(cartUpdateCartRoutine.FAILURE, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = undefined;
            })
        );

        /**
         * Cart Update Refill Lines Reducers
         */
        addCase(updateRefillLinesRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
            })
        );
        addCase(updateRefillLinesRoutine.SUCCESS, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = payload;
                draftState.isBusy = false;
            })
        );
        addCase(updateRefillLinesRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.cart = undefined;
                draftState.isBusy = false;
            })
        );

        /**
         * Cart Order Line Reducers
         */
        addCase(cancelOrderLine.SUCCESS, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = payload;
            })
        );
        addCase(cancelOrderLine.FAILURE, (state, { payload }: PayloadAction<CartObjectPayload>) => {
            // Do nothing, and leave the cart in the same state.
        });

        /**
         * Cart Update Shipping Reducers
         */
        addCase(cartUpdateShippingRoutine.SUCCESS, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = payload;
            })
        );
        addCase(cartUpdateShippingRoutine.FAILURE, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = undefined;
            })
        );

        /**
         * Cart Update Payment Reducers
         */
        addCase(cartUpdatePaymentRoutine.SUCCESS, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = payload;
            })
        );
        addCase(cartUpdatePaymentRoutine.FAILURE, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = undefined;
            })
        );
        /*
         * Complete Cart Routine
         */
        addCase(
            cartCompleteOrderRoutine.SUCCESS,
            (state, { payload }: PayloadAction<{ cart: CartObjectPayload; orderPlaced: boolean }>) =>
                produce(state, (draftState) => {
                    draftState.orderPlaced = payload.orderPlaced;
                    draftState.cart = payload.cart;
                })
        );
    }
});

export const { setCart, setOrderExpeditedShipping } = cartSlice.actions;

export default cartSlice.reducer;
