import React, { ReactElement } from 'react';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { PageSectionProps } from './page-section.props';

import './page-section.style.scss';

const pageSectionClasses = (paragraph: {
    internal: { type: any };
    field_bm_override: string;
    field_alert_priority_level: any;
}): string => {
    // Some paragraphs may need to override the PageSection Classes
    let classNames = '';
    switch (paragraph?.internal.type) {
        case 'paragraph__internal_header_component':
            // Right now, the "field_bm_override" attribute is only on the Internal Header Component.
            // The hope is that this be added to all paragraphs, to allow more flexible layouts.
            // If/when that happens, we probably shouldn't look for a specific "internal.type", but
            // just check for the presense of "field_bm_override"
            if (paragraph?.field_bm_override) {
                classNames = paragraph?.field_bm_override;
            }
            break;
        case 'paragraph__page_alert_banner':
            classNames = 'mb-0 mw-100';
            if (paragraph.field_alert_priority_level) {
                classNames += ` bg-${paragraph.field_alert_priority_level}`;
            }
            break;
    }

    return classNames;
};

const PageSection = ({ className, children, style, paragraph, index }: PageSectionProps): ReactElement => {
    const classes = classNames('page-section', className, pageSectionClasses(paragraph));

    return (
        <Container fluid={'xl'} className={classes} style={style ?? {}} key={`container-${index}`}>
            <Row>
                <Col>{children}</Col>
            </Row>
        </Container>
    );
};

export default PageSection;
