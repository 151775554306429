import React, { ReactElement } from 'react';
import './badge.style.scss';

import { BadgeProps } from './badge.props';

import AppStore from 'ui-kit/icons/badges/app-store';
import PlayStore from 'ui-kit/icons/badges/play-store';

function getSvg(badgeType: string, label: string) {
    switch (badgeType) {
        case 'app_store':
            return <AppStore label={label} />;
        case 'play_store':
            return <PlayStore label={label} />;
    }
}

const Badge = ({ className, label, to, dataGALocation, badgeType }: BadgeProps): ReactElement => {
    const svgImage = getSvg(badgeType, label);

    return (
        <div className={`badge ${className ?? ''}`}>
            <a
                href={to}
                rel="noopener noreferrer"
                target="_blank"
                data-tagtype="anchorlink"
                data-ga-location={dataGALocation}
            >
                {svgImage}
            </a>
        </div>
    );
};

export default Badge;
