import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from '@reach/router';

import withSessionExpiration from 'hoc/withSessionExpiration';

import { accountProfileSelector, accountHasInsuranceSelector } from 'state/account/account.selectors';

import {
    getAllIntraPageMenuItems,
    getFilteredIntraPageMenuItems,
    ProfileLayoutProps
} from 'components/layouts/profile/profile.props';
import IntraPageMenu from 'components/navigation/intra-page-menu/intra-page-menu.component';
import PageLayout from 'components/layouts/page/page.layout';

import PageSectionHeader from 'ui-kit/page-section-header/page-section-header';
import { paragraphToComponent } from 'providers/paragraphs/paragraphs';

import './profile.style.scss';

const ProfileLayout = ({
    children,
    eyebrowText,
    title,
    heading,
    subhead,
    suppressChildrenContainer
}: ProfileLayoutProps) => {
    const { t } = useTranslation();
    const replacePath = (path: string) => (path === `/` ? path : path.replace(/\/$/, ``));
    const pageLocation = replacePath(useLocation().pathname);
    const profileObject = useSelector(accountProfileSelector);
    const hasInsurance = useSelector(accountHasInsuranceSelector);
    const isProfileLoaded = profileObject !== undefined;

    const layoutData = useStaticQuery(graphql`
        query {
            blueSkyBackground: file(relativePath: { eq: "assets/images/feather-background.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(formats: [AUTO])
                }
            }
            allNodeApplicationPage {
                nodes {
                    title
                    id
                    drupal_internal__nid
                    relationships {
                        field_application_page_content {
                            ...supportedParagraphs
                        }
                    }
                    path {
                        langcode
                        alias
                    }
                }
            }
        }
    `);

    // See if there is any Drupal Application Page content that matches this page URL
    const applicationPageParagraphs = layoutData.allNodeApplicationPage.nodes.filter(
        (node: any) => pageLocation === node.path?.alias?.toString()
    );

    const allIntraPageMenuItems = getAllIntraPageMenuItems(t);
    const filteredIntraPageMenuItems = getFilteredIntraPageMenuItems(t, isProfileLoaded, hasInsurance);

    return (
        <PageLayout
            headerImage={layoutData.blueSkyBackground}
            headerImageClassName="profile-background"
            pageContentClassName="profile-wrapper"
            metaData={{ nodeTitle: title }}
            suppressApplicationPage={true}
        >
            <div className="profile">
                <Container fluid="xl">
                    <Row className="justify-content-lg-center">
                        <Col xs={12} md={12} lg={8}>
                            <PageSectionHeader eyebrowText={eyebrowText} title={title} />
                        </Col>
                    </Row>
                    <Row className="no-gutters">
                        <Col lg={2}>
                            <Row className="no-gutters">
                                <Col md={{ span: 10, offset: 1 }} lg={{ span: 12, offset: 0 }}>
                                    <IntraPageMenu
                                        allMenuItems={allIntraPageMenuItems}
                                        filteredMenuItems={filteredIntraPageMenuItems}
                                        onClick={(path: string) => navigate(path)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={12} lg={8}>
                            <Row>
                                <Col md={{ span: 10, offset: 1 }}>
                                    {heading && (
                                        <div className="profile-active-menu-item-top-wrapper">
                                            <Container fluid>
                                                <Row>
                                                    <div className="d-flex flex-column col">
                                                        <h2 className="h3 profile-active-menu-heading">{heading}</h2>
                                                        {subhead && (
                                                            <div className="profile-active-menu-subhead">{subhead}</div>
                                                        )}
                                                    </div>
                                                </Row>
                                            </Container>
                                        </div>
                                    )}
                                    <div
                                        className={
                                            !suppressChildrenContainer
                                                ? `profile-active-menu-item-${heading ? 'bottom-' : ''}wrapper`
                                                : ''
                                        }
                                    >
                                        {children}
                                    </div>
                                </Col>
                            </Row>
                            {applicationPageParagraphs.length > 0 && (
                                <Row>
                                    <Col md={{ span: 10, offset: 1 }} className="mt-5">
                                        {applicationPageParagraphs[0]?.relationships?.field_application_page_content.map(
                                            (paragraph: any, index: number) => (
                                                <React.Fragment key={`application_page_section_${index}`}>
                                                    {paragraphToComponent(
                                                        paragraph?.internal.type,
                                                        paragraph,
                                                        index + 10
                                                    )}
                                                </React.Fragment>
                                            )
                                        )}
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </PageLayout>
    );
};

export default withSessionExpiration(ProfileLayout);
