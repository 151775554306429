import React, { CSSProperties } from 'react';
import { FluidObject } from 'gatsby-image';
import BackgroundImage from 'ui-kit/background-image/background-image';
import classNames from 'classnames';

export interface WithBackgroundImageProps {
    backgroundImage: FluidObject;
    style?: CSSProperties;
    useRoundedCorners?: boolean;
    backgroundColor?: string;
    backgroundColorFullWidth?: boolean;
}
const withBackgroundImage = <P extends object>(Component: React.ComponentType<P>, className: string) =>
    class WithBackgroundImage extends React.Component<P & WithBackgroundImageProps> {
        render() {
            const { backgroundImage, style, useRoundedCorners, backgroundColor, backgroundColorFullWidth } = this.props;
            const backgroundImageClasses = classNames(
                [`${className}`],
                { [`${backgroundColor}`]: backgroundColor && backgroundColorFullWidth },
                { 'has-background-color-full-width': backgroundColor && backgroundColorFullWidth }
            );

            return (
                <BackgroundImage
                    image={backgroundImage}
                    style={style ?? {}}
                    useRoundedCorners={useRoundedCorners}
                    className={backgroundImageClasses}
                >
                    <Component {...(this.props as P)} />
                </BackgroundImage>
            );
        }
    };

export default withBackgroundImage;
