import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';

import { FooterProps } from 'components/footer/footer.props';
import { ClientId } from 'const/client';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import sanitizeHtml from 'sanitize-html';
import useSiteClient from 'hooks/useSiteClient';

import Badge from 'ui-kit/badge/badge';
import Link from 'ui-kit/link/link';
import Logo from 'ui-kit/logo/logo';
import EvioLogo from 'ui-kit/logo/evio-logo/evio-logo';

import './footer.style.scss';
import { NavPath } from 'components/navigation/navigation.props';
import SocialMediaIcon from 'ui-kit/icons/social-media-icon/social-media-icon';

//
// --- Types ---
interface FooterNavPathLinkProps {
    path: NavPath;
}

interface FooterSectionProps {
    className?: string;
    contentClassName?: string;
    title: string;
}

interface FooterNavPathSectionProps {
    paths?: ReadonlyArray<NavPath>;
    title: string;
    contentClassName?: string;
}

interface CopyrightData {
    businessName: string;
    yearStart: number;
}

type FooterClientCopyrightData = Record<ClientId, CopyrightData>;

//
// --- Constants ---

const footerClientCopyrightData: FooterClientCopyrightData = {
    birdi: { businessName: 'Birdi', yearStart: 2021 },
    evio: { businessName: 'Evio', yearStart: 2023 }
};

// --- Helper Components ---
const FooterNavPathLink: React.FC<FooterNavPathLinkProps> = (props) => {
    const { path } = props;
    const { link, label, field_menu_body } = path;

    if (link?.url) {
        return (
            <Link
                className="footer-content-item"
                dataGALocation="FooterNav"
                label={label}
                to={link.url}
                variant="footer"
            />
        );
    }

    return (
        <div
            className="footer-content-item"
            dangerouslySetInnerHTML={{
                __html: sanitizeHtml(field_menu_body?.processed ? field_menu_body.processed : label, {
                    allowedAttributes: {
                        a: ['id', 'href', 'name', 'rel', 'target', 'data-*'],
                        '*': ['class']
                    }
                })
            }}
        />
    );
};

const FooterSection: React.FC<FooterSectionProps> = (props) => {
    const { children, className = '', contentClassName = '', title } = props;

    return (
        <>
            <div className={classNames('footer-col-title', className)}>{title}</div>
            <div className={classNames('footer-col-content', contentClassName)}>{children}</div>
        </>
    );
};

const FooterNavPathSection: React.FC<FooterNavPathSectionProps> = (props) => {
    const { paths = [], title, contentClassName = '' } = props;

    if (!Array.isArray(paths) || !paths.length) {
        return null;
    }

    return (
        <FooterSection title={title} contentClassName={contentClassName}>
            <>
                {paths.map((path: NavPath) => (
                    <FooterNavPathLink key={`footer-company-path-${path.label}`} path={path} />
                ))}
            </>
        </FooterSection>
    );
};

const Footer = ({
    copyrightYear,
    paths,
    supportPaths,
    socialMediaPaths,
    legalPaths,
    companyPaths,
    getAppPaths,
    mailingAddress,
    physicalAddress
}: FooterProps) => {
    const { t } = useTranslation();
    const hasCompanyPaths = Array.isArray(companyPaths) && companyPaths.length > 0;
    const hasSocialMediaPaths = Array.isArray(socialMediaPaths) && socialMediaPaths.length > 0;
    const hasGetAppPaths = Array.isArray(getAppPaths) && getAppPaths.length > 0;
    const footerDesktopRowNumber = hasCompanyPaths ? 5 : 4;
    const { clientId, isEvio } = useSiteClient();

    return (
        <footer>
            <Container fluid="xl">
                <Row>
                    <Col className={`logo-col d-flex justify-content-start mb-4 pb-3`}>
                        {isEvio ? (
                            <>
                                <div className="d-none d-sm-block">
                                    <EvioLogo variant="primary" />
                                </div>
                                <div className="d-sm-none">
                                    <EvioLogo variant="light-larger" />
                                </div>
                            </>
                        ) : (
                            <Logo variant="primary" />
                        )}
                    </Col>
                </Row>
                <Row xs={1} lg={footerDesktopRowNumber} className="d-print-none">
                    <Col>
                        <FooterNavPathSection
                            paths={supportPaths}
                            contentClassName="footer-support"
                            title={t('menus.footer.support.title')}
                        />
                    </Col>
                    {hasCompanyPaths && (
                        <Col>
                            <FooterNavPathSection paths={companyPaths} title={t('menus.footer.company.title')} />
                        </Col>
                    )}
                    <Col>
                        <FooterNavPathSection paths={paths} title={t('menus.footer.main.title')} />
                    </Col>
                    <Col lg={4}>
                        {hasSocialMediaPaths && (
                            <FooterSection contentClassName="mb-4" title={t('menus.footer.social.title')}>
                                <>
                                    {socialMediaPaths.map(({ link, label }) => (
                                        <SocialMediaIcon
                                            key={`footer-socialmedia-${label}`}
                                            className="footer-content-item d-inline-block"
                                            to={link?.url}
                                            label={label}
                                            dataGALocation="FooterNav"
                                            iconType={label.toLocaleLowerCase()}
                                        />
                                    ))}
                                </>
                            </FooterSection>
                        )}
                        {hasGetAppPaths && (
                            <FooterSection title={t('menus.footer.app.title')} contentClassName="get-the-app">
                                <>
                                    {getAppPaths.map(({ link, label, badgeType }) => (
                                        <Badge
                                            key={`footer-getapp-path-${label}`}
                                            className="footer-content-item"
                                            to={link?.url}
                                            label={label}
                                            dataGALocation="FooterNav"
                                            badgeType={badgeType}
                                        />
                                    ))}
                                </>
                            </FooterSection>
                        )}
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col className="col-12 col-lg-auto">
                        <div className="footer-copyright">
                            {t('menus.footer.legal.title', {
                                yearStart: footerClientCopyrightData[clientId].yearStart,
                                yearEnd: copyrightYear,
                                projectName: footerClientCopyrightData[clientId].businessName
                            })}
                        </div>
                    </Col>
                    <Col className="col-12 col-lg-8 col-xl-9">
                        <div className="d-flex flex-wrap flex-column flex-lg-row">
                            {legalPaths.map(({ link, label }) => (
                                <Link
                                    key={`footer-path-${label}`}
                                    className="mt-4 mb-2 mt-lg-0 text-left"
                                    to={link?.url}
                                    label={label}
                                    variant="footer-legal"
                                    dataGALocation="FooterNav"
                                />
                            ))}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-2 footer-mail-physical-address">
                        {mailingAddress && (
                            <div className="d-inline-block mr-4 mt-2">
                                <small>
                                    <span className="font-weight-bold mr-2 title">
                                        {t('menus.footer.addresses.mailing.title')}
                                    </span>
                                    {mailingAddress}
                                </small>
                            </div>
                        )}
                        {physicalAddress && (
                            <div className="d-inline-block mr-4 mt-2">
                                <small>
                                    <span className="font-weight-bold mr-2 title">
                                        {t('menus.footer.addresses.physical.title')}
                                    </span>
                                    {physicalAddress}
                                </small>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
