import React, { ReactElement } from 'react';

import Facebook from './facebook/facebook';
import Instagram from './instagram/instagram';
import Twitter from './twitter/twitter';
import LinkedIn from './linkedin/linkedin';
import { SocialMediaIconProps } from './social-media-icon.props';

function getSvg(iconType: string, label: string) {
    switch (iconType) {
        case 'facebook':
            return <Facebook label={label} />;
        case 'instagram':
            return <Instagram label={label} />;
        case 'twitter':
            return <Twitter label={label} />;
        case 'linkedin':
            return <LinkedIn label={label} />;
    }
}

const SocialMediaIcon = ({ className, label, to, dataGALocation, iconType }: SocialMediaIconProps): ReactElement => {
    const svgImage = getSvg(iconType, label);

    return (
        <div className={`social-media-icon ${className ?? ''}`}>
            <a
                href={to}
                rel="noopener noreferrer"
                target="_blank"
                data-tagtype="anchorlink"
                data-ga-location={dataGALocation}
            >
                {svgImage}
            </a>
        </div>
    );
};

export default SocialMediaIcon;
