import { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ENABLE_AMPLIFY_CHATBOT, REACT_APP_REGION, REACT_APP_IDENTITY_POOL_ID } from 'gatsby-env-variables';
import { PostTextCommand } from '@aws-sdk/client-lex-runtime-service';
import AWS from 'aws-sdk/global';
import { Amplify } from 'aws-amplify';
import { LexRuntimeServiceClient } from '@aws-sdk/client-lex-runtime-service';

// Account
import { accountGetAwsCredsRoutine } from 'state/account/account.routines';
import {
    accountHasInsuranceSelector,
    accountIsLoggedInSelector,
    accountPlansSelector,
    accountProfileSelector,
    accountStateSelector
} from 'state/account/account.selectors';

// UI Kit & Components
import { Button as ReactButton, Dropdown } from 'react-bootstrap';
import { Message as AmplifyMessage } from 'react-chat-ui';
import ChatIcon from 'assets/icons/chat-icon.inline.svg';
import HamburgerMenu from 'assets/icons/hamburger.inline.svg';
import FillDownCircle from 'assets/icons/fill-down-circle.inline.svg';
import HelpIcon from 'assets/icons/help-icon.inline.svg';
import BirdiLogo from 'assets/icons/pill-logo.inline.svg';
import ThumbsUp from 'assets/icons/thumbs-up.inline.svg';
import ThumbsDown from 'assets/icons/thumbs-down.inline.svg';
import {
    Avatar,
    MainContainer,
    ChatContainer,
    MessageList,
    MessageGroup,
    Message,
    MessageInput,
    TypingIndicator
} from '@chatscope/chat-ui-kit-react';

// Utils
import { RefillRxs } from 'types/order-prescription';
import { removeHtmlTagsFromString } from 'util/string';
import { processCart } from 'util/cart';
import config from 'util/aws-exports';
import { TrackEvent, TrackGenericEvent } from 'util/google_optimize/optimize_helper';

// MedicineCabinet
import { drugSelector } from 'state/drug/drug.selectors';
import { medicineCabinetPrescriptionsSelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';
import { medicineCabinetGetAllPrescriptions } from 'state/medicine-cabinet/medicine-cabinet.routines';

// Cart
import { CartObjectPayload } from 'state/cart/cart.services';
import { startCartRoutine } from 'state/cart/cart.routines';
import { cartItemsSelector } from 'state/cart/cart.selectors';

import './chatbot.style.scss';
interface ChatBotProps {
    uniqueID: string;
    isInCart?: boolean;
}

const Chatbot = ({ uniqueID, isInCart }: ChatBotProps): ReactElement => {
    const dispatch = useDispatch();
    const inputRef = useRef();

    const [sessionAttributes, setSessionAttributes] = useState({});
    const [messages, setMessages] = useState<any[]>([]);
    const [expanded, setExpanded] = useState(false);
    const [userAvatarText, setUserAvatarText] = useState('');
    const [lexRuntimeService, setLexRuntimeService] = useState(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const defaultMessage =
        "Hello! I'm Robin, the Birdi virtual assistant. Simply type your question below or type Robin Help to pick from frequently asked questions.";

    const { token } = useSelector(accountStateSelector);
    const isLoggedIn = useSelector(accountIsLoggedInSelector);
    const profileObject = useSelector(accountProfileSelector);
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const prescriptions = useSelector(medicineCabinetPrescriptionsSelector);
    const { drugDiscountPrices } = useSelector(drugSelector);
    const accountPlans = useSelector(accountPlansSelector);
    const cartItemsObject = useSelector(cartItemsSelector);

    useEffect(() => inputRef.current.focus());

    useEffect(() => {
        const initials = `${profileObject?.patientFirstName ? profileObject?.patientFirstName.charAt(0) : ''}${
            profileObject?.patientLastName ? profileObject?.patientLastName.charAt(0) : ''
        }`;
        setUserAvatarText(initials);
    }, [profileObject]);

    const handleSend = (messageText) => {
        submitMessage(messageText);
    };

    const addMessage = (message: AmplifyMessage) => {
        setMessages((currentMessages: AmplifyMessage[]) => {
            return [...currentMessages, message];
        });
    };

    const clearMessages = () => {
        setMessages([]);
        setInitialMessage();
    };

    const setInitialMessage = () => {
        addMessage(
            new AmplifyMessage({
                id: 1,
                message: {
                    message: defaultMessage
                }
            })
        );
    };

    const submitMessage = async (input: string, addToHistory: boolean = true) => {
        if (input === '') return;
        setIsSubmitting(true);
        const inputToSend = input;
        const userMessage = new AmplifyMessage({
            id: 0,
            message: input
        });

        if (addToHistory) {
            addMessage(userMessage);
        }
        TrackEvent('chatbotQuestion', input);
        await communicateLex(inputToSend, function (data) {
            setIsSubmitting(false);
            const responseMessage = new AmplifyMessage({
                id: 1,
                message: data
            });
            setSessionAttributes(data.sessionAttributes);
            addMessage(responseMessage);
            if (data.sessionAttributes?.trackEvent) {
                const trackEventData = JSON.parse(data.sessionAttributes.trackEvent);
                TrackGenericEvent(trackEventData);
            }
        });
    };

    const getLexExpireTime = async () =>
        new Promise((resolve, reject) => {
            if (lexRuntimeService) {
                lexRuntimeService.config.credentials().then((creds) => {
                    resolve(creds.expireTime);
                });
            } else {
                resolve(undefined);
            }
        });

    const getLex = async () =>
        new Promise((resolve, reject) => {
            const currenTime = new Date();
            getLexExpireTime().then((expireTime) => {
                if (expireTime && currenTime < expireTime) {
                    // credentials are still valid
                    resolve(lexRuntimeService);
                } else {
                    dispatch(
                        accountGetAwsCredsRoutine.trigger({
                            region: REACT_APP_REGION,
                            poolID: REACT_APP_IDENTITY_POOL_ID,
                            onSuccess: (awsCredMessage: string) => {
                                if (awsCredMessage !== 'Credentials already exist') {
                                    AWS.config.credentials.get(function (err) {
                                        if (err) {
                                            console.error('AWS Credentials Error: ', err);
                                            return;
                                        } else {
                                            Amplify.configure(config);
                                            const lex = new LexRuntimeServiceClient({
                                                region: config.aws_cognito_region,
                                                credentials: AWS.config.credentials
                                            });
                                            setLexRuntimeService(lex);
                                            resolve(lex);
                                        }
                                    });
                                } else {
                                    resolve(lexRuntimeService);
                                }
                            }
                        })
                    );
                }
            });
        });

    const communicateLex = async (message, lsdata) => {
        const newSessionAttributes = {
            ...sessionAttributes,
            localTimeZone: JSON.stringify(Intl.DateTimeFormat().resolvedOptions().timeZone),
            isLoggedIn: isLoggedIn ? 'true' : 'false',
            jwtToken: isLoggedIn ? token : '',
            memberType: isLoggedIn ? (accountHasInsurance ? 'INS' : 'BRD') : null,
            // FUTURE WORK (DRX-1607): update QNAClientFilter to dynamically change between "birdi" and "evio"
            //   values when we have a way to determine which site we are working within
            QNAClientFilter: 'birdi',
            trackEvent: undefined
        };
        delete newSessionAttributes.appContext;
        const params = {
            botName: config.aws_bots_config[0].name,
            botAlias: config.aws_bots_config[0].alias,
            userId: uniqueID,
            inputText: removeHtmlTagsFromString(message),
            sessionAttributes: newSessionAttributes,
            activeContexts: [
                {
                    name: 'string',
                    timeToLive: {
                        timeToLiveInSeconds: 600,
                        turnsToLive: 10
                    },
                    parameters: {
                        string: 'string'
                    }
                }
            ]
        };
        const postTextCommand = new PostTextCommand(params);

        getLex().then((lex) => {
            lex.send(postTextCommand)
                .then((data) => {
                    lsdata(data);
                })
                .catch((error) => {
                    lsdata(error);
                });
        });
    };

    const iconicTouch = () => {
        if (messages.length === 0) {
            setInitialMessage();
            submitMessage('help');
        }
        setExpanded(true);
    };
    const minimizeTouch = () => {
        setExpanded(false);
    };

    useEffect(() => {
        if (messages.length > 0) {
            const messageWithCartModel = messages.find((message) => {
                if (typeof message.message === 'object') {
                    return message.message?.sessionAttributes?.cartModel;
                }
            });

            if (messageWithCartModel) {
                const cartModel = JSON.parse(messageWithCartModel.message?.sessionAttributes?.cartModel);
                const rxNumbers: string[] = cartModel.refillRxs.map((rx: RefillRxs) => rx.rxNumber);

                dispatch(
                    startCartRoutine.trigger({
                        rxNumbers,
                        onSuccess: (data: CartObjectPayload) => {
                            dispatch(medicineCabinetGetAllPrescriptions({ showNewRxModal: false }));
                            processCart(data, accountHasInsurance, prescriptions, drugDiscountPrices, accountPlans);
                        }
                    })
                );

                delete messageWithCartModel.message?.sessionAttributes?.cartModel;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItemsObject, dispatch, messages]);

    return (
        <>
            {ENABLE_AMPLIFY_CHATBOT && (
                <div className={`${isInCart ? 'robinChatbot-inCart' : 'robinChatbot'} mr-0 d-print-none`}>
                    <ReactButton
                        aria-label="Open Robin Chatbot"
                        className={`iconcons btn border-0 p-3 bg-cathams-blue no-min-width btn-primary no-min-width ${
                            expanded ? 'd-none' : 'd-inline-block'
                        }`}
                        onClick={iconicTouch}
                    >
                        <ChatIcon />
                    </ReactButton>
                    <div id="botcon" className={expanded ? 'shadow-lg d-flex flex-column' : 'd-none'}>
                        <div className="header bg-cathams-blue p-2">
                            <div className="d-inline-block first">
                                <Dropdown>
                                    <Dropdown.Toggle
                                        id="dropdown-basic"
                                        aria-label="Message options"
                                        className="d-inline-block text-white p-0 rounded-0 bg-transparent border-0 no-min-width"
                                    >
                                        <HamburgerMenu />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="p-0">
                                        <ReactButton
                                            aria-label="Clear chat"
                                            className="no-min-width dropdown-item text-dark rounded-0"
                                            onClick={clearMessages}
                                        >
                                            Clear Chat
                                        </ReactButton>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="d-inline-block title text-white align-bottom ml-2 font-weight-bold headerTitle">
                                CHAT WITH ROBIN
                            </div>
                            <div className="float-right">
                                <ReactButton
                                    aria-label="Help"
                                    className="d-inline-block bg-transparent rounded-0 border-0 ml-2 p-0 pl-3 no-min-width"
                                    onClick={() => submitMessage('help')}
                                >
                                    <HelpIcon />
                                </ReactButton>
                                <ReactButton
                                    aria-label="Close Robin Chatbot"
                                    className="d-inline-block bg-transparent rounded-0 border-0 ml-2 p-0 pl-3 pr-2 no-min-width"
                                    onClick={minimizeTouch}
                                >
                                    <FillDownCircle />
                                </ReactButton>
                            </div>
                        </div>

                        <MainContainer>
                            <ChatContainer>
                                <MessageList typingIndicator={isSubmitting ? <TypingIndicator /> : <></>}>
                                    {messages.map((entry, index) => {
                                        let messageContent: string = '';
                                        let messageModel = {};
                                        if (entry.id === 0) {
                                            messageContent = entry.message;
                                            messageModel = {
                                                type: 'html',
                                                direction: 'outgoing'
                                            };
                                            return (
                                                <Message model={messageModel} key={`message_${index}`}>
                                                    {isLoggedIn && userAvatarText !== '' ? (
                                                        <Avatar>{userAvatarText}</Avatar>
                                                    ) : (
                                                        <Message.Header sender={'You'} />
                                                    )}
                                                    <Message.HtmlContent html={messageContent} />
                                                </Message>
                                            );
                                        } else {
                                            messageContent = entry.message.message;
                                            messageModel = {
                                                type: 'html',
                                                direction: 'incoming'
                                            };

                                            var msg, textmsg;
                                            try {
                                                if (entry.message.sessionAttributes) {
                                                    const appContext = JSON.parse(
                                                        entry.message.sessionAttributes?.appContext.replace('`', '')
                                                    );
                                                    msg = appContext.altMessages.markdown;
                                                    textmsg = msg;
                                                } else {
                                                    msg = entry.message.message.split('Text');
                                                    textmsg = msg[0];
                                                }
                                            } catch (e) {
                                                msg = entry.message.message.split('Text');
                                                textmsg = msg[0];
                                            }
                                            var len = msg.length;
                                            var feedback = null;
                                            var linkcont = null;
                                            var buttons = [];
                                            if (entry.message.responseCard) {
                                                buttons = entry.message.responseCard.genericAttachments[0].buttons;
                                                if (buttons === undefined) buttons = [];
                                            }
                                            if (textmsg.indexOf('Sign in') > 0) {
                                                var links = textmsg.split('1) Sign in -');
                                                textmsg = links[0];
                                                linkcont = <a href={links[1]}>{links[1]}</a>;
                                            }
                                            if (
                                                index + 1 === messages.length &&
                                                textmsg.indexOf('feedback') < 0 &&
                                                index !== 0
                                            )
                                                feedback = (
                                                    <div className="text-right">
                                                        <ReactButton
                                                            className="thumbsup bg-transparent p-0 border-0 no-min-width"
                                                            onClick={() => submitMessage('Thumbs up', false)}
                                                        >
                                                            <ThumbsUp />
                                                        </ReactButton>
                                                        <ReactButton
                                                            className="thumbsdown bg-transparent p-0 border-0 no-min-width"
                                                            onClick={() => submitMessage('Thumbs down', false)}
                                                        >
                                                            <ThumbsDown />
                                                        </ReactButton>
                                                    </div>
                                                );
                                            return (
                                                <MessageGroup
                                                    key={`messagegroup_${index}`}
                                                    direction="incoming"
                                                    sentTime="just now"
                                                    avatarPosition="tl"
                                                >
                                                    <Avatar>
                                                        <BirdiLogo />
                                                    </Avatar>
                                                    <MessageGroup.Messages>
                                                        <Message model={messageModel}>
                                                            <Message.CustomContent>
                                                                <Message.HtmlContent html={textmsg} />
                                                                {linkcont}
                                                                {feedback}
                                                            </Message.CustomContent>
                                                        </Message>
                                                        {buttons.map((element, j) => {
                                                            if (len)
                                                                return (
                                                                    <ReactButton
                                                                        key={j}
                                                                        className="submenu p-2 mt-2 text-uppercase"
                                                                        onClick={() =>
                                                                            submitMessage(element.value, false)
                                                                        }
                                                                        vals={element.value}
                                                                        disabled={index + 1 !== messages.length}
                                                                    >
                                                                        {element.text}
                                                                    </ReactButton>
                                                                );
                                                        })}
                                                    </MessageGroup.Messages>
                                                </MessageGroup>
                                            );
                                        }
                                    })}
                                </MessageList>
                                <MessageInput
                                    attachButton={false}
                                    placeholder={'Type here'}
                                    onSend={handleSend}
                                    disabled={isSubmitting}
                                    ref={inputRef}
                                />
                            </ChatContainer>
                        </MainContainer>
                    </div>
                </div>
            )}
        </>
    );
};

export default Chatbot;
