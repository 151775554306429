import React from 'react';
import { Location } from '@reach/router';
import { v4 as uuidv4 } from 'uuid';

// Components
import ChatBot from '../chatbot/chatbot.component';
import SidebarColumnMobile from 'components/sidebar-column/sidebar-column-mobile.component';

// Used within gatsby-browser.js to keep the expanded state across pages
const MenuWrapper = ({ children }: any) => {
    const userId = uuidv4();

    return (
        <Location>
            {({ location }) => {
                const isInNewMedicineCabinet = location.pathname.includes('/new-medicine-cabinet');

                return (
                    <>
                        {children}
                        {isInNewMedicineCabinet ? <SidebarColumnMobile /> : <ChatBot uniqueID={userId} />}
                    </>
                );
            }}
        </Location>
    );
};

export default MenuWrapper;
