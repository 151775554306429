import { put, takeLatest, all, call, select } from 'redux-saga/effects';
import { AWS_CONFIG_REGION, AWS_CONFIG_POOLID } from 'gatsby-env-variables';
import { PayloadAction } from '@reduxjs/toolkit';

import {
    drugListRoutine,
    drugLookupRoutine,
    drugDetailsLookupRoutine,
    drugFormLookupRoutine,
    drugDiscountPriceRoutine,
    drugDescriptionRoutine
} from 'state/drug/drug.routines';
import DrugService, {
    drugDiscountPriceRoutinePayload,
    drugLookupRoutinePayload,
    drugDetailsRoutinePayload,
    drugDescriptionRoutinePayload,
    drugListRoutinePayload
} from 'state/drug/drug.services';
import {
    accountProfileSelector,
    accountHasInsuranceSelector,
    accountAcCodeSelector,
    accountIsLoggedInSelector
} from 'state/account/account.selectors';
import AccountService, { ProfileObjectPayload } from 'state/account/account.services';
import { PrescriptionObjectPayload } from 'state/medicine-cabinet/medicine-cabinet.services';

import { baseEffectHandler } from 'util/sagas/sagas';
import { TrackError } from 'util/google_optimize/optimize_helper';

export default function* drugSaga() {
    yield takeLatest(drugListRoutine.TRIGGER, function* (action: PayloadAction<drugListRoutinePayload>) {
        const insuranceName: string = yield select(accountAcCodeSelector);
        // First, get AWS credentials.
        yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

        // Now perform the drug lookup.
        yield baseEffectHandler({
            service: DrugService.drugList().get,
            data: {
                clientAcCode: insuranceName
            },
            *onResponse(response) {
                // Dispatch the success action in order to update the state of
                // the lookup component and show the returned results.
                yield put(drugListRoutine.success({ insuranceName: insuranceName, formulary: response }));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess(response);
            },
            *onError(error) {
                if (error.response?.data?.message) {
                    // This is an attempt to catch errors passed back from the API.
                    TrackError('drug.sagas.ts', 'drugListRoutine', error.response.data.message);
                } else if (error.message) {
                    // This is an attempt to catch basic network errors.
                    TrackError('drug.sagas.ts', 'drugListRoutine', error.message);
                }
                // Dispatch the failure action to update the state of the lookup
                // component and hide the dropdown.
                yield put(drugListRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });
    yield takeLatest(drugLookupRoutine.TRIGGER, function* (action: PayloadAction<drugLookupRoutinePayload>) {
        const insuranceName: string = yield select(accountAcCodeSelector);
        // First, get AWS credentials.
        yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

        // Now perform the drug lookup.
        yield baseEffectHandler({
            service: DrugService.drugLookup().get,
            data: {
                drugName: action.payload.drugName,
                clientAcCode: insuranceName
            },
            *onResponse(response) {
                // Dispatch the success action in order to update the state of
                // the lookup component and show the returned results.
                yield put(drugLookupRoutine.success(response));
            },
            *onError(error) {
                if (error.response?.data?.message) {
                    // This is an attempt to catch errors passed back from the API.
                    TrackError('drug.sagas.ts', 'drugLookupRoutine', error.response.data.message);
                } else if (error.message) {
                    // This is an attempt to catch basic network errors.
                    TrackError('drug.sagas.ts', 'drugLookupRoutine', error.message);
                }
                // Dispatch the failure action to update the state of the lookup
                // component and hide the dropdown.
                yield put(drugLookupRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });
    yield takeLatest(drugDetailsLookupRoutine.TRIGGER, function* (action: PayloadAction<drugDetailsRoutinePayload>) {
        const insuranceName: string = yield select(accountAcCodeSelector);
        // First, get AWS credentials.
        yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

        // Now lookup the drug details.
        yield baseEffectHandler({
            service: DrugService.drugDetailsLookup().get,
            data: {
                drugName: action.payload.drugName,
                gpi: action.payload.gpi,
                clientAcCode: insuranceName
            },
            *onResponse(response) {
                // Dispatch the success action in order to add the drug
                // details to state and update the drug lookup status.
                yield put(drugDetailsLookupRoutine.success(response));

                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess(response.dosageForms);
            },
            *onError(error) {
                if (error.response?.data?.message) {
                    // This is an attempt to catch errors passed back from the API.
                    TrackError('drug.sagas.ts', 'drugDetailsLookupRoutine', error.response.data.message);
                } else if (error.message) {
                    // This is an attempt to catch basic network errors.
                    TrackError('drug.sagas.ts', 'drugDetailsLookupRoutine', error.message);
                }
                // Dispatch the failure action to set the lookup status back to
                // IDLE.
                yield put(drugDetailsLookupRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });
    yield takeLatest(drugFormLookupRoutine.TRIGGER, function* (action: PayloadAction<drugDetailsRoutinePayload>) {
        const insuranceName: string = yield select(accountAcCodeSelector);
        // First, get AWS credentials.
        yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

        // Now lookup the drug forms.
        yield baseEffectHandler({
            service: DrugService.drugFormLookup().get,
            data: {
                drugName: action.payload.drugName,
                gpi: action.payload.gpi,
                clientAcCode: insuranceName
            },
            *onResponse(response) {
                // Dispatch the success action in order to add the drug
                // details to state and update the drug lookup status.
                yield put(drugFormLookupRoutine.success(response));

                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess(response.dosageForms);
            },
            *onError(error) {
                if (error.response?.data?.message) {
                    // This is an attempt to catch errors passed back from the API.
                    TrackError('drug.sagas.ts', 'drugFormLookupRoutine', error.response.data.message);
                } else if (error.message) {
                    // This is an attempt to catch basic network errors.
                    TrackError('drug.sagas.ts', 'drugFormLookupRoutine', error.message);
                }
                // Dispatch the failure action to set the lookup status back to
                // IDLE.
                yield put(drugFormLookupRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });
    yield takeLatest(
        drugDiscountPriceRoutine.TRIGGER,
        function* (action: PayloadAction<drugDiscountPriceRoutinePayload>) {
            const profileObject: ProfileObjectPayload = yield select(accountProfileSelector);
            const insuranceName: string = yield select(accountAcCodeSelector);
            const hasInsurance: boolean = yield select(accountHasInsuranceSelector);
            const isLoggedIn: boolean = yield select(accountIsLoggedInSelector);
            const { prescriptions, forceBirdiInsurance, location } = action.payload;

            // This action is always called following medicineCabinetGetAllPrescriptions.trigger,
            // but that action does not *always* run after the profileObject has been loaded.
            // So just check to make sure that profileObject exists before proceeding.
            // Whatever is dispatching this action is responsible for making sure that it
            // does so after the profileObject has been loaded.
            // Also, don't run this action if the user does have insurance.
            if (isLoggedIn && (!profileObject || (hasInsurance && !forceBirdiInsurance))) {
                return;
            }

            // Fetch the AWS credentials.
            yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

            // Call the drug discount price lookup for each of the drugs in parallel.
            const simultaneousRequests: number = 9;
            for (let i = 0; i < prescriptions.length; i += simultaneousRequests) {
                const rxSlice = prescriptions.slice(i, i + simultaneousRequests);
                yield all(
                    rxSlice.map((item: PrescriptionObjectPayload) => {
                        let drugCode =
                            item.dispensedProductNumber !== ''
                                ? item.dispensedProductNumber
                                : item.writtenProductNumber !== ''
                                ? item.writtenProductNumber
                                : '';

                        // The following throws a TypeScript error ("No overload
                        // matches this call"). Not convinced that this is a legitimate
                        // issue.
                        // @ts-ignore
                        return call(baseEffectHandler, {
                            service: DrugService.drugDiscountPriceLookup().get,
                            data: {
                                drugCode,
                                quantity: item.fillQuantity,
                                daysSupply: item.fillDaysSupply,
                                clientAcCode: forceBirdiInsurance ? 'BRD01' : insuranceName,
                                memberNumber: isLoggedIn ? profileObject.cardholderID : '',
                                location: location
                            },
                            *onResponse(response) {
                                yield put(
                                    drugDiscountPriceRoutine.success({ response: response, rxNumber: item.rxNumber })
                                );
                                const { onSuccess } = action.payload;
                                if (onSuccess) onSuccess({ response: response, rxNumber: item.rxNumber });
                            },
                            *onError(error) {
                                yield put(drugDiscountPriceRoutine.failure(item.rxNumber));
                                const { onFailure } = action.payload;
                                if (onFailure) onFailure({ response: error });
                                // Log the error.
                                if (error.response?.data) {
                                    // This is an attempt to catch errors passed back from the API.
                                    if (error.response?.data?.status) {
                                        TrackError(
                                            'drug.sagas.ts',
                                            'drugDiscountPriceRoutine',
                                            error.response.data.status
                                        );
                                    } else {
                                        TrackError(
                                            'drug.sagas.ts',
                                            'drugDiscountPriceRoutine',
                                            error.response.data.message
                                        );
                                    }
                                } else if (error.message) {
                                    // This is an attempt to catch basic network errors.
                                    TrackError('drug.sagas.ts', 'drugDiscountPriceRoutine', error.message);
                                } else {
                                    TrackError('drug.sagas.ts', 'drugDiscountPriceRoutine', error);
                                }
                            }
                        });
                    })
                );
            }
        }
    );
    yield takeLatest(drugDescriptionRoutine.TRIGGER, function* (action: PayloadAction<drugDescriptionRoutinePayload>) {
        // First, get AWS credentials.
        yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

        // Now lookup the drug description.
        yield baseEffectHandler({
            service: DrugService.drugDescriptionLookup().get,
            data: action.payload,
            *onResponse(response) {
                // Dispatch the success action in order to add the drug
                // description to state.
                if (response.htmlDesc) {
                    yield put(drugDescriptionRoutine.success(response));
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess(response);
                } else {
                    // If the htmlDesc is not available in the response for
                    // whatever reason, dispatch the failure action.
                    yield put(drugDescriptionRoutine.failure());
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                }
            },
            *onError(error) {
                if (error.response?.data?.message) {
                    // This is an attempt to catch errors passed back from the API.
                    TrackError('drug.sagas.ts', 'drugDescriptionRoutine', error.response.data.message);
                } else if (error.message) {
                    // This is an attempt to catch basic network errors.
                    TrackError('drug.sagas.ts', 'drugDescriptionRoutine', error.message);
                }
                // Dispatch the failure action.
                yield put(drugDescriptionRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });
}
