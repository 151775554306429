/* eslint-disable no-unused-vars */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { AccountState } from 'state/account/account.reducers';
import { ConfirmationStatuses } from 'components/verify-sms-modal/types';

export const accountStateSelector = (state: RootState): AccountState => state.accountReducer;

export const accountRegistationSelector = createSelector(accountStateSelector, (account) => account.registration);
export const accountProfileSelector = createSelector(accountStateSelector, (account) => account.profileObject);
export const accountPlansSelector = createSelector(accountStateSelector, (account) => account.plansObject);
export const accountProfileAddressesSelector = createSelector(accountProfileSelector, (profile) =>
    profile && profile.addresses ? profile?.addresses : []
);
export const accountIsLoggedInSelector = createSelector(accountStateSelector, (account) => {
    const now = Date.now();
    return !account.isLoggingOut &&
        account.token &&
        account.issued &&
        account.expires &&
        account.sessionExpiration &&
        now < account.expires &&
        now < account.sessionExpiration
        ? true
        : false;
});
export const accountHasInsuranceSelector = createSelector(accountProfileSelector, (profile) => {
    return profile && profile?.cardholderID && !profile?.cardholderID.includes('BRD') ? true : false;
});
export const accountAcCodeSelector = createSelector(accountProfileSelector, (profile) => {
    let insuranceName = 'BRD01';
    // If the user is logged in. For non-insured users, this will still resolve to
    // 'BRD01', the same as the default value for insuranceName.
    if (profile && profile?.insuranceName) {
        insuranceName = profile?.insuranceName.split('-')[0].trim();
    }
    return insuranceName;
});
export const accountHealthConditionsSelector = createSelector(
    accountStateSelector,
    ({ healthConditions }: AccountState) => ({
        healthConditions
    })
);

export const accountAllergiesSelector = createSelector(
    accountStateSelector,
    (healthConditions) => healthConditions.healthConditions.allergies
);

export const accountMedicalConditionsSelector = createSelector(
    accountStateSelector,
    (healthConditions) => healthConditions.healthConditions.medicalConditions
);

export const accountWebProfileSelector = createSelector(accountStateSelector, (account) => account.webProfile);
export const accountChangedPasswordSelector = createSelector(
    accountStateSelector,
    (account) => account.passwordChangeSuccess
);

export const accountIsSubmittingPasswordResetSelector = createSelector(
    accountStateSelector,
    (account) => account.isSubmittingPasswordReset
);

export const accountCreditCardsSelector = createSelector(accountStateSelector, (account) => account.creditCards);
export const accountRepresentativesSelector = createSelector(
    accountStateSelector,
    (account) => account.representatives
);
export const paymetricDetailsSelector = createSelector(accountStateSelector, (account) => account.paymetricDetails);
export const paymetricTokenizedCardSelector = createSelector(accountStateSelector, (account) => account.pciToken);
export const accountContactUsSelector = createSelector(accountStateSelector, (account) => account.contactUs);
export const accountNotificationsSelector = createSelector(accountStateSelector, (account) => account.notifications);
export const accountMessagesSelector = createSelector(accountStateSelector, (account) => account.messages);
export const accountAcknowledgementSelector = createSelector(
    accountStateSelector,
    (account) => account.acknowledgement
);
export const accountHIPAASelector = createSelector(accountStateSelector, (account) => account.HIPAA);
export const accountAcknowledgementCurrentVIDSelector = createSelector(
    accountStateSelector,
    (account) => account?.currentVid
);

export const currentMessageThreadSelector = createSelector(
    accountMessagesSelector,
    (message) => message?.messageThread
);

export const accountSmsPendingRequestsSelector = createSelector(
    accountStateSelector,
    (account) => account.pendingSmsOptIns
);

export const accountSmsRequestsVerifiedSelector = createSelector(
    [accountSmsPendingRequestsSelector, accountStateSelector],
    (pendingOptIns, account) => {
        const anyPendingRequests = pendingOptIns.filter((optIn) => optIn.status !== ConfirmationStatuses.Confirmed);
        return account.pendingSmsOptInsLoaded && anyPendingRequests.length === 0;
    }
);

export const accountSmsRequestsPendingSelector = createSelector(
    [accountSmsPendingRequestsSelector, accountStateSelector],
    (pendingOptIns, account) => {
        const anyPendingRequests = pendingOptIns.filter((optIn) => optIn.status === ConfirmationStatuses.NoChange);
        return account.pendingSmsOptInsLoaded && anyPendingRequests.length > 0;
    }
);

export const accountSmsPendingRequestsLoadedSelector = createSelector(
    accountStateSelector,
    (account) => account.pendingSmsOptInsLoaded
);

export const accountSmsCheckingStatusSelector = createSelector(
    accountStateSelector,
    (account) => account.checkingSmsStatus
);

export const accountSmsCancelingReqeustSelector = createSelector(
    accountStateSelector,
    (account) => account.cancelingSmsRequest
);

export const accountSmsRequestInitiatedSelector = createSelector(accountSmsPendingRequestsSelector, (pendingOptIns) => {
    const timeRequestInitiated = pendingOptIns.map((smsRequest) => smsRequest.TimeInitiated);
    if (timeRequestInitiated.length > 0) {
        const date = new Date(timeRequestInitiated[timeRequestInitiated.length - 1]);
        var userTimezoneOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() - userTimezoneOffset).getTime();
    }
    return Date.now();
});

export const accountSmsRequestsNotVerifiedSelector = createSelector(
    [accountSmsPendingRequestsSelector, accountStateSelector],
    (pendingOptIns, account) => {
        const anyCanceledRequests = pendingOptIns.filter((optIn) =>
            [ConfirmationStatuses.Denied, ConfirmationStatuses.Expired].includes(optIn.status)
        );
        return account.pendingSmsOptInsLoaded && anyCanceledRequests.length > 0;
    }
);

export const accountUnauthenticatedSessionExpirationSelector = createSelector(
    accountStateSelector,
    (account) => account.unauthenticatedSesssionExpiration
);

export const accountAllSmsNumbersSelector = createSelector(accountNotificationsSelector, (notifications) => {
    return [
        notifications?.MarketingTextNumber,
        notifications?.NewScriptTextNumber,
        notifications?.RefillReminderTextNumber,
        notifications?.OrderShippedTextNumber
    ];
});

export const accountUserIpAddressSelector = createSelector(accountStateSelector, (account) => account.userIpAddress);
