import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from '@reach/router';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { ENABLE_MAINTENANCE_MODE, BIRDI_IP_ADDRESSES } from 'gatsby-env-variables';

import Spinner from 'ui-kit/spinner/spinner';

import {
    accountIsLoggedInSelector,
    accountProfileSelector,
    accountHasInsuranceSelector,
    accountAcknowledgementSelector,
    accountHIPAASelector,
    accountUserIpAddressSelector
} from 'state/account/account.selectors';
import { accountGetUserIpAddressRoutine, accountSetAcknowledgementRoutine } from 'state/account/account.routines';
import useSecurePage from 'hooks/useSecurePage';
import { accountFetchProfileRoutine } from 'state/account/account.routines';
import { AcknowledgementPayload } from 'state/account/account.services';
import AcknowledgementModal from 'components/acknowledgement/acknowledgement-modal/acknowledgement-modal';
import { closeModal, openModal, setBusyModal } from 'state/birdi-modal/birdi-modal.reducers';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { getClientIp } from 'const/options';
import { logout } from 'state/account/account.reducers';

export interface WithSecurityProps {
    isLoggedIn: boolean;
    isSecurePage: boolean;
}

const withSecurity = (Component: any) => (props: any) => {
    const isLoggedIn = useSelector(accountIsLoggedInSelector);
    const { isSecurePage } = useSecurePage();
    const profileObject = useSelector(accountProfileSelector);
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const accountAcknowledgement: AcknowledgementPayload | undefined = useSelector(accountAcknowledgementSelector);
    const accountHIPAA: boolean | undefined = useSelector(accountHIPAASelector);
    const accountUserIpAddress = useSelector(accountUserIpAddressSelector);
    const location = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const pageData = useStaticQuery(graphql`
        query {
            termsOfService: allNodeLandingPage(filter: { path: { alias: { eq: "/website-terms-of-use" } } }) {
                nodes {
                    drupal_internal__vid
                }
            }
        }
    `);

    const currentVID = pageData?.termsOfService ? pageData?.termsOfService.nodes[0]?.drupal_internal__vid / 100 : 0;

    const showAcknowledmentModal = (acceptPayload: any) => {
        acceptPayload.onSuccess = () => {
            dispatch(setBusyModal(false));
            dispatch(closeModal({}));
        };

        dispatch(
            openModal({
                className: 'acknowledgement-modal-content',
                showClose: false,
                bodyContent: <AcknowledgementModal />,
                backdrop: 'static',
                ctas: [
                    {
                        async: true,
                        label: t('components.acknowledgement.labels.agree'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(setBusyModal(true));
                            dispatch(accountSetAcknowledgementRoutine.trigger(acceptPayload));
                        },
                        dataGALocation: 'AcknowledgementModal'
                    },
                    {
                        label: t('components.acknowledgement.labels.decline'),
                        variant: 'text',
                        className: 'mt-2 mt-lg-4',
                        onClick: () => {
                            dispatch(logout({}));
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'AcknowledgementModal'
                    }
                ]
            })
        );
    };

    useEffect(() => {
        if (ENABLE_MAINTENANCE_MODE && !accountUserIpAddress) {
            dispatch(accountGetUserIpAddressRoutine.trigger());
        }
    }, [accountUserIpAddress, dispatch]);

    useEffect(() => {
        if (isLoggedIn && profileObject === undefined) {
            dispatch(accountFetchProfileRoutine.trigger());
        }
        if (typeof window !== 'undefined' && window.dataLayer) {
            let trackingPayload = {
                event: 'pageview',
                logged_in: isLoggedIn
            };

            if (!isLoggedIn) {
                window.dataLayer.push(trackingPayload);
            }
            if (isLoggedIn && profileObject !== undefined) {
                trackingPayload.user_type = isLoggedIn ? (accountHasInsurance ? 'funded' : 'cash-card') : null;
                window.dataLayer.push(trackingPayload);
            }
        }
    }, [isLoggedIn, profileObject, dispatch]);

    useEffect(() => {
        if (isLoggedIn && profileObject && accountAcknowledgement !== undefined && accountHIPAA !== undefined) {
            let acknowledgementVerion = 0;
            if (accountAcknowledgement.Acknowledgements && accountAcknowledgement.Acknowledgements.length > 0) {
                acknowledgementVerion = accountAcknowledgement.Acknowledgements[0].Version
                    ? accountAcknowledgement.Acknowledgements[0].Version
                    : 0;
            }
            const acceptedHIPAA = accountHIPAA ? accountHIPAA : false;
            if (!acknowledgementVerion || currentVID > acknowledgementVerion || !acceptedHIPAA) {
                getClientIp().then((res) => {
                    showAcknowledmentModal({
                        patientId: profileObject.epostPatientNum,
                        clientIP: res.toString(),
                        currentVID: currentVID > acknowledgementVerion ? currentVID : undefined
                    });
                });
            }
        }
    }, [isLoggedIn, profileObject, accountAcknowledgement, accountHIPAA]);

    const shouldRedirectToMaintenancePage = useMemo(() => {
        return (
            ENABLE_MAINTENANCE_MODE &&
            typeof window !== 'undefined' &&
            window.location.pathname !== '/maintenance' &&
            typeof BIRDI_IP_ADDRESSES === 'object' &&
            !!accountUserIpAddress &&
            !BIRDI_IP_ADDRESSES.includes(accountUserIpAddress)
        );
    }, [accountUserIpAddress]);

    if (shouldRedirectToMaintenancePage) {
        navigate('/maintenance');
        return null;
    } else if (isSecurePage && !isLoggedIn && typeof window !== 'undefined') {
        navigate('/sign-in?redirect=' + location.pathname + encodeURIComponent(location.search));
        return null;
    } else {
        const hideSpinner: boolean = !isSecurePage || (isSecurePage && isLoggedIn && profileObject !== undefined);
        return (
            <>
                <Spinner isVisible={!hideSpinner} t={t} />
                <Component {...props} isSecurePage={isSecurePage} isLoggedIn={isLoggedIn} />
            </>
        );
    }
};

export default withSecurity;
