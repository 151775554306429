//
// --- Types ---

export type ClientIdKey = keyof typeof CLIENT_ID;
export type ClientId = typeof CLIENT_ID[ClientIdKey];

//
// --- Constants ---

export const CLIENT_ID = {
    BIRDI: 'birdi',
    EVIO: 'evio'
} as const;
