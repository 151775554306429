import { useTranslation } from 'react-i18next';
import { MutableRefObject } from 'react';

// UI Kit & Components
import EmptyAlertBox from 'components/new-medicine-cabinet-cart/empty-alert-box/empty-alert-box';
import CreditCardIcon from 'ui-kit/icons/creditcard-icon/creditcard-icon';

// Uitils
import { ellipsify, lowercaseAndCapitalize } from 'util/cart';

// Account
import { CreditCardPayload } from 'state/account/account.services';

import './selected-payment.style.scss';
interface SelectedPaymentProps {
    selectedPaymentMethod?: CreditCardPayload;
    creditCardsData?: CreditCardPayload[];
    isInnerWidthViewport?: MutableRefObject<boolean>;
}

const SelectedPayment: React.FC<SelectedPaymentProps> = (props) => {
    const { selectedPaymentMethod, creditCardsData, isInnerWidthViewport } = props;

    const { t } = useTranslation();

    const hasPaymentData = creditCardsData && creditCardsData.length > 0;

    return (
        <>
            {hasPaymentData ? (
                <>
                    {selectedPaymentMethod && (
                        <div className="selected-payment">
                            <div className="selected-payment-type">
                                {t(
                                    `components.medicineCabinetCart.paymentMethod.${
                                        selectedPaymentMethod.defaultCard ? 'primaryPayment' : 'secondaryPayment'
                                    }`
                                )}
                            </div>
                            <div className="selected-payment-info">
                                <div className="selected-payment-info-text">
                                    <p>{`${
                                        isInnerWidthViewport?.current
                                            ? ellipsify(lowercaseAndCapitalize(selectedPaymentMethod.cardType))
                                            : selectedPaymentMethod.cardType
                                    } ···· ${selectedPaymentMethod.secureCardNumber.slice(-4)}`}</p>
                                    <p>{` ${selectedPaymentMethod.cardName}`}</p>
                                    <p>{`${selectedPaymentMethod.cardMonthNum} / ${selectedPaymentMethod.cardYear.slice(
                                        -2
                                    )}`}</p>
                                </div>
                                <CreditCardIcon
                                    className="payment-card__credit-card-icon"
                                    variant={selectedPaymentMethod.cardType}
                                />
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <EmptyAlertBox text={t('pages.cart.emptyPaymentMethod')} />
            )}
        </>
    );
};

export default SelectedPayment;
