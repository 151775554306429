import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// UI Kit & Components
import Button from 'ui-kit/button/button';
import ColumnSectionEditModeToggle, {
    ColumnSectionEditModeToggleRef
} from 'components/sidebar-column/column-section-toggle/column-section-toggle.component';
import EmptyAlertBox from 'components/new-medicine-cabinet-cart/empty-alert-box/empty-alert-box';

// Mpdal
import { HealthConditionsWarningModalContent } from 'pages/secure/profile/health-profile';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';

// Account, Health Conditions and Profile
import { accountFetchHealthConditionsRoutine } from 'state/account/account.routines';
import { useHealthConditions } from 'hooks/useHealthConditions';
import { HealthConditionPills } from 'components/health-conditions/health-conditions.component';
import { HealthProfileBubbleUpdateEvent } from 'components/health-profile/health-profile.props';
import {
    HealthConditionsModalContent,
    AllergiesModalContent
} from 'components/health-profile/health-profile.component';

import './health-profile.style.scss';

interface HealthConditions {
    allergyChoices: (string | undefined)[];
    existingAllergies: string[];
    existingFreeformAllergies: string;
    conditionChoices: (string | undefined)[];
    existingConditions: string[];
    existingFreeformConditions: string;
    userHasNotSubmittedAllergies: boolean;
    userHasNotSubmittedConditions: boolean;
    ePostPatientNum: string;
}

export interface HealthProfileProps {
    className?: string;
    healthConditions: HealthConditions;
    includeAccordionSectionTitlePillIcon?: boolean;
    onUpdateHealthConditions: (e: HealthProfileBubbleUpdateEvent) => void;
}

const HealthProfile: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const columnSectionRef = useRef<ColumnSectionEditModeToggleRef>(null);
    const healthConditions = useHealthConditions();
    const { existingAllergies, existingConditions, userHasNotSubmittedConditions, userHasNotSubmittedAllergies } =
        healthConditions;

    const handleUpdateConditionsClick = ({ action, update }: HealthProfileBubbleUpdateEvent) => {
        dispatch(
            openModal({
                showClose: true,
                type: 'danger',
                size: 'lg',
                headerContent: <BirdiModalHeaderDanger headerText={t('modals.healthConditions.title')} icon="alert" />,
                bodyContent: <HealthConditionsWarningModalContent translation={t} />,
                ctas: [
                    {
                        label: t('modals.healthConditions.submit'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                            dispatch(action(update));
                            if (columnSectionRef.current) {
                                columnSectionRef.current.handleSaveChangesButtonClick();
                            }
                        },
                        dataGALocation: 'HealthProfileWarning'
                    }
                ]
            })
        );
    };

    const handleToggleHealthConditionsClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: (
                    <HealthConditionsModalContent
                        title={t('modals.prescription.addHealthCondition.title')}
                        subTitle={t('modals.prescription.addHealthCondition.subTitle', {
                            phoneNumber: t('modals.healthConditions.phoneNumber')
                        })}
                        onUpdateHealthConditions={handleUpdateConditionsClick}
                        submitLabel={t('modals.prescription.addHealthCondition.submit')}
                    />
                ),
                ctas: []
            })
        );
    };

    const handleToggleAllergiesClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: (
                    <AllergiesModalContent
                        title={t('modals.prescription.addAllergy.title')}
                        subTitle={t('modals.prescription.addAllergy.subTitle', {
                            phoneNumber: t('modals.healthConditions.phoneNumber')
                        })}
                        onUpdateHealthConditions={handleUpdateConditionsClick}
                        freeformConditionsLabel={t('components.healthConditions.labels.freeformAllergiesLabel')}
                        submitLabel={t('modals.prescription.addAllergy.submit')}
                    />
                ),
                ctas: []
            })
        );
    };

    // Fetch health conditions
    useEffect(() => {
        dispatch(accountFetchHealthConditionsRoutine.trigger());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const healthProfileContent = (
        <div className="health-profile">
            <div className="health-profile__intro">{t('components.medicineCabinetCart.healthProfile.intro')}</div>
            {(userHasNotSubmittedConditions || userHasNotSubmittedAllergies) && (
                <EmptyAlertBox text={t('components.medicineCabinetCart.healthProfile.emptyHealthConditions')} />
            )}
            <div className="health-profile__conditions">
                <h5 className="health-profile__conditions-title">
                    {t('components.medicineCabinetCart.healthProfile.healthConditions')}
                </h5>
                {!userHasNotSubmittedConditions && (
                    <div className="health-profile__conditions-details">
                        <HealthConditionPills conditions={existingConditions} />
                    </div>
                )}
                <div className="health-profile__conditions-actions">
                    <Button
                        plusIcon
                        IconType="secondary"
                        className="column-section__button"
                        variant="text-blue-light"
                        label={t('components.medicineCabinetCart.healthProfile.addHealthConditions')}
                        type="button"
                        onClick={handleToggleHealthConditionsClick}
                    />
                </div>
            </div>
            <div className="health-profile__conditions">
                <h5 className="health-profile__conditions-title">
                    {t('components.medicineCabinetCart.healthProfile.allergies')}
                </h5>
                {!userHasNotSubmittedAllergies && (
                    <div className="health-profile__conditions-details">
                        <HealthConditionPills conditions={existingAllergies} />
                    </div>
                )}
                <div className="health-profile__conditions-actions">
                    <Button
                        plusIcon
                        IconType="secondary"
                        className="column-section__button"
                        variant="text-blue-light"
                        label={t('components.medicineCabinetCart.healthProfile.addAllergies')}
                        type="button"
                        onClick={handleToggleAllergiesClick}
                    />
                </div>
            </div>
        </div>
    );

    return (
        <ColumnSectionEditModeToggle
            ref={columnSectionRef}
            bodyClassName="health-profile__content"
            className="health-profile"
            editModeContent={healthProfileContent}
            headerClassName="health-profile__header"
            title={t('components.medicineCabinetCart.healthProfile.title')}
            chevronButton={true}
        />
    );
};
export default HealthProfile;
