import axiosClient from 'util/axiosClient';
import { EASY_REFILL_API_URL } from 'gatsby-env-variables';
import {
    AuthenticateUserRequest,
    AuthenticateUserResponse,
    CreateOrUpdateRequest,
    CreateOrUpdateResponse,
    EasyRefillResponse,
    EasyRefillSubmitOrderRequest,
    EasyRefillSubmitOrderResponse,
    GetEasyRefillAddressDataResponse,
    GetEasyRefillPatientDataResponse,
    GetEasyRefillPaymentCardDataResponse,
    VerifyUserRequest,
    VerifyUserResponse
} from 'types/easy-refill';

export class EasyRefillService {
    static verifyUser() {
        return {
            post: async (payload: VerifyUserRequest): Promise<VerifyUserResponse> => {
                const response = await axiosClient.post(`${EASY_REFILL_API_URL}/Authentication/VerifyUser`, payload);
                return response.data;
            }
        };
    }
    static authenticateUser() {
        return {
            post: async (payload: AuthenticateUserRequest): Promise<AuthenticateUserResponse> => {
                const response = await axiosClient.post(`${EASY_REFILL_API_URL}/Authentication/Authenticate`, payload);
                return response.data;
            }
        };
    }
    static getSecretKeyHash() {
        return {
            get: async (payload: string): Promise<string> => {
                const response = await axiosClient.get(
                    `${EASY_REFILL_API_URL}/Authentication/CreateSecretKeyHash?securityToken=${payload}`
                );
                return response.data;
            }
        };
    }
    static getPatientData() {
        return {
            get: async (bearerToken: string): Promise<GetEasyRefillPatientDataResponse> => {
                const response = await axiosClient.get(`${EASY_REFILL_API_URL}/EasyRefill/GetEasyRefillPatientData`, {
                    headers: { Authorization: `Bearer ${bearerToken}` }
                });
                return response.data;
            }
        };
    }
    static getPatientAddresses() {
        return {
            get: async (bearerToken: string): Promise<GetEasyRefillAddressDataResponse> => {
                const response = await axiosClient.get(`${EASY_REFILL_API_URL}/EasyRefill/GetAddresses`, {
                    headers: { Authorization: `Bearer ${bearerToken}` }
                });
                return response.data;
            }
        };
    }
    static getPatientPaymentCard() {
        return {
            get: async (bearerToken: string): Promise<GetEasyRefillPaymentCardDataResponse> => {
                const response = await axiosClient.get(`${EASY_REFILL_API_URL}/EasyRefill/GetPaymentCards`, {
                    headers: { Authorization: `Bearer ${bearerToken}` }
                });
                return response.data;
            }
        };
    }
    static createOrder() {
        return {
            post: async (payload: CreateOrUpdateRequest & { bearerToken: string }): Promise<CreateOrUpdateResponse> => {
                const response = await axiosClient.post(
                    `${EASY_REFILL_API_URL}/EasyRefill/CreateOrUpdateOrder`,
                    { rxNumbers: payload.rxNumbers },
                    {
                        headers: { Authorization: `Bearer ${payload.bearerToken}` }
                    }
                );
                return response.data;
            }
        };
    }
    static submitOrder() {
        return {
            post: async (
                payload: EasyRefillSubmitOrderRequest & { bearerToken: string }
            ): Promise<EasyRefillSubmitOrderResponse> => {
                const response = await axiosClient.post(`${EASY_REFILL_API_URL}/EasyRefill/SubmitOrder`, payload, {
                    headers: { Authorization: `Bearer ${payload.bearerToken}` }
                });
                return response.data;
            }
        };
    }
    static cancelOrder() {
        return {
            post: async (payload: { bearerToken: string }): Promise<EasyRefillResponse> => {
                const response = await axiosClient.post(
                    `${EASY_REFILL_API_URL}/EasyRefill/CancelOrder`,
                    {},
                    {
                        headers: { Authorization: `Bearer ${payload.bearerToken}` }
                    }
                );
                return response.data;
            }
        };
    }
}
