import React from 'react';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal, ModalCTAButton } from 'state/birdi-modal/birdi-modal.reducers';
import {
    birdiModalClassnameSelector,
    birdiModalHeaderContentSelector,
    birdiModalContentSelector,
    birdiModalOnCloseSelector,
    birdiModalOpenSelector,
    birdiModalShowCloseSelector,
    birdiModalBusySelector,
    birdiModalBackdropSelector,
    birdiModalSizeSelector,
    birdiModalTypeSelector
} from 'state/birdi-modal/birdi-modal.selectors';

import Button from 'ui-kit/button/button';

import './birdi-modal.style.scss';

/**
 * A Modal that should be present in all the pages, this only activates if the prop isOpen
 * set to true.
 * @param {ModalProps} props Modal props to use for managing state of the modal.
 * @return {JSX.Element} Returns a rendered modal, that may stay hidden until required.
 */
export default function BirdiModal() {
    const isOpened = useSelector(birdiModalOpenSelector);
    const onClose = useSelector(birdiModalOnCloseSelector);
    const showCloseButton = useSelector(birdiModalShowCloseSelector);
    const className = useSelector(birdiModalClassnameSelector);
    const headerContent = useSelector(birdiModalHeaderContentSelector);
    const content = useSelector(birdiModalContentSelector);
    const isBusy = useSelector(birdiModalBusySelector);
    const modalBackdrop = useSelector(birdiModalBackdropSelector);
    const modalSize = useSelector(birdiModalSizeSelector);
    const modalType = useSelector(birdiModalTypeSelector);
    const dispatch = useDispatch();

    return (
        <ModalRender
            className={className}
            contentClassName={content.contentClassName}
            onHide={() => {
                dispatch(closeModal({})); //TODO: Need to confirm this is the default functionality
            }}
            showCloseButton={showCloseButton}
            onClose={onClose}
            isOpened={isOpened}
            headerContent={headerContent}
            bodyContent={content.bodyContent}
            ctas={content.ctas}
            isBusy={isBusy}
            backdrop={modalBackdrop}
            size={modalSize}
            type={modalType}
        />
    );
}

export interface ModalRenderProps extends Pick<ModalProps, 'centered' | 'scrollable' | 'size' | 'backdrop'> {
    className?: string;
    contentClassName?: string;
    isOpened: boolean;
    bodyContent?: JSX.Element;
    headerContent?: JSX.Element;
    footerContent?: JSX.Element;
    ctas?: ModalCTAButton[];
    showCloseButton: boolean;
    onHide: () => void;
    onClose?: () => void;
    isBusy?: boolean;
    footerStyle?: 'sticky';
    type?: 'default' | 'danger';
}

const defaultFooterClasses = 'd-flex flex-column justify-content-center birdi-modal__footer';

export const ModalRender = React.memo(function ({
    className,
    contentClassName,
    isOpened,
    bodyContent,
    headerContent,
    footerContent,
    ctas = [],
    showCloseButton,
    onClose,
    onHide,
    isBusy,
    backdrop,
    centered,
    scrollable,
    size = 'xl',
    type,
    footerStyle
}: ModalRenderProps) {
    const isStickyFooter = footerStyle === 'sticky';
    const dialogClasses = classNames(className, 'birdi-modal mx-xs-5 mx-xs-auto', {
        'mt-5': !centered,
        'sticky-footer-modal': isStickyFooter
    });
    const contentClasses = classNames(
        'birdi-modal__content',
        contentClassName,
        { 'with-header': type !== undefined },
        { [`header-type-${type}`]: type !== undefined }
    );
    const handleHide = () => {
        if (onHide) onHide();
        if (onClose) onClose();
    };
    const modalTitle =
        bodyContent && bodyContent?.props?.title ? bodyContent.props.title.replace(/ /g, '') : 'unknown-modal';

    const hasCustomFooterContent = !!footerContent;

    return (
        <Modal
            size={size}
            dialogClassName={dialogClasses}
            contentClassName={contentClasses}
            show={isOpened}
            onHide={handleHide}
            data-ga-type="modal"
            backdrop={backdrop}
            centered={centered}
            scrollable={scrollable}
        >
            <Modal.Header closeButton={showCloseButton}>{headerContent}</Modal.Header>
            <Modal.Body>{bodyContent && bodyContent}</Modal.Body>

            <Modal.Footer className={isStickyFooter ? undefined : defaultFooterClasses}>
                {!hasCustomFooterContent &&
                    ctas.map((cta, idx) => (
                        <React.Fragment key={`modal-footer-button-${idx}`}>
                            {cta.async && (
                                <Button
                                    async
                                    dataGAType={'modal'}
                                    dataGALocation={cta?.dataGALocation?.replace(/ /g, '') || modalTitle}
                                    {...cta}
                                    type={'button'}
                                    isBusy={isBusy}
                                    disabled={isBusy}
                                />
                            )}
                            {!cta.async && (
                                <Button
                                    dataGAType={'modal'}
                                    dataGALocation={cta?.dataGALocation?.replace(/ /g, '') || modalTitle}
                                    {...cta}
                                    type={'button'}
                                />
                            )}
                        </React.Fragment>
                    ))}

                {hasCustomFooterContent && footerContent}
            </Modal.Footer>
        </Modal>
    );
});
